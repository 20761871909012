import * as React from 'react';

function SvgHub(props) {
  return (
    <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.393 6.225c.911 0 1.34-1.126.658-1.732L6.661.591a1 1 0 00-1.329 0L.948 4.492c-.681.606-.253 1.733.66 1.733V11a1 1 0 001 1h6.785a1 1 0 001-1V6.225z"
        fill="#3C3C3C"
      />
    </svg>
  );
}
export default SvgHub;
