import * as React from 'react';

function SvgBgSwirl2(props) {
  return (
    <svg viewBox="0 0 491 1210" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity={0.2} strokeWidth={2} strokeMiterlimit={10}>
        <path
          d="M563.611 697.304c11.675-28.136 34.894-23.451 67.604-13.495 24.336 7.408 50.948 17.794 76.863 28.592 25.684 10.765 50.487 24.061 73.496 25.967 23.486 1.947 37.542 26.048 43.696 59.121 7.558 40.628 3.236 86.734-18.424 113.736-27.321 34.057-57.077 53.712-88.504 57.866-30.906 4.109-63.441-6.885-97.21-34.023-19.419-15.607-35.364-39.676-46.981-67.313-5.728-13.627-10.392-28.119-13.894-42.897-3.844-16.233-6.291-32.812-7.21-48.974-1.721-29.174 1.565-56.899 10.562-78.582l.002.002z"
          stroke="url(#bg-swirl2_svg__paint0_linear_25_7701)"
        />
        <path
          d="M511.839 599.518c14.768-34.969 42.916-38.198 83.519-29.171 36.65 8.148 75.915 23.37 113.297 42.865 37.196 19.467 70.886 45.335 102.898 61.423 26.375 13.256 45.619 44.101 55.342 82.13 13.566 53.076 10.824 109.498-18.019 145.455-39.951 49.802-78.619 78.627-119.763 84.871-40.727 6.202-83.903-9.869-133.57-49.785-26.864-21.59-49.407-56.13-66.108-95.952-8.101-19.318-14.814-39.874-19.967-60.81-5.678-23.052-9.471-46.566-11.156-69.382-3.184-42.165.822-81.875 13.527-111.649v.005z"
          stroke="url(#bg-swirl2_svg__paint1_linear_25_7701)"
        />
        <path
          d="M459.263 502.814c17.916-41.779 50.969-53.272 99.435-44.843 49.014 8.523 100.881 28.948 149.734 57.139 48.712 28.171 92.501 65.462 132.301 96.878 30.238 23.87 53.905 62.096 66.987 105.142 19.886 65.433 18.413 132.27-17.611 177.177-52.581 65.547-100.162 103.548-151.025 111.873-50.553 8.29-104.364-12.849-169.934-65.545-34.306-27.571-63.447-72.583-85.232-124.595-10.475-25.009-19.235-51.632-26.043-78.721-7.512-29.876-12.651-60.32-15.099-89.789-4.648-55.161.125-106.83 16.493-144.712l-.006-.004z"
          stroke="url(#bg-swirl2_svg__paint2_linear_25_7701)"
        />
        <path
          d="M403.98 409.545c21.066-48.594 59.011-68.485 115.354-60.521 61.384 8.678 125.853 34.525 186.174 71.414 60.228 36.872 114.799 85.489 161.706 132.335 34.618 34.576 62.296 80.046 78.635 128.154 26.397 77.712 26 155.044-17.207 208.905-65.211 81.292-121.709 128.478-182.295 138.878-60.379 10.39-124.829-15.83-206.304-81.311-41.75-33.554-77.492-89.036-104.36-153.243-12.848-30.697-23.659-63.389-32.121-96.634-9.343-36.7-15.831-74.078-19.043-110.197-6.11-68.158-.575-131.793 19.46-177.785l.001.005z"
          stroke="url(#bg-swirl2_svg__paint3_linear_25_7701)"
        />
        <path
          d="M348.297 316.845c24.217-55.404 67.029-83.768 131.275-76.195 73.749 8.695 150.823 40.106 222.613 85.692C773.93 371.921 839.572 431.936 893.3 494.135c39.231 45.412 70.735 97.958 90.286 151.169 33.044 89.923 33.584 177.82-16.804 240.637-77.845 97.04-143.257 153.399-213.566 165.889-70.204 12.48-145.295-18.82-242.674-97.079-49.195-39.536-91.536-105.492-123.491-181.888-15.222-36.39-28.081-75.15-38.198-114.55-11.178-43.525-19.012-87.835-22.99-130.609-7.574-81.157-1.273-156.751 22.427-210.856l.007-.003z"
          stroke="url(#bg-swirl2_svg__paint4_linear_25_7701)"
        />
        <path
          d="M1004.86 882.188c-90.474 112.785-164.803 178.322-244.834 192.902-80.029 14.57-165.762-21.81-279.046-112.848-56.642-45.521-105.579-121.949-142.619-210.537-37.038-88.587-62.174-189.332-71.209-283.485-9.035-94.154-1.97-181.712 25.393-243.931 27.366-62.217 75.031-99.091 147.198-91.871 86.112 8.615 175.795 45.683 259.057 99.969 83.264 54.283 160.102 125.779 220.523 203.249 120.847 154.938 176.017 333.767 85.537 446.555v-.003z"
          stroke="url(#bg-swirl2_svg__paint5_linear_25_7701)"
        />
      </g>
      <defs>
        <linearGradient
          id="bg-swirl2_svg__paint0_linear_25_7701"
          x1={581.182}
          y1={891.249}
          x2={782.505}
          y2={722.557}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="bg-swirl2_svg__paint1_linear_25_7701"
          x1={532.679}
          y1={837.345}
          x2={848.587}
          y2={691.58}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="bg-swirl2_svg__paint2_linear_25_7701"
          x1={460.737}
          y1={754.902}
          x2={919.985}
          y2={703.517}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="bg-swirl2_svg__paint3_linear_25_7701"
          x1={364.248}
          y1={641.568}
          x2={988.621}
          y2={774.958}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="bg-swirl2_svg__paint4_linear_25_7701"
          x1={268.4}
          y1={470.856}
          x2={992.53}
          y2={845.757}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="bg-swirl2_svg__paint5_linear_25_7701"
          x1={222.752}
          y1={299.542}
          x2={983.276}
          y2={909.626}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
      </defs>
    </svg>
  );
}
export default SvgBgSwirl2;
