import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Markup } from 'interweave';
import { Button, TimeZoneSelector } from 'components';
import { useWindowSize } from 'hooks';
import { hexToRGB, getTimeZoneOffset } from 'utils';
import { useLocation } from '@reach/router';
import { Plus } from 'assets/svgs';

const cityNames = ['New York', 'São Paulo', 'Dubai', 'Hong Kong'];

const AgendaSingleDay = ({ agenda, colors, did, date }) => {
  const { windowWidth } = useWindowSize();
  const { origin } = useLocation();
  const [selectedTimeZone, setSelectedTimeZone] = useState('');

  return (
    <Wrapper id="agenda">
      <Agenda>Agenda</Agenda>
      <DayAndDate colors={colors}>
        <span>Day {did}</span> - {date}
      </DayAndDate>
      <div style={{ marginBottom: '2.5rem' }}>
        <TimeZoneSelector
          cityNames={cityNames}
          selectedTimeZone={selectedTimeZone}
          setSelectedTimeZone={setSelectedTimeZone}
          colors={colors}
        />
      </div>
      <Table>
        <thead>
          <tr>
            {windowWidth < 768 ? (
              <th>Time</th>
            ) : (
              <>
                {/* <th>JPN Time:</th> */}
                {/* <th>CET:</th> */}
                <th>{selectedTimeZone}</th>
              </>
            )}
            <th>Livestream:</th>
            {/* <th>Speakers:</th> */}
            <th>Duration:</th>
          </tr>
        </thead>
        <tbody>
          {agenda.map((timeslot, i) => (
            <BodyTrackRow
              colors={colors}
              highlightColor={timeslot.highlightColor}
              inset={timeslot.inset}
              key={i}>
              {windowWidth < 768 ? (
                timeslot.time ? (
                  <td>
                    {/* JPN: {timeslot.time.jpn}
                    <br /> */}
                    {/* CET: {timeslot.time.cet}
                    <br /> */}
                    {selectedTimeZone}{' '}
                    {getTimeZoneOffset({
                      hour: parseInt(timeslot.time.gmt.split(':')[0], 10),
                      selectedTimeZone
                    })}
                    :{timeslot.time.gmt.split(':')[1]}
                  </td>
                ) : (
                  <td />
                )
              ) : timeslot.time ? (
                <>
                  {/* <td>{timeslot.time.jpn}</td> */}
                  {/* <td>{timeslot.time.cet}</td> */}
                  <td>
                    {getTimeZoneOffset({
                      hour: parseInt(timeslot.time.gmt.split(':')[0], 10),
                      selectedTimeZone
                    })}
                    :{timeslot.time.gmt.split(':')[1]}
                  </td>
                </>
              ) : (
                <>
                  {/* <td /> */}
                  {/* <td /> */}
                  <td />
                </>
              )}
              <td>
                <Markup content={timeslot.livestream} noWrap />
                {(timeslot.calendarLink || timeslot.breakoutSessionLink) && (
                  <ButtonWrapper>
                    {timeslot.calendarLink && (
                      <a
                        href={`${origin}${timeslot.calendarLink}`}
                        target="_blank"
                        rel="noreferrer">
                        <Button
                          style={{
                            backgroundColor: timeslot.highlightColor
                              ? timeslot.highlightColor
                              : colors.primary,
                            fontSize: windowWidth >= 768 ? '0.75rem' : '0.625rem',
                            height: windowWidth >= 450 ? '2.35em' : '3.25em',
                            lineHeight: '1.362em',
                            padding: '1em 1.25em',
                            maxWidth: '14.5em',
                            width: '100%'
                          }}
                          whileHover={{
                            scale: 1.025
                          }}
                          whileTap={{
                            scale: 0.975
                          }}>
                          {windowWidth >= 768 && (
                            <Plus width="0.75rem" style={{ marginRight: '0.75em' }} />
                          )}
                          Add To Calendar
                        </Button>
                      </a>
                    )}
                    {timeslot.breakoutSessionLink && (
                      <a href={timeslot.breakoutSessionLink} target="_blank" rel="noreferrer">
                        <Button
                          style={{
                            backgroundColor: timeslot.highlightColor
                              ? timeslot.highlightColor
                              : colors.primary,
                            fontSize: windowWidth >= 768 ? '0.75rem' : '0.625rem',
                            height: windowWidth >= 450 ? '2.35em' : '3.25em',
                            lineHeight: '1.362em',
                            padding: '1em 1.25em',
                            maxWidth: '14.5em',
                            width: '100%'
                          }}
                          whileHover={{
                            scale: 1.025
                          }}
                          whileTap={{
                            scale: 0.975
                          }}>
                          Breakout Session
                        </Button>
                      </a>
                    )}
                  </ButtonWrapper>
                )}
              </td>
              {/* <td style={{ paddingLeft: 0 }}>
                <Markup content={timeslot.speakers} noWrap />
              </td> */}
              <td style={{ paddingLeft: 0 }}>{timeslot.duration}</td>
            </BodyTrackRow>
          ))}
        </tbody>
      </Table>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 5.125rem auto;
  max-width: 72.875rem;
  padding: 0 0 0 1rem;
  width: 100%;

  @media screen and (min-width: 75rem) {
    padding: 0;
  }
`;

const Table = styled.table`
  color: #3c3c3c;
  width: 100%;

  &,
  th,
  td {
    border-collapse: collapse;
  }

  thead tr th {
    font-size: 0.75rem;
    font-weight: 400;
    left: -1.333em;
    line-height: 2.667em;
    padding-right: 1.333em;
    position: relative;
    text-align: left;

    &:nth-of-type(1) {
      left: 0;
      padding-right: 2.666em;
      white-space: nowrap;
    }
  }
`;

const BodyTrackRow = styled.tr`
  background-color: ${({ highlightColor }) =>
    highlightColor ? hexToRGB({ color: highlightColor, alpha: 0.5 }) : 'transparent'};
  color: #3c3c3c;
  font-size: 1rem;
  left: -1em;
  position: relative;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  :hover {
    ${({ highlightColor }) =>
      !highlightColor &&
      css`
        background-color: ${({ colors }) => hexToRGB({ color: colors.primary, alpha: 0.1 })};
        color: ${({ colors }) => colors.primary};
      `}
  }

  td {
    font-size: 0.85rem;
    line-height: 1.5em;
    padding: ${({ inset }) => (inset ? '0.1em 1em 0.1em 1.125em' : '0.75em 1em 0.75em 0')};
    vertical-align: top;

    @media screen and (min-width: 48rem) {
      font-size: 1rem;
    }

    &:nth-of-type(1) {
      font-size: 0.75rem;
      padding: 0.75rem 1rem;
      white-space: nowrap;

      @media screen and (min-width: 48rem) {
        font-size: 1rem;
        white-space: unset;
      }
    }
  }
`;

const Agenda = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 37px;
`;

const DayAndDate = styled.p`
  color: #3c3c3c;
  font-size: 1.25rem;
  margin-bottom: 2rem;

  span {
    color: ${({ colors }) => colors.primary};
    font-weight: bold;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0.9rem 0 0.5rem;
  max-width: 15.625rem;
  width: 100%;

  @media screen and (min-width: 565px) {
    max-width: 20.75rem;
  }

  @media screen and (min-width: 768px) {
    max-width: 24rem;
  }

  a {
    display: block;
    flex-grow: 1;

    @media screen and (max-width: 564px) {
      &:nth-of-type(1) {
        margin-bottom: 1rem;
      }
    }
  }
`;

export default AgendaSingleDay;
