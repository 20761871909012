import React, { useContext } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { FirebaseContext } from 'context';
import { motion } from 'framer-motion';
import { Modal, KioskEntryPopup } from 'components';
import { Markup } from 'interweave';

export default ({ content, colors }) => {
  const { user } = useContext(FirebaseContext);
  const thumbnail = getImage(content.image);
  return (
    <Grid>
      <GridItem>
        <GatsbyImage image={thumbnail} alt={content.title} />
      </GridItem>
      <GridItem>
        <Content colors={colors}>
          <h3 colors={colors}>{content.title}</h3>
          <ButtonLink colors={colors} href={content.link} target="blank">
            Visit Website
          </ButtonLink>
          <div colors={colors}>
            <Markup content={content.description} />
          </div>
        </Content>
      </GridItem>
    </Grid>
  );
};

const ButtonLink = styled.a`
  align-items: center;
  align-self: start;
  background: ${({ colors }) => colors.tertiary};
  border-radius: 50px;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  font-weight: 600;
  height: 3rem;
  justify-content: center;
  margin: 2rem 0;
  text-transform: uppercase;
  transition: 0.5s;
  width: 10rem;
  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  }
`;

const Content = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  padding: 1.5rem;
  width: 100%;
  h3,
  p {
    text-align: left !important;
    max-width: 100% !important;
    margin-bottom: 1rem !important;
  }
  h3 {
    color: ${({ colors }) => colors.secondary};
  }
  @media (min-width: 768px) {
    padding: 3rem;
  }
`;

const GridItem = styled.div`
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  width: 100%;
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
`;

const Grid = styled.div`
  background: white;
  box-shadow: 0px 6px 20px rgba(196, 196, 196, 0.4);
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr;
  padding: 0;
  width: 100%;
  img {
    max-width: 100%;
    object-position: bottom right !important;
  }
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1150px) {
    grid-template-columns: 1fr 1fr;
  }
`;
