class CustomError extends Error {
    constructor(code, message) {
      const fullMsg = message ? `${code}: ${message}` : code;
      super(fullMsg);
  
      if (Error.captureStackTrace) {
        Error.captureStackTrace(this, CustomError);
      }
  
      this.code = code;
      this.message = fullMsg;
      this.name = 'CustomError';
    }
  
    toString() {
      return this.message;
    }
  }
  
  export default CustomError;
  