import React from 'react';
import { Button, Modal, GamePopup2 } from 'components';
import styled from 'styled-components';
import { useWindowSize } from 'hooks';
import { useStaticQuery, graphql } from 'gatsby';
import { motion } from 'framer-motion';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ZoomLogo } from 'assets/svgs';
// import playIcon from './play-icon.png';

const ZoomLink = () => (
  <ZoomWrapper>
    <ZoomLogo width={186} height={186} />
    <a
      href="https://dynamicevents-ie.zoom.us/j/88220613271?pwd=dTRIdGt5RGpoUWhnU0Y0b1l0MFIvdz09"
      target="_blank"
      rel="noreferrer"
      style={{
        textDecoration: 'none'
      }}>
      <Button style={{ backgroundColor: '#0095FF', marginTop: '26px', width: '200px' }}>
        Zoom Link
      </Button>
    </a>
  </ZoomWrapper>
);

const ZoomWrapper = styled.section`
  align-items: center;
  background-color: #0e41d5;
  border-radius: 2px;
  box-shadow: 5px 5px 12px -6px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  /* height: 330px; */
  justify-content: center;
  max-width: 330px;
  padding: 1.813rem 3.25rem;
  z-index: 2;

  @media screen and (max-width: 1149px) {
    margin: 1.5rem auto 0;
  }
`;

const FeelTheJoyDay3 = ({ colors, style }) => {
  const { backgroundImg } = useStaticQuery(graphql`
    query {
      backgroundImg: file(relativePath: { eq: "assets/images/feel-the-joy-bg-img-2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 517, height: 420)
        }
      }
    }
  `);

  const { windowWidth } = useWindowSize();

  return (
    <Wrapper style={style} colors={colors}>
      <div>
        {windowWidth >= 1150 && (
          <BackgroundImage image={getImage(backgroundImg)} alt="Person holding earth in hands" />
        )}
        <section>
          <Text>
            <h3>Feel The #JOY</h3>
            <p>
              Simply follow the instructions and start playing, your participation is greatly valued
              and will bring a greater sense of togetherness and joy to your colleagues.
              <br />
              <br />
              DOWNLOAD THE APP <strong>Mobile Adventures</strong> directly from{' '}
              <a
                href="https://apps.apple.com/us/app/mobile-adventures/id824257097"
                target="_blank"
                rel="noreferrer">
                App Store
              </a>{' '}
              and{' '}
              <a
                href="https://play.google.com/store/apps/details?id=uk.co.huntthegoose.app&hl=en_GB&gl=US"
                target="_blank"
                rel="noreferrer">
                Google Play
              </a>{' '}
              or <strong>scan the QR Code</strong>!
              <br />
              <br />
              Once you downloaded the app, wait to click on the Zoom link provided at game time!!
            </p>
            <Modal
              trigger={
                <Button
                  whileHover={{ scale: 1.035 }}
                  whileTap={{ scale: 0.975 }}
                  style={{ maxWidth: '332px', backgroundColor: '#0E41D5', color: '#fff' }}>
                  Click For More Information
                </Button>
              }
              modalContent={<GamePopup2 colors={colors} />}
            />
          </Text>
          {windowWidth < 1150 && <ZoomLink />}
        </section>
        {windowWidth >= 1150 && <ZoomLink />}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  margin-top: -1.75rem;
  padding: 0rem 1rem 3rem;
  width: 100%;

  > div {
    align-items: center;
    background-blend-mode: unset;
    background-color: ${({ colors }) => colors.primary};
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 34.25rem;
    padding: 2rem 1.5rem;
    position: relative;

    @media screen and (min-width: 1150px) {
      align-items: center;
      flex-direction: row;
      height: auto;
      justify-content: space-between;
      overflow: hidden;
      max-width: 72.75rem;
      padding: 45px 45px 45px 56px;
    }

    p,
    button {
      position: relative;
      z-index: 1;
    }

    h3,
    p {
      color: #fff;
    }

    a {
      color: #fff;
      text-decoration: underline;
    }

    h3 {
      font-size: 2rem;
      margin-bottom: 0.5em;
      text-align: center;

      @media screen and (min-width: 1150px) {
        text-align: left;
      }
    }

    p {
      font-size: 1rem;
      margin-bottom: 2em;
      max-width: 40.75rem;
      text-align: center;

      @media screen and (min-width: 1150px) {
        text-align: left;
      }
    }

    @media screen and (max-width: 1149px) {
      button {
        margin: 2rem auto 0;
      }
    }
  }
`;

const Text = styled.div`
  @media screen and (min-width: 1150px) {
    margin-right: 2rem;
    left: 0;
    position: absolute;
    top: 0;
    padding: 51px 0px 45px 56px;
  }
`;

const BackgroundImage = styled(GatsbyImage)`
  bottom: 0;
  position: absolute;
  right: 0;
`;

export default FeelTheJoyDay3;
