import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const Day3JoyBanner = ({ colors }) => {
  const { bgImg } = useStaticQuery(graphql`
    {
      bgImg: file(relativePath: { eq: "assets/images/events/3_1/joy-banner-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1164, height: 180)
        }
      }
    }
  `);

  return (
    <Container>
      <BackgroundImage image={bgImg.childImageSharp.gatsbyImageData} alt="JOY! Session" />
      <p>Click the button to join</p>
      <h3>JOY! Session</h3>
      <PlayButton
        color={colors.primary}
        whileHover={{ scale: 1.025 }}
        whileTap={{ scale: 0.975 }}
        href="https://dynamicevents-ie.zoom.us/j/88220613271?pwd=dTRIdGt5RGpoUWhnU0Y0b1l0MFIvdz09"
        target="_blank">
        View Breakout Session
      </PlayButton>
    </Container>
  );
};

const Container = styled(motion.section).attrs({
  initial: {
    opacity: 0,
    height: '0px'
  },
  animate: {
    opacity: 1,
    height: '180px'
  },
  exit: {
    opacity: 0,
    height: '0px'
  }
})`
  display: flex;
  flex-direction: column;
  height: 0px;
  justify-content: center;
  margin: 2.5rem auto 0;
  max-width: 72.688rem;
  position: relative;
  width: 100%;

  p,
  h3 {
    color: #fff;
    text-align: center;
  }

  p {
    font-size: 1rem;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0.2rem auto 1.5rem;
  }
`;

const BackgroundImage = styled(GatsbyImage)`
  height: 100%;
  left: 0;
  position: absolute !important;
  top: 0;
  width: auto;
  z-index: -1;
`;

const PlayButton = styled(motion.a)`
  align-items: center;
  align-self: center;
  background-color: ${({ color }) => color};
  border-radius: 3.125rem;
  color: #fff;
  display: inline-flex;
  font-size: 1rem;
  font-weight: bold;
  grid-area: button;
  justify-content: center;
  max-width: 17.75rem;
  padding: 0.8rem;
  text-transform: uppercase;
  width: 100%;
`;

export default Day3JoyBanner;
