import { useEffect, useState } from 'react';
import getFirebaseInstance from './firebase';
import loadFirebaseDependencies from './loadFirebaseDependencies';

function useAuth() {
  const [user, setUser] = useState(null);
  const [firebase, setFirebase] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let unsubscribe;
    let userUnsubscribe;

    loadFirebaseDependencies.then((app) => {
      const firebaseInstance = getFirebaseInstance(app);
      setFirebase(firebaseInstance);

      unsubscribe = firebaseInstance.auth.onAuthStateChanged((userAuthInfo) => {
        if (userAuthInfo) {
          userUnsubscribe = firebaseInstance.subscribeToUserUpdates({
            uid: userAuthInfo.uid,
            onSnapshot: (_user) => {
              firebaseInstance.auth.currentUser
                .getIdTokenResult(true)
                .then(async (token) => {
                  if (_user.exists) {
                    const userDatabaseInfo = _user.data();
                    setUser({
                      ...userAuthInfo,
                      ...userDatabaseInfo,
                      isAdmin: token.claims.isAdmin,
                      isModerator: token.claims.isModerator
                    });
                  }
                })
                .catch((error) => {
                  console.error(error.message);
                });
            }
          });
        } else {
          setUser(null);
        }
        setLoading(false);
      });
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }

      if (userUnsubscribe) {
        userUnsubscribe();
      }
    };
  }, []);

  return { user, setUser, firebase, loading };
}

export default useAuth;
