const getTimeZoneOffset = ({ hour, selectedTimeZone: _selectedTimeZone }) => {
  switch (_selectedTimeZone) {
    case 'GMT':
      return hour - 0;
    case 'CET':
      return hour + 1;
    case 'EST':
      return hour - 5;
    case 'ART':
      return hour - 3;
    case 'GST':
      return hour + 4;
    case 'HKT':
      return hour + 8;
    default:
      return hour;
  }
};

export default getTimeZoneOffset;
