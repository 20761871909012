import * as React from 'react';

function SvgBackground(props) {
  return (
    <svg viewBox="0 0 643 532" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity={0.2} strokeWidth={2} strokeMiterlimit={10}>
        <path
          style={{
            mixBlendMode: 'luminosity'
          }}
          d="M190.261 264.67c4.896-3.18 7.513 1.956 7.991 9.026.479 7.069-1.181 16.073-4.838 20.627-7.315 9.107-20.587 10.598-29.644 3.328-4.528-3.635-7.532-9.564-8.86-15.938-1.33-6.375-.983-13.196 1.188-18.615 2.155-5.374 6.874-2.5 12.966.478 6.091 2.978 13.555 6.058 21.196 1.094h.001z"
          stroke="url(#Background_svg__paint0_linear_25_2632)"
        />
        <path
          d="M143.349 238.339c2.968-7.085 8.789-5.94 16.984-3.484 6.097 1.828 12.761 4.401 19.25 7.078 6.431 2.67 12.637 5.976 18.409 6.423 5.891.457 9.385 6.494 10.884 14.795 1.841 10.198.692 21.789-4.781 28.603-6.903 8.595-14.398 13.575-22.29 14.662-7.762 1.075-15.912-1.642-24.349-8.415-4.852-3.894-8.82-9.92-11.697-16.848-1.419-3.416-2.569-7.051-3.428-10.759a71.16 71.16 0 01-1.742-12.296c-.391-7.328.472-14.299 2.759-19.759h.001z"
          stroke="url(#Background_svg__paint1_linear_25_2632)"
        />
        <path
          d="M130.428 213.826c3.754-8.808 10.824-9.659 21.003-7.446 9.188 1.997 19.022 5.768 28.378 10.615 9.309 4.842 17.73 11.296 25.743 15.295 6.602 3.294 11.389 11.019 13.777 20.563 3.332 13.319 2.566 27.503-4.723 36.578-10.097 12.571-19.842 19.868-30.178 21.494-10.231 1.615-21.046-2.364-33.457-12.327-6.713-5.388-12.324-14.037-16.461-24.022-2.006-4.843-3.663-10-4.927-15.254-1.394-5.785-2.313-11.689-2.705-17.421-.741-10.592.319-20.576 3.549-28.076l.001.001z"
          stroke="url(#Background_svg__paint2_linear_25_2632)"
        />
        <path
          d="M117.304 189.585c4.555-10.524 12.868-13.459 25.022-11.407 12.291 2.075 25.283 7.136 37.507 14.154 12.189 7.013 23.129 16.325 33.076 24.165 7.558 5.958 13.446 15.533 16.67 26.333 4.901 16.418 4.44 33.218-4.665 44.554-13.289 16.546-25.285 26.162-38.064 28.325-12.702 2.154-26.18-3.086-42.566-16.239-8.574-6.882-15.827-18.154-21.223-31.196-2.595-6.271-4.757-12.95-6.429-19.749-1.845-7.498-3.092-15.143-3.666-22.546-1.091-13.857.179-26.849 4.339-36.392l-.001-.002z"
          stroke="url(#Background_svg__paint3_linear_25_2632)"
        />
        <path
          d="M103.499 166.215c5.355-12.242 14.907-17.293 29.039-15.369 15.397 2.096 31.544 8.503 46.635 17.691 15.068 9.184 28.699 21.327 40.409 33.037 8.642 8.642 15.527 20.031 19.562 32.1 6.519 19.495 6.313 38.931-4.607 52.527-16.482 20.521-30.729 32.457-45.952 35.157-15.17 2.694-31.312-3.808-51.674-20.152-10.434-8.376-19.329-22.271-25.985-38.37-3.183-7.698-5.851-15.899-7.929-24.243-2.295-9.211-3.872-18.596-4.629-27.67-1.44-17.121.038-33.122 5.131-44.709v.001z"
          stroke="url(#Background_svg__paint4_linear_25_2632)"
        />
        <path
          d="M89.593 142.989c6.155-13.958 16.941-21.145 33.057-19.331 18.501 2.084 37.804 9.872 55.762 21.23 17.947 11.356 34.341 26.349 47.742 41.907 9.785 11.359 17.621 24.522 22.455 37.869 8.17 22.554 8.186 44.645-4.55 60.502-19.674 24.496-36.172 38.751-53.838 41.987-17.64 3.234-36.446-4.529-60.782-24.064-12.294-9.868-22.832-26.386-30.747-45.543-3.771-9.125-6.946-18.848-9.43-28.737-2.747-10.923-4.652-22.049-5.592-32.794-1.789-20.386-.103-39.394 5.921-53.025l.002-.001z"
          stroke="url(#Background_svg__paint5_linear_25_2632)"
        />
        <path
          d="M253.566 284.165c-22.866 28.471-41.615 45.044-61.724 48.818-20.109 3.774-41.579-5.25-69.89-27.975-14.155-11.363-26.334-30.504-35.51-52.717-9.175-22.213-15.345-47.499-17.483-71.15-2.139-23.65-.244-45.666 6.71-61.341 6.956-15.675 18.971-25.008 37.076-23.293 21.604 2.046 44.065 11.239 64.891 24.767 20.826 13.528 40.015 31.391 55.075 50.778 30.12 38.774 43.722 83.642 20.855 112.114v-.001z"
          stroke="url(#Background_svg__paint6_linear_25_2632)"
        />
      </g>
      <circle cx={461} cy={353.118} r={107} fill="#0095FF" />
      <circle
        cx={361}
        cy={314.118}
        r={156}
        transform="rotate(35.957 361 314.118)"
        fill="url(#Background_svg__paint7_linear_25_2632)"
      />
      <circle cx={189} cy={108} r={108} fill="url(#Background_svg__paint8_linear_25_2632)" />
      <circle cx={321} cy={229} r={217} fill="#1000C9" />
      <circle cx={202} cy={326} r={147} fill="#1000C9" />
      <circle cx={463.5} cy={340.618} r={83.5} fill="#1000C9" />
      <circle cx={501} cy={180.118} r={142} fill="#1000C9" />
      <g opacity={0.2} strokeWidth={2} strokeMiterlimit={10}>
        <path
          style={{
            mixBlendMode: 'luminosity'
          }}
          d="M469.968 331.238c8.984 2.966 5.149 11.507-3.3 19.305-8.447 7.799-21.507 14.857-30.97 14.857-18.927 0-34.271-15.296-34.271-34.163 0-9.435 4.445-19.272 11.152-27.44 6.707-8.17 15.677-14.67 24.726-17.432 8.976-2.74 10.131 6.163 12.546 16.91 2.415 10.745 6.094 23.333 20.116 27.962l.001.001z"
          stroke="url(#Background_svg__paint9_linear_25_2632)"
        />
        <path
          d="M455.667 245.052c11.963-3.448 16.419 5.09 21.627 17.968 3.874 9.583 7.381 20.64 10.579 31.583 3.149 10.86 5.265 22.085 10.554 29.85 5.397 7.927 1.314 18.493-7.654 28.837-11.017 12.705-26.826 23.042-40.985 23.042-17.86 0-31.752-4.427-41.13-13.318-9.23-8.738-14.062-21.824-14.062-39.4 0-10.107 3.589-21.263 9.424-31.956 2.877-5.272 6.303-10.427 10.118-15.286 4.19-5.338 8.849-10.321 13.767-14.715 8.861-7.95 18.543-13.948 27.762-16.606v.001z"
          stroke="url(#Background_svg__paint10_linear_25_2632)"
        />
        <path
          d="M473.545 203.772c14.935-4.204 23.179 3.885 30.706 19.028 6.795 13.668 12.003 29.96 15.367 46.741 3.324 16.715 3.71 33.946 6.783 48.162 2.533 11.713-2.372 25.635-12.007 38.367-13.448 17.77-32.144 31.227-51.002 31.227-26.12 0-45.222-4.92-57.757-16.357-12.415-11.315-18.356-29.06-18.356-54.914 0-13.984 5.238-29.888 13.656-45.285 4.085-7.468 8.919-14.812 14.275-21.758 5.896-7.65 12.422-14.821 19.266-21.146 12.63-11.714 26.319-20.528 39.07-24.066l-.001.001z"
          stroke="url(#Background_svg__paint11_linear_25_2632)"
        />
        <path
          d="M490.871 162.514c17.916-4.938 30.052 2.606 39.786 20.087 9.844 17.677 16.623 39.28 20.154 61.901 3.501 22.571 2.831 45.899 3.013 66.473.137 15.632-5.988 32.829-16.362 47.899-15.771 22.908-37.463 39.412-61.017 39.412-34.38 0-58.694-5.411-74.385-19.396-15.601-13.895-22.65-36.296-22.65-70.43 0-17.859 6.886-38.511 17.89-58.612 5.291-9.666 11.536-19.198 18.43-28.231 7.602-9.964 15.994-19.32 24.764-27.577 16.4-15.477 34.101-27.086 50.377-31.524v-.002z"
          stroke="url(#Background_svg__paint12_linear_25_2632)"
        />
        <path
          d="M506.408 121.275c20.896-5.67 36.964 1.289 48.865 21.145 12.965 21.633 21.245 48.601 24.942 77.06 3.677 28.426 2.159 58.043-.759 84.784-2.154 19.737-9.561 40.042-20.716 57.429-18.017 28.087-42.781 47.596-71.031 47.596-42.639 0-72.165-5.903-91.013-22.436-18.788-16.474-26.943-43.532-26.943-85.946 0-21.734 8.534-47.134 22.124-71.941 6.496-11.861 14.151-23.584 22.585-34.703 9.309-12.276 19.567-23.82 30.262-34.008 20.17-19.239 41.883-33.645 61.685-38.981l-.001.001z"
          stroke="url(#Background_svg__paint13_linear_25_2632)"
        />
        <path
          d="M521.66 80.056c23.876-6.403 43.894-.054 57.944 22.204 16.129 25.551 25.866 57.921 29.728 92.219 3.852 34.281 1.537 70.296-4.529 103.095-4.428 23.948-13.111 47.261-25.069 66.96-20.21 33.291-48.101 55.781-81.049 55.781-50.898 0-85.636-6.394-107.639-25.477-21.974-19.051-31.238-50.767-31.238-101.46 0-25.61 10.183-55.758 26.356-85.27 7.704-14.057 16.769-27.969 26.741-41.175 11.016-14.59 23.139-28.32 35.76-40.44 23.941-23.003 49.663-40.203 72.992-46.439l.003.002z"
          stroke="url(#Background_svg__paint14_linear_25_2632)"
        />
        <path
          d="M509.603 431.357c-59.157 0-99.106-6.887-124.267-28.517-25.16-21.63-35.532-58.002-35.532-116.975 0-29.486 11.831-64.381 30.589-98.598 18.759-34.216 44.445-67.752 72.156-94.518 27.711-26.766 57.445-46.761 84.3-53.898 26.855-7.136 50.831-1.411 67.024 23.264 19.323 29.443 30.487 67.241 34.515 107.379 4.029 40.137.921 82.611-8.299 121.405-18.439 77.59-61.329 140.458-120.487 140.458h.001z"
          stroke="url(#Background_svg__paint15_linear_25_2632)"
        />
      </g>
      <defs>
        <linearGradient
          id="Background_svg__paint0_linear_25_2632"
          x1={161.029}
          y1={295.557}
          x2={189.604}
          y2={259.958}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="Background_svg__paint1_linear_25_2632"
          x1={147.491}
          y1={287.046}
          x2={198.38}
          y2={244.541}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="Background_svg__paint2_linear_25_2632"
          x1={135.33}
          y1={273.564}
          x2={215.117}
          y2={237.14}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="Background_svg__paint3_linear_25_2632"
          x1={117.326}
          y1={252.94}
          x2={232.812}
          y2={240.661}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="Background_svg__paint4_linear_25_2632"
          x1={93.205}
          y1={224.584}
          x2={249.426}
          y2={258.735}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="Background_svg__paint5_linear_25_2632"
          x1={69.325}
          y1={181.807}
          x2={250.075}
          y2={275.996}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="Background_svg__paint6_linear_25_2632"
          x1={58.046}
          y1={138.809}
          x2={247.93}
          y2={291.315}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="Background_svg__paint7_linear_25_2632"
          x1={361}
          y1={158.118}
          x2={361}
          y2={470.118}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0095FF" />
          <stop offset={0.172} stopColor="#3C09DB" />
          <stop offset={0.474} stopColor="#891AFC" />
          <stop offset={0.974} stopColor="#C7D601" />
        </linearGradient>
        <linearGradient
          id="Background_svg__paint8_linear_25_2632"
          x1={189}
          y1={0}
          x2={189}
          y2={216}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0095FF" />
          <stop offset={0.172} stopColor="#3C09DB" />
          <stop offset={0.474} stopColor="#891AFC" />
          <stop offset={0.974} stopColor="#C7D601" />
        </linearGradient>
        <linearGradient
          id="Background_svg__paint9_linear_25_2632"
          x1={401.294}
          y1={325.633}
          x2={475.255}
          y2={325.633}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="Background_svg__paint10_linear_25_2632"
          x1={398.331}
          y1={299.848}
          x2={503.657}
          y2={320.979}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="Background_svg__paint11_linear_25_2632"
          x1={403.045}
          y1={270.75}
          x2={530.104}
          y2={334.493}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="Background_svg__paint12_linear_25_2632"
          x1={410.856}
          y1={226.987}
          x2={543.847}
          y2={360.396}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="Background_svg__paint13_linear_25_2632"
          x1={422.23}
          y1={167.612}
          x2={538.053}
          y2={399.935}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="Background_svg__paint14_linear_25_2632"
          x1={452.067}
          y1={93.871}
          x2={516.794}
          y2={418.522}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="Background_svg__paint15_linear_25_2632"
          x1={494.962}
          y1={35.867}
          x2={494.962}
          y2={431.491}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgBackground;
