import * as React from 'react';

function SvgIgIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={12} cy={12} r={12} fill="#0E41D5" />
      <path
        d="M15.007 9.665a.667.667 0 10-.001-1.333.667.667 0 00.001 1.333zM12.043 9.191a2.826 2.826 0 00-2.814 2.82c0 1.55 1.267 2.82 2.814 2.82a2.826 2.826 0 002.814-2.82c0-1.55-1.267-2.82-2.814-2.82zm0 4.627a1.817 1.817 0 01-1.805-1.807c0-.99.817-1.808 1.805-1.808s1.804.818 1.804 1.808c0 .99-.816 1.808-1.804 1.808z"
        fill="#fff"
      />
      <path
        d="M14.277 17.714H9.723a3.45 3.45 0 01-3.437-3.443V9.729a3.45 3.45 0 013.437-3.443h4.554a3.45 3.45 0 013.437 3.443v4.563c-.021 1.894-1.546 3.422-3.437 3.422zM9.723 7.362A2.358 2.358 0 007.36 9.729v4.563a2.372 2.372 0 002.363 2.368h4.554a2.372 2.372 0 002.363-2.368V9.73a2.372 2.372 0 00-2.363-2.367H9.723z"
        fill="#fff"
      />
    </svg>
  );
}
export default SvgIgIcon;
