const firebaseConfig = {
  apiKey: 'AIzaSyCynORX8cRdk6zrsLquAym5X9tJHkZ7WnY',
  authDomain: 'em-transform.firebaseapp.com',
  databaseURL: 'https://em-transform-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'em-transform',
  storageBucket: 'em-transform.appspot.com',
  messagingSenderId: '765210239965',
  appId: '1:765210239965:web:b206c33dbafb7d523ccc34'
};

export default firebaseConfig;
