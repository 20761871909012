import React, { useContext } from 'react';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { Add } from 'assets/animated-svgs';

export default ({ colors, contrast, transparent, eventTitle, description, startTime, endTime }) => {
  const event = {
    title: eventTitle.replace('<br />', ' '),
    description: description || eventTitle,
    location: 'Virtual Conference',
    startTime: `${startTime}`,
    endTime: `${endTime}`
  };
  return (
    <StyledDateButton href="/ics-files/em-tramsform.ics" target="_blank">
      <AddIcon color={colors.primary} />
      <Text color={colors.primary}>Add To Calendar</Text>
    </StyledDateButton>
  );
};

const StyledDateButton = styled.a`
  align-items: center;
  display: flex;
  margin: 2rem 0;

  @media screen and (min-width: 440px) {
    margin: 0 0 0 2.1rem;
  }
`;

const Text = styled.span`
  color: ${({ color }) => color};
  font-weight: bold;
  padding-left: 11px;
  text-transform: uppercase;
`;

const AddIcon = styled(Add)`
  width: 1.5rem;
`;
