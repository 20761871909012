import React from 'react';
import styled from 'styled-components';
import { ReactionStream } from 'components';
import { hexToRGB } from 'utils';
import { AnimatePresence, motion } from 'framer-motion';

const Overlays = ({
  colors,
  did,
  eid,
  handlePipOverlayClick,
  isPipModeEnabled,
  livestreamRef,
  questionCurrentlyBeingAnsweredLive
}) => (
  <>
    <AnimatePresence>
      {questionCurrentlyBeingAnsweredLive && (
        <AnswerLiveOverlay colors={colors}>
          <div>
            <div>
              <motion.span
                initial={{ rotateY: -720 }}
                animate={{
                  rotateY: 0,
                  transition: {
                    duration: 1,
                    type: 'tween',
                    ease: 'easeOut'
                  }
                }}
                exit={{
                  x: '-10.5rem',
                  opacity: 1,
                  transition: {
                    duration: 0.4
                  }
                }}>
                Question
              </motion.span>
            </div>
            <div>
              <motion.span
                initial={{ rotateY: 720 }}
                animate={{
                  rotateY: 0,
                  transition: {
                    duration: 1,
                    type: 'tween',
                    ease: 'easeOut'
                  }
                }}
                exit={{
                  x: '21rem',
                  opacity: 0,
                  transition: {
                    duration: 0.4
                  }
                }}>
                {questionCurrentlyBeingAnsweredLive}
              </motion.span>
            </div>
          </div>
        </AnswerLiveOverlay>
      )}
    </AnimatePresence>
    <ReactionStream did={did} eid={eid} livestreamRef={livestreamRef} />
    <AnimatePresence>
      {isPipModeEnabled && (
        <PipOverlay
          onClick={handlePipOverlayClick}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          colors={colors}>
          Click To Exit
          <br />
          Popout Player
        </PipOverlay>
      )}
    </AnimatePresence>
  </>
);

const AnswerLiveOverlay = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: {
    opacity: 0,
    transition: {
      delay: 0.2
    }
  }
})`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  left: 0;
  position: absolute;
  pointer-events: none;
  top: 0;
  width: 100%;

  > div {
    background-color: #fff;
    border-radius: 0.125rem;
    color: ${({ colors }) => colors.primary};
    margin-bottom: 3.25rem;
    max-width: 21rem;
    text-align: center;
    overflow: hidden;

    span {
      display: inline-block;
      perspective: 100px;
    }

    > div:nth-child(1) {
      font-weight: 700;
      padding: 0.25rem 1rem 0.4rem;
    }

    > div:nth-child(2) {
      background-color: ${({ colors }) => hexToRGB({ color: colors.primary, alpha: 0.2 })};
      padding: 0.65rem 1rem;
    }
  }
`;

const PipOverlay = styled(motion.div)`
  align-items: center;
  background-color: ${({ colors }) => colors.primary};
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 2rem;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  width: 100%;
`;

export default Overlays;
