import * as React from 'react';

function SvgBgSwirl(props) {
  return (
    <svg viewBox="0 0 372 1120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity={0.2} strokeWidth={2} strokeMiterlimit={10}>
        <path
          d="M-129.791 479.635c38.506-1.387 54.435 22.519 65.01 62.732 9.548 36.298 13.893 77.905 13.496 119.61-.458 41.526-8.334 82.587-8.123 118.047.172 29.217-18.887 59.397-48.854 84.042-41.825 34.392-94.215 55.552-140.118 44.721-63.58-15-107.56-37.632-132.223-71.937-24.432-33.951-29.815-79.421-16.59-140.699 7.153-33.144 28.036-67.831 56.404-99.488 13.762-15.357 29.286-29.986 45.876-43.374 18.264-14.746 37.818-27.993 57.714-39.056 36.733-20.508 74.565-33.537 107.412-34.601l-.004.003z"
          stroke="url(#bg-swirl_svg__paint0_linear_25_7712)"
        />
        <path
          d="M-66.384 391.019c46.15-1.414 71.843 23.443 86.6 70.476 14.924 47.566 20.378 102.676 17.4 158.333-3.025 55.522-16.592 110.444-26.678 159.326-7.664 37.137-31.378 74.39-64.352 104.158-50.125 45.251-111.392 71.918-168.746 58.387-83.716-19.75-140.152-46.546-171.204-88.716-30.879-41.904-36.581-99.06-19.115-179.984 9.139-42.341 36.474-87.347 73.555-128.682 17.83-19.875 37.914-38.888 59.323-56.343 23.611-19.254 48.834-36.615 74.415-51.152 47.854-27.272 96.897-44.625 138.8-45.797l.002-.006z"
          stroke="url(#bg-swirl_svg__paint1_linear_25_7712)"
        />
        <path
          d="M-7.374 301.47c53.786-1.44 89.353 24.291 108.172 78.203 20.502 58.735 26.864 127.43 21.302 197.025-5.592 69.505-24.444 138.849-45.233 200.573-15.344 45.555-43.773 89.441-79.832 124.253-58.248 56.24-128.533 88.268-197.327 72.039-103.83-24.494-172.709-55.451-210.144-105.477-37.321-49.849-43.333-118.685-21.629-219.24 11.122-51.529 44.9-106.847 90.687-157.853 21.89-24.388 46.529-47.783 72.756-69.301 28.951-23.756 59.837-45.232 91.094-63.242 58.962-34.027 119.206-55.707 170.158-56.982l-.004.002z"
          stroke="url(#bg-swirl_svg__paint2_linear_25_7712)"
        />
        <path
          d="M50.93 211.81c61.415-1.463 106.912 25.088 129.737 85.93 26.201 69.841 33.345 152.18 25.198 235.712-8.161 83.488-32.229 167.544-63.783 241.82-23.036 54.232-56.11 104.516-95.31 144.348-66.248 67.317-145.674 104.62-225.906 85.69-123.941-29.241-205.26-64.356-249.075-122.238-43.76-57.791-50.088-138.304-24.147-258.488 13.105-60.717 53.327-126.344 107.813-187.02 25.952-28.903 55.146-56.677 86.187-82.258 34.29-28.261 70.837-53.849 107.774-75.332 70.068-40.785 141.506-66.786 201.505-68.169l.006.005z"
          stroke="url(#bg-swirl_svg__paint3_linear_25_7712)"
        />
        <path
          d="M-158.127 1037.06c-144.051-33.99-237.807-73.264-288.007-138.998-50.197-65.733-56.841-157.923-26.664-297.736 15.088-69.907 61.754-145.84 124.941-216.186 63.187-70.343 142.896-135.096 224.07-182.634 81.174-47.538 163.81-77.863 232.858-79.356 69.046-1.49 124.498 25.855 151.305 93.657 31.986 80.905 39.829 176.931 29.098 274.403-10.729 97.472-40.03 196.385-82.334 283.063C122.535 946.63-14.076 1071.04-158.13 1037.06h.003z"
          stroke="url(#bg-swirl_svg__paint4_linear_25_7712)"
        />
      </g>
      <defs>
        <linearGradient
          id="bg-swirl_svg__paint0_linear_25_7712"
          x1={-335.658}
          y1={597.895}
          x2={-62.031}
          y2={825.608}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="bg-swirl_svg__paint1_linear_25_7712"
          x1={-294.216}
          y1={497.907}
          x2={-51.102}
          y2={898.155}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="bg-swirl_svg__paint2_linear_25_7712"
          x1={-236.068}
          y1={362.97}
          x2={-95.511}
          y2={985.41}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="bg-swirl_svg__paint3_linear_25_7712"
          x1={-125.605}
          y1={204.587}
          x2={-157.935}
          y2={1010.5}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="bg-swirl_svg__paint4_linear_25_7712"
          x1={8.6}
          y1={91.014}
          x2={-211.679}
          y2={1024.76}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
      </defs>
    </svg>
  );
}
export default SvgBgSwirl;
