import * as React from 'react';

function SvgRightArrow(props) {
  return (
    <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11.314 1.686L22.627 13 11.314 24.314" stroke="#fff" strokeWidth={4} />
    </svg>
  );
}
export default SvgRightArrow;
