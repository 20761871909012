import React, { useContext, useEffect } from 'react';
import { LocalContext, FirebaseContext } from 'context';
import { navigate } from '@reach/router';
import styled from 'styled-components';
import { clearQueryParams } from 'utils';
import { FormContainer } from '../FormComponents';

const LoggingExistingUserIn = ({ setShowLoggingExistingUserInModal, colors, continueUrl }) => {
  const { user } = useContext(FirebaseContext);
  const { theme } = useContext(LocalContext);

  useEffect(() => {
    if (user) {
      if (continueUrl.includes('day-')) {
        navigate(continueUrl);
      } else {
        // i.e. if continueUrl is just the homepage
        clearQueryParams();
      }
      setShowLoggingExistingUserInModal(false);
    }
  }, [user]);

  return (
    <FormContainer theme={theme} colors={colors}>
      <Status>Logging In</Status>
    </FormContainer>
  );
};

const Status = styled.h3`
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: bold;
  justify-content: center;
  line-height: 1.75rem;
  text-align: center;

  &:after {
    animation: dots 1s steps(5, end) infinite;
    content: ' .';
    margin-left: 0.25rem;
  }

  @keyframes dots {
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow: 0.5rem 0 0 rgba(0, 0, 0, 0), 1rem 0 0 rgba(0, 0, 0, 0);
    }
    40% {
      color: #fff;
      text-shadow: 0.5rem 0 0 rgba(0, 0, 0, 0), 1rem 0 0 rgba(0, 0, 0, 0);
    }
    60% {
      text-shadow: 0.5rem 0 0 #fff, 1rem 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
      text-shadow: 0.5rem 0 0 #fff, 1rem 0 0 #fff;
    }
  }
`;

export default LoggingExistingUserIn;
