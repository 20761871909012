import React from 'react';
import styled from 'styled-components';
import { Markup } from 'interweave';

const EventSmallPrint = ({ colors, smallPrint }) => (
  <Wrapper colors={colors}>
    <Text>
      <Markup content={smallPrint} />
    </Text>
  </Wrapper>
);

const Wrapper = styled.div`
  background-color: ${({ colors }) => colors?.primary || '#00004E'};
  bottom: 0;
  color: #fff;
  font-size: 0.688rem;
  left: 0;
  line-height: 1.182em;
  padding: 1.75em;
  width: 100%;

  > svg {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

const Text = styled.p`
  margin: 0 auto;

  @media screen and (min-width: 1150px) {
    max-width: 800px;
  }

  a {
    color: #fff;
    font-weight: bold;
    text-decoration: underline;
  }
`;

export default EventSmallPrint;
