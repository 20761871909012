import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
// import { breakpoints, gridContainer } from 'styles';
import { motion, AnimatePresence } from 'framer-motion';
import { Button, GameScreenshot } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import { Plus, AppleStoreLogo, GooglePlayLogo, QrCode1, QrCode2 } from 'assets/svgs';
// import { LeftArrow, RightArrow, FbIcon, TwitterIcon, LinkedinIcon, EmailIcon } from 'assets/svgs';
import Interweave from 'interweave';

export default ({ colors }) => {
  const { gamePopupBanner, gameScreenshot } = useStaticQuery(graphql`
    query {
      gamePopupBanner: file(relativePath: { eq: "assets/images/game-popup-banner.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1164, height: 420)
        }
      }
      gameScreenshot: file(relativePath: { eq: "assets/images/game-screenshot.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 687, height: 388)
        }
      }
    }
  `);
  return (
    <Backdrop>
      <Container>
        <BannerImg image={getImage(gamePopupBanner)} alt="banner image" />
        <Content>
          <h2>Feel the #JOY!</h2>
          <h2 style={{ color: colors.primary }}>Getting Started</h2>
          <br />
          <br />
          <p style={{ marginBottom: '0.4em', maxWidth: '808px' }}>
            <strong>
              If you are joining the #JOY game app for the first time here is a summary to get you
              going fast...
            </strong>
            <br />
            The basics of the game play are that you need a phone and a laptop. The phone to answer
            questions and the laptop to communicate with your colleagues through Zoom. Zoom can be
            accessed via the Desktop app or through your browser.
          </p>
          <br />
          <br />
          <a href="https://zoom.us/download" target="_blank" rel="noreferrer">
            <Button style={{ backgroundColor: colors.primary, maxWidth: '260px' }}>
              Zoom Desktop App
            </Button>
          </a>
          <br />
          <br />
          <p>
            <strong>APP:</strong> Download and install the free “Mobile Adventures” App on your
            smartphone. It is available to download on{' '}
            <a
              href="https://apps.apple.com/us/app/mobile-adventures/id824257097"
              target="_blank"
              style={{ textDecoration: 'underline' }}
              rel="noreferrer">
              iOS
            </a>{' '}
            or{' '}
            <a
              href="https://play.google.com/store/apps/details?id=uk.co.huntthegoose.app&hl=en_GB&gl=US"
              target="_blank"
              style={{ textDecoration: 'underline' }}
              rel="noreferrer">
              Android
            </a>{' '}
            by searching “mobile adventures” in the app store/play store.
          </p>
          <br />
          <CodeAndLogosWrapper>
            <StyledQrCode1 />
            <Logos>
              <a
                href="https://apps.apple.com/us/app/mobile-adventures/id824257097"
                target="_blank"
                rel="noreferrer">
                <AppleStoreLogo />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=uk.co.huntthegoose.app&hl=en_GB&gl=US"
                target="_blank"
                rel="noreferrer">
                <GooglePlayLogo />
              </a>
            </Logos>
          </CodeAndLogosWrapper>
          <br />
          <br />
          <p>
            <strong>NOTE:</strong> If you already have the app installed please check your app store
            for updates.
            <br />
            <br />
            That&apos;s all you have to do for now. Your game access code will be provided to you by
            the host during the #JOY Session. Not before.
            <br />
            <br />
            <strong>IMPORTANT:</strong> Make sure you accept all permissions for the app to access
            camera and microphone to ensure smooth gameplay.
          </p>
          <br />
          <br />
          <h2 style={{ fontWeight: 400 }}>Zoom Details:</h2>
          <br />
          <p>
            Meeting ID: <strong>860 7556 7377</strong>
          </p>
          <p>
            Passcode: <strong>522065</strong>
          </p>
          <br />
          <br />
          <a
            href="https://dynamicevents-ie.zoom.us/j/88220613271?pwd=dTRIdGt5RGpoUWhnU0Y0b1l0MFIvdz09"
            target="_blank"
            rel="noreferrer">
            <Button style={{ backgroundColor: colors.primary, width: '188px' }}>Zoom Link</Button>
          </a>
          <br />
          <p>
            If you have any more questions about the games please message us on:
            <br />
            <a
              style={{ color: '#0095FF', textDecoration: 'underline' }}
              href="https://go.crisp.chat/chat/embed/?website_id=00b69e53-4c51-42e2-a625-f876a3efec48"
              target="_blank"
              rel="noreferrer">
              Live Chat Support
            </a>{' '}
          </p>
          <br />
          <br />
        </Content>
      </Container>
    </Backdrop>
  );
};

const CodeAndLogosWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 500px) {
    flex-direction: row;
    align-items: center;
  }
`;

const StyledQrCode1 = styled(QrCode1)`
  max-width: 410px;
  width: 100%;
`;

const StyledQrCode2 = styled(QrCode2)`
  max-width: 408px;
  width: 100%;
`;

const Logos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 500px) {
    margin-left: 20px;
    position: relative;
    top: 15px;
  }

  svg {
    height: 3rem;
    width: 10.125rem;

    &:first-of-type {
      margin-bottom: 10px;
    }
  }
`;

const Content = styled.div`
  > * {
    max-width: 926px;
  }
`;

const BannerImg = styled(GatsbyImage)`
  height: 15.625rem;
  width: 100%;
  @media (min-width: 48rem) {
    height: 26.25rem;
  }
`;

const Container = styled(motion.div)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
  padding-top: 1rem;
  width: 1200px;

  p,
  ol,
  ul,
  li,
  h2,
  a {
    color: #3c3c3c;
    font-size: 1.25rem;
    line-height: 32px;
  }

  ol,
  ul {
    list-style-position: inside;
    line-height: 32px;
  }

  h2 {
    font-size: 2rem;
  }

  > div {
    background: #ededed;
    padding: 1rem 1.5rem;
    width: 100%;
  }

  @media (min-width: 48rem) {
    > div {
      padding: 3rem 5rem 1.5rem;
    }
  }

  @media (min-width: 71.875rem) {
    max-width: 100%;
    padding-top: 0;
  }
`;

const Backdrop = styled.div`
  height: auto;
  width: auto;
  @media (max-width: 71.875rem) {
    overflow-x: hidden;
  }
`;
