import styled from 'styled-components';

export const container = () =>
  `
    padding: 0px 1.25rem;
    grid-column: 1/7;
    @media (min-width: 768px){
      grid-column: 2/6;
    }
    @media (min-width: 1150px){
        grid-column: 2/12;
    }
    @media (min-width: 1600px){
      grid-column: 3/11;
    }
    `;

export const gridContainer = () =>
  `
    padding: 0px 1.25rem;
    grid-column: 1/7;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap:.625rem;
    @media (min-width: 768px){
      grid-column: 2/6;
    }
    @media (min-width: 1150px){
        grid-column: 2/12;
        grid-template-columns: repeat(10, 1fr);
        grid-gap: 1.25rem;
    }
    @media (min-width: 1600px){
      grid-column: 3/11;
    }
    `;

export const fullGridContainer = () =>
  `
    padding:0px 1.25rem;
    grid-column: 1/7;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap:.625rem;
    @media (min-width: 1150px){
        grid-column: 1/13;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 1.25rem;
    }
    `;

export const flexContainer = () =>
  `
    grid-column: 1/7;
    display: flex;
    @media (min-width: 768px){
      grid-column: 2/6;
    }
    @media (min-width: 1150px){
        grid-column: 2/12;
    }
    @media (min-width: 1600px){
      grid-column: 3/11;
    }
    `;

export const noPadding = () => `
    padding:0px;`;

export const fullWidth = () => `
grid-column: 1/7;
@media (min-width: 768px){
  grid-column: 2/6;
}
@media (min-width: 1150px){
  grid-column: 2/12;
}
@media (min-width: 1600px){
  grid-column: 3/11;
}
`;

export const breakpoints = {
  xs: '480px',
  sm: '768px',
  md: '992px',
  lg: '1150px'
};

export const Section = styled.section`
  display: grid;
  grid-column-gap: 0.5rem;
  grid-row-gap: 1rem;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  padding: 1rem;
  width: 100%;
  @media (min-width: 1150px) {
    grid-column-gap: 1rem;
    grid-row-gap: 3rem;
    padding: 5rem;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const fadeInAndOutVariants = ({ animateDelay = 0, exitDelay = 0 } = {}) => ({
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      delay: animateDelay
    }
  },
  exit: {
    opacity: 0,
    transition: {
      delay: exitDelay
    }
  }
});

export const fadeInAndOutAndAnimateHeightVariants = () => ({
  initial: {
    opacity: 0,
    height: 0
  },
  animate: {
    opacity: 1,
    height: 'auto',
    transition: {
      ease: 'easeInOut',
      duration: 0.2,
      opacity: {
        delay: 0.1
      }
    }
  },
  exit: {
    opacity: 0,
    height: 0,
    transition: {
      ease: 'easeInOut',
      duration: 0.2,
      height: {
        delay: 0.1
      }
    }
  }
});

export const evolve = {
  primary: '#891AFC',
  secondary: '#FD5A45',
  tertiary: '#0095FF'
};

export const pfizer = {
  primary: '#00004E',
  secondary: '#0095FF',
  tertiary: '#BD00CE'
};

// export const pfizer = {
//   primary: '#FD5A45',
//   secondary: '#B12F7E',
//   blue: '#0095FF',
//   yellow: '#FFC156',
//   green: '#479244',
//   dark: '#00004E',
//   black: '#3C3C3C',
//   notifications: '#fee500'
// };
