import React from 'react';
import styled from 'styled-components';
import Star from '../SharedGraphics/Star';
import Mesh from '../SharedGraphics/Mesh';
import Background from './Background';

function HomepageIntroGraphic() {
  return (
    <Container>
      <StyledBackground />
      <StyledMesh color="#29ABE2" />
      <StyledStar1 />
      <StyledStar2 />
      <Orb1 color="#891AFC" />
      <Orb2 color="#C7D601" />
    </Container>
  );
}

const Orb = styled.div`
  animation: float 4s linear infinite;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  height: 12%;
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  width: 10%;

  @keyframes float {
    0% {
      transform: rotate(0deg) translateX(14%) rotate(0deg);
    }
    100% {
      transform: rotate(360deg) translateX(14%) rotate(-360deg);
    }
  }
`;

const Orb1 = styled(Orb)`
  animation: float 4s linear infinite;
  left: 30%;
  top: 1.75%;
`;
const Orb2 = styled(Orb)`
  animation: float 5s 1s linear infinite;
  bottom: 7%;
  left: 45%;
`;

const Container = styled.div`
  max-width: 653px;
  position: relative;
  top: 2rem;
  width: 100%;
`;

const StyledBackground = styled(Background)`
  width: 100%;
`;

const StyledMesh = styled(Mesh)`
  animation: moveMesh 3s ease-in-out alternate-reverse infinite;
  left: 4.5%;
  position: absolute;
  top: -16.5%;
  transform: rotate(-5deg);
  width: 89%;

  @keyframes moveMesh {
    0% {
      transform: rotate(-5deg);
    }

    100% {
      transform: rotate(5deg);
    }
  }
`;

const StyledStar1 = styled(Star)`
  animation: rotate 2s ease-in-out alternate-reverse infinite;
  left: 15%;
  position: absolute;
  top: 20%;
  width: 80px;

  @keyframes rotate {
    0% {
      transform: rotate3d(0, 0, 1, 0deg);
    }
    100% {
      transform: rotate3d(0, 0, 1, 90deg);
    }
  }
`;

const StyledStar2 = styled(Star)`
  animation: rotate 2s ease-in-out alternate-reverse infinite;
  position: absolute;
  right: 0.5%;
  top: 43%;
  transform: rotate3d(0, 0, 1, -45deg);
  width: 70px;

  @keyframes rotate {
    0% {
      transform: rotate3d(0, 0, 1, -45deg);
    }
    100% {
      transform: rotate3d(0, 0, 1, 45deg);
    }
  }
`;

export default HomepageIntroGraphic;
