import * as React from 'react';

function SvgEmojiSmile(props) {
  return (
    <svg viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M69.411 37.132c0 20.745-13.998 32.404-34.705 32.404C13.998 69.536 0 57.877 0 37.132 0 15.6 13.234 0 34.706 0 56.177 0 69.41 15.6 69.41 37.132z"
        fill="#FED132"
      />
      <path
        d="M52.105 35.95c0 10.408-7.01 16.249-17.4 16.249-10.388 0-17.398-5.841-17.398-16.248 0-10.802 6.64-18.613 17.399-18.613 10.758 0 17.399 7.811 17.399 18.612z"
        fill="#fff"
      />
      <path
        d="M26.538 29.901c0 .65.07 1.298.185 1.924.463 2.295 3.795 2.295 4.257 0a10.43 10.43 0 00.186-1.924 8.96 8.96 0 00-.37-2.62c-.556-1.97-3.356-1.97-3.91 0a10.836 10.836 0 00-.348 2.62zM42.78 29.901c0 .65-.07 1.298-.185 1.924-.463 2.295-3.795 2.295-4.258 0a10.44 10.44 0 01-.185-1.924c0-.904.116-1.785.37-2.62.556-1.97 3.355-1.97 3.91 0a9.8 9.8 0 01.348 2.62zM27.949 38.338c1.365 1.16 3.03 2.504 6.733 2.41 3.447-.092 4.905-.764 6.802-2.41a.612.612 0 01.856.047.596.596 0 01.116.579c-1.088 3.268-4.28 4.636-7.705 4.682-3.91.047-6.733-1.924-7.774-4.682a.601.601 0 01.347-.765c.185-.07.416-.046.625.14z"
        fill="#FED132"
      />
    </svg>
  );
}
export default SvgEmojiSmile;
