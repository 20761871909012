import styled, { css } from 'styled-components';

export const FormContainer = styled.form`
  background: ${({ theme }) => theme.muted};
  border-radius: 0.625rem;
  color: white;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  max-width: 100%;
  padding: 2.5rem 1rem;
  width: 500px;
  ${({ style }) => style}
  h3,
  label,
  input,
  span,
  p,
  textarea {
    color: ${({ theme }) => theme.contrastTextColor};
  }
  input,
  span textarea {
    border-color: ${({ theme }) => theme.contrastTextColor};
  }
  input,
  textarea {
    ::placeholder {
      color: ${({ theme }) => theme.contrastTextColor};
    }
  }

  @media (min-width: 1440px) {
    padding: 2.5rem 1.25rem;
    max-width: 466.625px;
  }
`;

export const FormLabel = styled.h3`
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  justify-content: center;
  line-height: 1.75rem;

  ${({ hide }) =>
    hide
      ? css`
          height: 1px;
          opacity: 0;
          width: 1px;
        `
      : css`
          margin-bottom: 2rem;
        `}
`;

export const FormInputLabel = styled.label`
  align-items: center;
  display: flex;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  justify-content: flex-start;
  line-height: 19px;
  overflow: hidden;
  width: 100%;
  ${({ style }) => style}

  ${({ hide }) =>
    hide
      ? css`
          height: 1px;
          opacity: 0;
          width: 1px;
        `
      : css`
          margin-bottom: 0.375rem;
        `}
`;

export const FormInput = styled.input`
  align-items: center;
  background: transparent;
  border: 0.071em solid #fff;
  color: #fff;
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  height: 2.857em;
  letter-spacing: 0.036em;
  outline: none;
  padding: 0 1.143em;
  width: 100%;
  /* CONTINUE */

  :focus {
    border: 0.2rem solid #fff;
    padding: 0px 0.95em;
  }

  ${({ style }) => style}

  ::placeholder {
    color: #fff;
    font-size: 1rem;
    font-style: italic;
    font-weight: 600;
    ${({ style }) => style}
  }
`;

export const FormChecks = styled.div`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.9rem;
  position: relative;
`;

export const FormTerms = styled.span`
  color: ${({ checked, colors }) => (checked ? colors?.tertiary : '#fff')} !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin-top: 0.2em;
  transition: font-weight 200ms ease-in-out;
`;

export const FileUpload = styled.label`
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 6rem;
  justify-content: center;
  margin-bottom: 1.25rem;
`;

export const HiddenFileUpload = styled.input`
  display: none;
`;

export const TextArea = styled.textarea`
  background-color: transparent;
  border: 1px solid;
  border-radius: 0px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.contrastTextColor};
  font-size: 1rem;
  font-weight: 600;
  height: 10rem;
  letter-spacing: 0.5px;
  margin-bottom: 1.5rem;
  padding: 0.75rem 1.25rem;
  resize: none;
  width: 100%;
  ::placeholder {
    color: white !important;
    font-style: italic;
  }
  :focus {
    outline: none;
  }
`;
