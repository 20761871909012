import * as React from 'react';

function SvgPrevArrow(props) {
  return (
    <svg viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.071 16.071L3 9l7.071-7.071" stroke="#0095FF" strokeWidth={4} />
    </svg>
  );
}
export default SvgPrevArrow;
