import React, { useState, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { Arrow } from 'assets/svgs';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { hexToRGB } from 'utils';
import { LocalContext } from 'context';
import { pfizer } from 'styles';

export default ({ toggleNav }) => {
  const { mobile } = useContext(LocalContext);
  const [expanded, setExpanded] = useState(false);
  const dropdownToggle = () => setExpanded((currentValue) => !currentValue);

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativePath: { regex: "/data/days/" } }) {
        edges {
          node {
            childrenMarkdownRemark {
              id
              frontmatter {
                did
                slug
                title
                events {
                  eid
                  title
                  colors {
                    primary
                    secondary
                    tertiary
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const days = data.allFile.edges.map(({ node }) => node.childrenMarkdownRemark[0].frontmatter);

  const day1Data = days.filter(({ did }) => did === '1');
  const day2Data = days.filter(({ did }) => did === '2');
  const day3Data = days.filter(({ did }) => did === '3');
  const day4Data = days.filter(({ did }) => did === '4');

  const allDaysData = [...day1Data, ...day2Data, ...day3Data, ...day4Data];

  return (
    <Dropdown expanded={expanded}>
      <Heading
        onClick={() => {
          if (mobile) {
            dropdownToggle();
          }
        }}
        onMouseEnter={() => {
          if (!mobile) {
            dropdownToggle();
          }
        }}
        onMouseLeave={() => setExpanded(false)}
        expanded={expanded}>
        <DownArrow />
        <span>Event Selection</span>
        <ContentContainer expanded={expanded}>
          <AnimatePresence initial={false}>
            {expanded && (
              <Content
                onClick={() => {
                  setExpanded(false);
                  toggleNav();
                }}
                initial="exit"
                animate={expanded ? 'enter' : 'exit'}
                exit="exit"
                variants={{
                  enter: {
                    opacity: 1,
                    rotateX: 0,
                    transition: {
                      duration: 0.5
                    },
                    display: 'block'
                  },
                  exit: {
                    opacity: 0,
                    rotateX: -15,
                    transition: {
                      duration: 0.5,
                      delay: 0.3
                    },
                    transitionEnd: {
                      display: 'none'
                    }
                  }
                }}>
                {allDaysData.map((day, i) => (
                  <div key={i}>
                    <StyledLink
                      to={`/day-${day.did}/`}
                      onClick={() => {
                        setExpanded(false);
                        toggleNav();
                      }}
                      $bgColor={day.events[0].colors.primary}>
                      <H4 color={day.events[0].colors.primary}>{`Day ${day.did}`}</H4>
                    </StyledLink>
                  </div>
                ))}
              </Content>
            )}
          </AnimatePresence>
        </ContentContainer>
      </Heading>
    </Dropdown>
  );
};

const TechSupport = styled(motion.a).attrs({
  whileTap: {
    scale: 0.95
  }
})`
  align-items: center;
  background-color: #0095ff;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  font-weight: bold;
  justify-content: center;
  margin-left: 5rem;
  max-width: 206px;
  padding: 0.625rem 2.2rem;
  position: relative;
  text-transform: uppercase;
  top: 1px;
  white-space: nowrap;
  width: 100%;
`;

const DownArrow = styled(Arrow)`
  height: auto;
  margin-left: 0;
  margin-right: 0.5rem;
  min-width: 1rem;
  /* transform: ${({ expanded }) => (expanded ? 'scale(-1)' : 'none')}; */
  transition-duration: 0.5s;
  width: 1rem;

  path {
    stroke: ${({ expanded }) => (expanded ? '#3c3c3c' : pfizer.primary)};
    transition: 0.25s;
  }
  @media (min-width: 1150px) {
    height: auto;
    min-width: 0.75rem;
    width: 0.75rem;
    path {
      stroke: ${({ expanded }) => (expanded ? '#3c3c3c' : pfizer.primary)};
    }
  }
`;

const Content = styled(motion.section)`
  @media (min-width: 1150px) {
    width: 100%;

    a {
      text-align: left;
    }
  }
`;

const H4 = styled.h4`
  color: ${({ color }) => color};
  font-size: 1rem;
  text-transform: capitalize;

  @media (min-width: 1150px) {
    padding-left: 1.5rem;
  }
`;

const StyledLink = styled(Link)`
  align-items: center;
  display: flex;
  height: 3rem;
  justify-content: center;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;

  &:hover {
    background-color: ${({ $bgColor }) => hexToRGB({ color: $bgColor, alpha: 0.25 })};
  }

  @media (min-width: 1150px) {
    justify-content: flex-start;
  }
`;

const ContentContainer = styled.div`
  height: auto;
  text-align: center;
  width: 100%;

  @media (min-width: 1150px) {
    border: 1px solid #0095ff;
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.15);
    display: ${({ expanded }) => (expanded ? 'flex' : 'none')};
    height: auto;
    left: 0.6rem;
    position: absolute;
    text-align: left;
    top: 3.5rem;
    width: 9.375rem;
  }
`;

const Heading = styled(motion.div)`
  background: white;
  border: none;
  color: #3c3c3c;
  font-family: noto-sans, tahoma, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 3rem;
  outline: none;
  text-align: center;
  transition: 0.5s;
  width: 100%;

  > span {
    cursor: pointer;
  }

  @media (min-width: 1150px) {
    align-items: center;
    background: transparent;
    color: ${({ expanded }) => (expanded ? pfizer.secondary : '#3c3c3c')};
    display: flex;
    font-size: 0.875rem;
    height: 100%;
    line-height: 1.5rem;
    padding-left: 1em;
    text-align: left;
    width: 180px;

    &:hover {
      color: ${pfizer.secondary};
    }

    &:hover ${DownArrow} {
      path {
        stroke: ${pfizer.secondary};
      }
    }
  }
`;

const Dropdown = styled.div`
  height: auto;
  position: relative;
  width: 100%;

  @media (min-width: 1150px) {
    align-items: center;
    display: flex;
    grid-column: 3/6;
    height: 100%;
    margin-left: 1rem;
    overflow: visible;
    position: relative;
  }
`;
