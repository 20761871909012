import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { FirebaseContext } from 'context';
import {
  CommentArrow,
  DefaultCommenterAvatar,
  EmojiClap,
  EmojiLike,
  EmojiLove,
  EmojiSmile
} from 'assets/svgs';
import { fadeInAndOutVariants } from 'styles';
import { scrollToAnchor, hexToRGB } from 'utils';
import Interweave from 'interweave';
import { UrlMatcher, EmailMatcher } from 'interweave-autolink';
import { format } from 'date-fns';
import { Pin, ThumbsUp } from 'assets/animated-svgs';
import { MentionsMatcher } from './matchers';

const Chat = ({ colors, did, eid, handleParticipantAvatarOrNameClick }) => {
  const { firebase, user } = useContext(FirebaseContext);
  const [allComments, setAllComments] = useState([]);
  const [userCommentText, setUserCommentText] = useState('');
  const [showEmojisSelector, setShowEmojisSelector] = useState(false);

  useEffect(() => {
    let unsubscribeFromEventComments;

    if (firebase) {
      unsubscribeFromEventComments = firebase.subscribeToEventComments({
        did,
        eid,
        onSnapshot: (snapshot) => {
          if (!snapshot.empty) {
            const comments = [];

            snapshot.forEach((doc) => {
              comments.push({
                cid: doc.id,
                ...doc.data()
              });
            });

            setAllComments(comments);
          } else if (snapshot.empty) {
            setAllComments([]);
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromEventComments) {
        unsubscribeFromEventComments();
      }
      setAllComments([]);
    };
  }, [firebase, did, eid]);

  const handleUserCommentChange = (e) => {
    e.persist();
    setUserCommentText(e.target.value);
  };

  const handleUserCommentSubmit = (e) => {
    e.preventDefault();
    if (e.target.value !== '' && e.target.value !== ' ') {
      firebase
        .postEventComment({
          avatarUrl: user.avatarUrl,
          region: user.region,
          function: user.function,
          profession: user.profession || '',
          did,
          eid,
          name: user.name,
          text: userCommentText,
          uid: user.uid
        })
        .then(() => {
          setUserCommentText('');
          // scrollToAnchor('livestream', -90, 800);
        })
        .catch(console.error);
    }
  };

  const handleDeleteComment = ({ cid }) => {
    firebase.deleteEventComment({ did, eid, cid });
  };

  const handlePinComment = ({ cid, isPinned }) => {
    if (isPinned) {
      firebase.unpinEventComment({ did, eid, cid });
    } else {
      firebase.pinEventComment({ did, eid, cid });
    }
  };

  const handleEmojiClick = (emojiType) =>
    firebase.sendEmoji({ did, eid, emojiType }).catch(console.error);

  return (
    <Wrapper>
      <EventComments colors={colors}>
        {allComments.length > 0 ? (
          <AnimatePresence>
            {allComments.map((comment) => (
              <Comment
                layout
                key={comment.cid}
                colors={colors}
                isPinned={comment.pinned.status}
                initial={{
                  scale: 0,
                  opacity: 0
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  transition: {
                    duration: 0.9,
                    type: 'spring'
                  }
                }}
                exit={{
                  scale: 0,
                  opacity: 0,
                  transition: {
                    duration: 0.6,
                    type: 'tween'
                  }
                }}>
                {comment.avatarUrl ? (
                  <CommenterAvatar
                    onClick={() => handleParticipantAvatarOrNameClick(comment)}
                    size="1.625rem"
                    src={comment.avatarUrl}
                    alt={comment.name}
                    colors={colors}
                  />
                ) : (
                  <DefaultCommenterAvatar
                    onClick={() => handleParticipantAvatarOrNameClick(comment)}
                  />
                )}
                <CommentTextAndButtons colors={colors}>
                  <p>
                    <span
                      onClick={() => handleParticipantAvatarOrNameClick(comment)}
                      role="button"
                      tabIndex={0}>
                      {comment.name}
                    </span>
                    <Interweave
                      content={comment.text}
                      // matchers={[new UrlMatcher('url')]}
                      matchers={[
                        new UrlMatcher('url'),
                        new MentionsMatcher('mention'),
                        new EmailMatcher('email')
                      ]}
                      colors={colors}
                      tagName="q"
                      newWindow
                    />
                  </p>
                  {comment.timestamp && (
                    <small>{format(comment.timestamp.toDate(), 'HH:mm')}</small>
                  )}
                  {(user?.isModerator?.includes(`${did}_${eid}`) || user?.uid === comment?.uid) && (
                    <DeleteComment
                      whileTap={{ scale: 0.9 }}
                      onClick={() => handleDeleteComment({ cid: comment.cid })}>
                      Delete
                    </DeleteComment>
                  )}
                  {user?.isModerator?.includes(`${did}_${eid}`) && (
                    <PinComment
                      isPinned={comment.pinned.status}
                      whileTap={{ scale: 0.9 }}
                      onClick={() =>
                        handlePinComment({
                          cid: comment.cid,
                          isPinned: comment.pinned.status
                        })
                      }>
                      <Pin
                        stroke={comment.pinned.status ? colors.primary : '#000'}
                        fill={comment.pinned.status ? colors.primary : 'transparent'}
                      />
                      {comment.pinned.status ? 'Unpin' : 'Pin'}
                    </PinComment>
                  )}
                </CommentTextAndButtons>
              </Comment>
            ))}
          </AnimatePresence>
        ) : (
          <p style={{ color: '#000', paddingTop: '1.25rem' }}>Add a comment below...</p>
        )}
      </EventComments>
      <NewCommentForm onSubmit={handleUserCommentSubmit} autoComplete="off">
        <AddComment>
          <NewCommentInput
            onChange={handleUserCommentChange}
            value={userCommentText}
            id="comment"
            name="comment"
            type="text"
            placeholder="Type your message..."
            colors={colors}
          />
          <ChatButtons>
            <SubmitComment
              whileTap={{ scale: 0.9 }}
              disabled={userCommentText === '' || userCommentText === ' '}
              colors={colors}
              brighten={userCommentText}
              type="submit">
              <CommentArrow width="1rem" />
            </SubmitComment>
            <Emojis
              onTap={() => {
                if (!showEmojisSelector) {
                  setShowEmojisSelector(true);
                } else if (showEmojisSelector) {
                  // setShowEmojisSelector(false);
                }
              }}
              onHoverStart={() => setShowEmojisSelector(true)}
              onHoverEnd={() => setShowEmojisSelector(false)}
              colors={colors}>
              <AnimatePresence>
                {showEmojisSelector && (
                  <EmojiSelector
                    variants={fadeInAndOutVariants({
                      exitDelay: 0.1
                    })}
                    initial="initial"
                    animate="animate"
                    exit="exit">
                    <div>
                      <Emoji onClick={() => handleEmojiClick('smile')}>
                        <EmojiSmile />
                      </Emoji>
                      <Emoji onClick={() => handleEmojiClick('love')}>
                        <EmojiLove />
                      </Emoji>
                      <Emoji onClick={() => handleEmojiClick('like')}>
                        <EmojiLike />
                      </Emoji>
                      <Emoji onClick={() => handleEmojiClick('clap')}>
                        <EmojiClap />
                      </Emoji>
                      <div />
                    </div>
                  </EmojiSelector>
                )}
              </AnimatePresence>
              <ThumbsUp colors={colors} width="1.25rem" />
            </Emojis>
          </ChatButtons>
        </AddComment>
      </NewCommentForm>
    </Wrapper>
  );
};

const Wrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  background-color: #fff;
`;

export const CommenterAvatar = styled.img`
  border: 0.125rem solid ${({ colors }) => colors.primary};
  border-radius: 50%;
  height: ${({ size }) => size};
  object-fit: cover;
  position: relative;
  width: ${({ size }) => size};
`;

const AddComment = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const NewCommentForm = styled.form`
  background: white;
  border-top: 1px solid #c4c4c4;
  bottom: 0;
  height: auto;
  left: 0;
  padding: 1rem;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

const EventComments = styled.div`
  border-top: none;
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  height: calc(100% - 4.5rem);
  /* max-height: 32.563rem; */
  min-height: 18.75rem;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 1rem;
  width: 100%;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.primary};
    border-radius: 0.625rem;
  }
`;

const Comment = styled(motion.div)`
  align-items: flex-start;
  background-color: ${({ isPinned, colors }) =>
    isPinned ? hexToRGB({ color: colors.primary, alpha: 0.2 }) : 'transparent'};
  color: #000;
  display: flex;
  margin-bottom: 0.7em;
  padding: 0.125em 0.25em 0;

  &:first-of-type {
    margin-top: 1.25rem;
  }

  /* Avatars */
  > img:first-child,
  > svg:first-child {
    flex-shrink: 0;
    margin-right: 0.5rem;
    position: relative;
    top: 0.313rem;
    width: 1.625rem;
    cursor: pointer;
  }

  > div {
    bottom: 0.375rem;
    position: relative;

    p {
      color: ${({ isPinned, colors }) => (isPinned ? colors.primary : '#000')};
      font-size: 1rem;
      line-height: 1.25em;
      margin-top: 0.575em;
      q {
        &:before {
          content: '';
        }
        &:after {
          content: '';
        }
      }

      span {
        cursor: pointer;
        color: ${({ isPinned, colors }) => (isPinned ? colors.primary : '#c4c4c4')};
        font-weight: 600;
        margin-right: 0.475rem;
      }
    }

    small {
      bottom: 0.125em;
      display: inline-block;
      font-size: 0.65rem;
      left: 0.063em;
      position: relative;

      &:last-of-type {
        color: ${({ isPinned, colors }) => (isPinned ? colors.primary : '#000')};
      }
    }
  }
`;

const CommentTextAndButtons = styled.div`
  p {
    color: ${({ colors }) => colors.primary};
  }
  a {
    color: ${({ colors }) => colors.primary};
    text-decoration: underline;
  }
`;

const DeleteComment = styled(motion.small)`
  cursor: pointer;
  margin-left: 0.5em;
  text-decoration: underline;
`;

export const PinComment = styled(DeleteComment)`
  margin-left: 2.6em;
  position: relative;

  > svg {
    bottom: -0.1em;
    height: 1.2em;
    left: -1.45em;
    position: absolute;
    width: 1.2em;
  }
`;

const ChatButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 6.25rem;

  > * {
    margin-left: 0.625rem;
  }
`;

const NewCommentInput = styled.input`
  align-items: center;
  background-color: transparent;
  border: 1px solid #c4c4c4;
  border-bottom: 1px solid;
  border-color: #c4c4c4;
  border-radius: 2px;
  color: #00004e;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  height: 2.5rem;
  justify-content: flex-start;
  letter-spacing: 0.5px;
  line-height: 2rem;
  outline-color: #c4c4c4;
  padding: 0.625rem;
  width: calc(100% - 6.25rem);

  &:focus {
    outline-color: ${({ colors }) => colors.primary};
  }

  ::placeholder {
    color: #bdbdbd;
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
  }
`;

const SubmitComment = styled(motion.button)`
  align-items: center;
  background-color: ${({ brighten, colors }) =>
    brighten ? colors.primary : 'rgba(196, 196, 196, 0.3)'};
  border-radius: 2px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  height: 2.5rem;
  justify-content: center;
  transition: background-color 0.25s ease-in-out, stroke 0.25s ease-in-out,
    outline-color 0.25s ease-in-out;
  width: 2.5rem;

  svg path {
    stroke: ${({ brighten }) => brighten && '#fff'};
  }

  &:focus {
    outline-color: ${({ colors }) => colors.primary};
  }
`;

const EmojiSelector = styled(motion.div)`
  height: 3.25rem;
  position: absolute;
  right: -0.063rem;
  top: -3.125rem;
  width: 10rem;

  > div {
    background-color: #fff;
    box-shadow: 0px 0.25rem 0.625rem rgba(0, 0, 0, 0.14);
    display: flex;
    height: 2.5rem;
    justify-content: space-between;
    padding: 0.5rem 0.688rem;
    position: relative;

    /* Speech bubble triangle */
    > div {
      background-color: #fff;
      bottom: -0.25rem;
      height: 0.5rem;
      pointer-events: none;
      position: absolute;
      right: 0.875rem;
      transform: rotate(45deg);
      width: 0.5rem;
    }
  }
`;

const Emojis = styled(motion.div)`
  align-items: center;
  background-color: #fff;
  border: 1px solid ${({ colors }) => colors.primary};
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  position: relative;
  transition: background-color 0.25s ease-in-out, fill 0.25s ease-in-out;
  width: 2.5rem;

  &:hover {
    background-color: ${({ colors }) => colors.primary};

    > svg path {
      fill: #fff;
    }
  }
`;

const Emoji = styled(motion.span).attrs({
  whileTap: {
    scale: 0.9
  },
  whileHover: {
    scale: 1.1
  }
})`
  display: inline-flex;

  svg {
    width: 1.5rem;
  }
`;

export default Chat;
