import * as React from 'react';

function SvgLogo(props) {
  return (
    <svg viewBox="0 0 105 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M.3 23.045c1.264-3.61 8.424-5.115 18.894-3.851l2.045-7.46c-10.409-1.325-17.93.12-19.193 4.813L.3 23.045z"
        fill="#0095FF"
      />
      <path
        d="M38.026 15.283c-1.263 3.61-8.423 5.114-18.832 3.85l-2.046 7.461c10.349 1.324 17.87-.18 19.133-4.873l1.745-6.438z"
        fill="#0000C9"
      />
      <path
        d="M6.92 7.46c15.282 1.084 30.866 8.665 29.362 14.26l1.383-5.053c.422-1.565.662-2.407.662-3.069C38.327 7.882 23.285.842 8.965 0L6.92 7.46z"
        fill="#0095FF"
      />
      <path
        d="M31.408 30.866C16.125 29.723.542 22.202 2.046 16.546L.662 21.601C.24 23.165 0 24.006 0 24.669c0 5.716 15.042 12.756 29.362 13.598l2.046-7.4zM87.003 20.698c1.143 0 1.685.722 1.685 2.347v.481h-4.332c.481-1.685 1.504-2.828 2.647-2.828zm.06 8.424c-1.925 0-2.948-1.204-2.948-3.37v-.42h7.581c.12-.602.24-1.204.24-1.806 0-2.647-1.624-4.693-4.993-4.693-3.43 0-6.438 3.37-6.438 6.92 0 3.369 2.226 5.415 5.896 5.415 1.625 0 3.25-.542 4.393-1.384l-.722-1.745c-.843.601-1.926 1.023-3.009 1.083zM48.195 22.323H46.81l1.865-6.98h1.444c1.805 0 2.828 1.203 2.828 2.888 0 2.587-2.046 4.092-4.753 4.092zm8.784-4.272c0-2.768-2.166-4.754-5.415-4.754h-6.318l-4.753 17.57h4.031l1.745-6.499h3.49c3.911 0 7.22-2.527 7.22-6.317zM62.214 19.134l.421-1.625c.24-.842.602-2.286 1.143-3.97.542-1.746 1.565-2.829 2.889-2.829.661 0 1.082.301 1.082.782 0 .542-.48.903-.48 1.745 0 1.023.902 1.805 1.985 1.805a2.542 2.542 0 002.527-2.527c0-2.106-1.805-3.55-4.633-3.55-4.092 0-6.8 2.588-8.424 8.725l-6.799 24.85h3.851l5.896-21.48h4.032l-2.648 9.806h3.851l3.19-11.732h-7.883z"
        fill="#0000C9"
      />
      <path
        d="M81.769 19.133H71.54L71 21.06h4.994l-7.702 9.807h10.83l.482-1.925h-5.536l7.702-9.808zM102.045 18.953c-1.384 0-2.828.902-4.031 2.767l.662-2.527h-3.85l-3.19 11.733h3.851l1.444-5.295c.602-2.166 1.986-4.272 2.888-4.272 1.023 0 .602 1.866 2.347 1.866 1.263 0 1.985-1.144 1.985-2.227.06-1.203-.902-2.045-2.106-2.045z"
        fill="#0000C9"
      />
    </svg>
  );
}
export default SvgLogo;
