/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useWindowSize } from 'hooks';
import { TimeZoneSelector } from 'components';
import { getTimeZoneOffset } from 'utils';
// import AddToCalendar from './AddToCalendar';

const colors = {
  primary: '#0095FF',
  secondary: '#0E41D5',
  tertiary: '#891AFC'
};

const cityNames = ['New York', 'São Paulo', 'Dubai', 'Hong Kong'];

const AgendaAllDaysVisibleVersion = () => {
  const { windowWidth } = useWindowSize();
  const [selectedTimeZone, setSelectedTimeZone] = useState('');

  return (
    <div style={{ margin: windowWidth < 1200 ? '0 1rem' : 0 }} id="agenda">
      {windowWidth < 1150 ? (
        <>
          <Agenda>Agenda Overview</Agenda>
          {/* <P>Click on any of the flashing icons below to add that event to your calendar.</P> */}
          <TimeZoneSelectorWrapper>
            <TimeZoneSelector
              cityNames={cityNames}
              selectedTimeZone={selectedTimeZone}
              setSelectedTimeZone={setSelectedTimeZone}
              colors={colors}
            />
          </TimeZoneSelectorWrapper>
          <Table>
            <thead>
              <tr>
                <th>Time</th>
                <th style={{ color: '#0095FF' }}>Day 1 - TUE, FEB 21st</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Start:
                  <br />
                  {`${getTimeZoneOffset({ hour: 12, selectedTimeZone })}:00 ${selectedTimeZone}`}
                </td>
                <td style={{ backgroundColor: 'rgba(0, 149, 255, 0.4)' }}>
                  <p>
                    <strong>Welcome - POA Captains</strong>
                    <br />
                    Laybet Castillo and Hatem El Kadi (5 mins)
                  </p>
                </td>
              </tr>
              <tr height="9px" />
              <tr>
                <td />
                <td style={{ backgroundColor: 'rgba(0, 149, 255, 0.2)' }}>
                  <p>
                    <strong>
                      EM Oncology in 2023
                      <br />- Opening and Panel Discussion
                    </strong>
                    <br />
                    Meaghan Jones, Roberto Uehara, and Manoj
                    <br />
                    Irap, with messages from Nick Lagunowich,
                    <br />
                    Suneet Varma, and Dany Habr (50 mins)
                  </p>
                </td>
              </tr>
              <tr height="9px" />
              <tr>
                <td />
                <td style={{ backgroundColor: 'rgba(0, 149, 255, 0.2)' }}>
                  <p>
                    <strong>Nikki Bradley</strong>
                    <br />
                    Guest Speaker
                    <br />
                    (30 mins)
                  </p>
                </td>
              </tr>
              <tr>
                <td />
                <td>
                  <p>
                    <strong>Break</strong> (10 Mins)
                  </p>
                </td>
              </tr>
              <tr>
                <td />
                <td style={{ backgroundColor: 'rgba(0, 149, 255, 0.2)' }}>
                  <p>
                    <strong>Sound Bites - LatAm RTAL and RML</strong>
                    <br />
                    Simon Hagger and Luis Alberto Suarez
                    <br />
                    (10 mins)
                  </p>
                </td>
              </tr>
              <tr height="9px" />
              <tr>
                <td />
                <td style={{ backgroundColor: 'rgba(0, 149, 255, 0.2)' }}>
                  <p>
                    <strong>Breast Cancer</strong>
                    <br />
                    Armando Juarez, Lakshmi Parasuraman,
                    <br />
                    Alexandra Guarin
                    <br />
                    (35 mins)
                  </p>
                </td>
              </tr>
              <tr height="9px" />
              <tr>
                <td>
                  End:
                  <br />
                  {`${getTimeZoneOffset({ hour: 14, selectedTimeZone })}:30 ${selectedTimeZone}`}
                </td>
                <td style={{ backgroundColor: 'rgba(0, 149, 255, 0.4)' }}>
                  <p>
                    <strong>Closing the Day with #JOY!</strong>
                    <br />
                    (10 mins)
                  </p>
                </td>
              </tr>
            </tbody>
          </Table>
          <Table>
            <thead>
              <tr>
                <th>Time</th>
                <th style={{ color: '#891AFC' }}>Day 2 - WED, FEB 22nd</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Start:
                  <br />
                  {`${getTimeZoneOffset({ hour: 12, selectedTimeZone })}:00 ${selectedTimeZone}`}
                </td>
                <td style={{ backgroundColor: 'rgba(137, 26, 252, 0.4)' }}>
                  <p>
                    <strong>Session Opening - the POA Captains</strong>
                    <br />
                    Harry Ngai and Lakshmi Parasuraman (5 mins)
                  </p>
                </td>
              </tr>
              <tr height="9px" />
              <tr>
                <td />
                <td style={{ backgroundColor: 'rgba(137, 26, 252, 0.2)' }}>
                  <p>
                    <strong>Conversations with Gabriela and Ehab</strong>
                    <br />
                    Gabriela Bodea and Ehab Mahgoub (10 mins)
                  </p>
                </td>
              </tr>
              <tr height="9px" />
              <tr>
                <td />
                <td style={{ backgroundColor: 'rgba(137, 26, 252, 0.2)' }}>
                  <p>
                    <strong>Sound Bites - MERA RTAL and RML</strong>
                    <br />
                    Mohamed Okasha and Hatem El Kadi (10 mins)
                  </p>
                </td>
              </tr>
              <tr height="9px" />
              <tr>
                <td />
                <td style={{ backgroundColor: 'rgba(137, 26, 252, 0.2)' }}>
                  <p>
                    <strong>Biosimilars</strong>
                    <br />
                    Daniel Doria and Waleed El Morsi
                    <br />
                    (25 mins)
                  </p>
                </td>
              </tr>
              <tr>
                <td />
                <td>
                  <p>
                    <strong>Break</strong> (10 Mins)
                  </p>
                </td>
              </tr>
              <tr>
                <td />
                <td style={{ backgroundColor: 'rgba(137, 26, 252, 0.2)' }}>
                  <p>
                    <strong>Sound Bites - EM Asia RTAL and RML</strong>
                    <br />
                    Laybet Castillo and Manmohan Singh
                    <br />
                    (10 mins)
                  </p>
                </td>
              </tr>
              <tr height="9px" />
              <tr>
                <td />
                <td style={{ backgroundColor: 'rgba(137, 26, 252, 0.2)' }}>
                  <p>
                    <strong>Lung Cancer</strong>
                    <br />
                    Mohamed El Sayed and Chew Hooi Wong
                    <br />
                    (35 mins)
                  </p>
                </td>
              </tr>
              <tr height="9px" />
              <tr>
                <td />
                <td style={{ backgroundColor: 'rgba(137, 26, 252, 0.2)' }}>
                  <p>
                    <strong>Sound Bites - Precision Medicine Lead</strong>
                    <br />
                    Tarek Fahmi
                    <br />
                    (5 mins)
                  </p>
                </td>
              </tr>
              <tr height="9px" />
              <tr>
                <td />
                <td style={{ backgroundColor: 'rgba(137, 26, 252, 0.2)' }}>
                  <p>
                    <strong>Colorectal Cancer and Melanoma</strong>
                    <br />
                    Mohamed Adel and Julieta D&apos;Annunzio
                    <br />
                    (25 mins)
                  </p>
                </td>
              </tr>
              <tr height="9px" />
              <tr>
                <td>
                  End:
                  <br />
                  {`${getTimeZoneOffset({ hour: 14, selectedTimeZone })}:30 ${selectedTimeZone}`}
                </td>
                <td style={{ backgroundColor: 'rgba(137, 26, 252, 0.4)' }}>
                  <p>
                    <strong>Closing the Day with #JOY!</strong>
                    <br />
                    (10 mins)
                  </p>
                </td>
              </tr>
            </tbody>
          </Table>
          <Table>
            <thead>
              <tr>
                <th>Time</th>
                <th style={{ color: '#C7D601' }}>Day 3 - THU, FEB 23rd</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Start:
                  <br />
                  {`${getTimeZoneOffset({ hour: 12, selectedTimeZone })}:00 ${selectedTimeZone}`}
                </td>
                <td style={{ backgroundColor: 'rgba(199, 214, 1, 0.4)' }}>
                  <p>
                    <strong>Session Opening</strong> - the POA Captains
                    <br />
                    Harry Ngai and Lakshmi Parasuraman
                    <br />
                    (5 mins)
                  </p>
                </td>
              </tr>
              <tr height="9px" />
              <tr>
                <td />
                <td style={{ backgroundColor: 'rgba(199, 214, 1, 0.2)' }}>
                  <p>
                    <strong>Sound Bites - Access</strong>
                    <br />
                    Silver Chen (10 mins)
                  </p>
                </td>
              </tr>
              <tr height="9px" />
              <tr>
                <td />
                <td style={{ backgroundColor: 'rgba(199, 214, 1, 0.2)' }}>
                  <p>
                    <strong>Genitourinary Cancer</strong>
                    <br />
                    Mahmoud Ammar, Waleed El Morsi, Ahmed Hashem
                    <br />
                    (35 mins)
                  </p>
                </td>
              </tr>
              <tr>
                <td />
                <td>
                  <p>
                    <strong>Break</strong> (10 Mins)
                  </p>
                </td>
              </tr>
              <tr>
                <td />
                <td style={{ backgroundColor: 'rgba(199, 214, 1, 0.2)' }}>
                  <p>
                    <strong>
                      Sound Bites
                      <br />- EM Commercial Development
                    </strong>
                    <br />
                    Shimin Qi (10 mins)
                  </p>
                </td>
              </tr>
              <tr height="9px" />
              <tr>
                <td />
                <td style={{ backgroundColor: 'rgba(199, 214, 1, 0.2)' }}>
                  <p>
                    <strong>
                      What&apos;s Next in Oncology?
                      <br />
                      Global Pipeline Update
                    </strong>
                    <br />
                    Ritesh Shah and Subramanian Hariharan
                    <br />
                    (10 mins)
                  </p>
                </td>
              </tr>
              <tr height="9px" />
              <tr>
                <td />
                <td style={{ backgroundColor: 'rgba(199, 214, 1, 0.2)' }}>
                  <p>
                    <strong>Hematology</strong>
                    <br />
                    Archie Huang and Vivian Blunk
                    <br />
                    (35 mins)
                  </p>
                </td>
              </tr>
              <tr height="9px" />
              <tr>
                <td />
                <td style={{ backgroundColor: 'rgba(199, 214, 1, 0.2)' }}>
                  <p>
                    <strong>Leaping Into the Future with #JOY</strong>
                    <br />
                    (25 mins)
                  </p>
                </td>
              </tr>
              <tr height="9px" />
              <tr>
                <td>
                  End:
                  <br />
                  {`${getTimeZoneOffset({ hour: 14, selectedTimeZone })}:30 ${selectedTimeZone}`}
                </td>
                <td style={{ backgroundColor: 'rgba(199, 214, 1, 0.4)' }}>
                  <p>
                    <strong>
                      Awards Ceremony and Highlights
                      <br />
                      - POA Captains
                      <br />
                      Harry Ngai and Lakshmi Parasuraman
                      <br />
                      (10 mins)
                    </strong>
                  </p>
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      ) : (
        <>
          <Agenda>Agenda Overview</Agenda>
          {/* <P>Click on any of the flashing icons below to add that event to your calendar.</P> */}
          <TimeZoneSelectorWrapper>
            <TimeZoneSelector
              cityNames={cityNames}
              selectedTimeZone={selectedTimeZone}
              setSelectedTimeZone={setSelectedTimeZone}
              colors={colors}
            />
          </TimeZoneSelectorWrapper>
          <Table>
            <thead>
              <tr>
                <th>Time:</th>
                <th style={{ width: '20px' }} />
                <th style={{ color: '#0095FF' }}>Day 1 - TUE, FEB 21st</th>
                <th style={{ width: '20px' }} />
                <th style={{ color: '#891AFC' }}>Day 2 - WED, FEB 22nd</th>
                <th style={{ width: '20px' }} />
                <th style={{ color: '#C7D601' }}>Day 3 - THU, FEB 23rd</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ position: 'relative', width: '98px' }}>
                  <p>
                    Start:
                    <br />
                    {`${getTimeZoneOffset({ hour: 12, selectedTimeZone })}:00 ${selectedTimeZone}`}
                  </p>
                  <p
                    style={{ position: 'absolute', bottom: 'calc(50% + 110px)' }}>{`${getTimeZoneOffset({
                    hour: 13,
                    selectedTimeZone
                  })}:00 ${selectedTimeZone}`}</p>
                  <p style={{ position: 'absolute', bottom: 0 }}>
                    End:
                    <br />
                    {`${getTimeZoneOffset({ hour: 14, selectedTimeZone })}:30 ${selectedTimeZone}`}
                  </p>
                </td>
                <td style={{ width: '20px' }} />
                <td>
                  <Column>
                    <Slot style={{ backgroundColor: 'rgba(0, 149, 255, 0.4)', minHeight: '86px' }}>
                      <p>
                        <strong>Welcome - POA Captains</strong>
                        <br />
                        Laybet Castillo and Hatem El Kadi (5 mins)
                      </p>
                    </Slot>
                    <Slot style={{ backgroundColor: 'rgba(0, 149, 255, 0.2)', minHeight: '260px' }}>
                      <p>
                        <strong>
                          EM Oncology in 2023
                          <br />- Opening and Panel Discussion
                        </strong>
                        <br />
                        Meaghan Jones, Roberto Uehara, and Manoj
                        <br />
                        Irap, with messages from Nick Lagunowich,
                        <br />
                        Suneet Varma, and Dany Habr (50 mins)
                      </p>
                    </Slot>
                    <Slot style={{ backgroundColor: 'rgba(0, 149, 255, 0.2)', minHeight: '132px' }}>
                      <p>
                        <strong>Nikki Bradley</strong>
                        <br />
                        Guest Speaker
                        <br />
                        (30 mins)
                      </p>
                    </Slot>
                    <Slot style={{ minHeight: '56px' }}>
                      <p>
                        <strong>Break</strong> (10 Mins)
                      </p>
                    </Slot>
                    <Slot style={{ backgroundColor: 'rgba(0, 149, 255, 0.2)', minHeight: '100px' }}>
                      <p>
                        <strong>Sound Bites - LatAm RTAL and RML</strong>
                        <br />
                        Simon Hagger and Luis Alberto Suarez
                        <br />
                        (10 mins)
                      </p>
                    </Slot>
                    <Slot style={{ backgroundColor: 'rgba(0, 149, 255, 0.2)', minHeight: '143px' }}>
                      <p>
                        <strong>Breast Cancer</strong>
                        <br />
                        Armando Juarez, Lakshmi Parasuraman,
                        <br />
                        Alexandra Guarin (35 mins)
                      </p>
                    </Slot>
                    <Slot style={{ backgroundColor: 'rgba(0, 149, 255, 0.4)', minHeight: '80px' }}>
                      <p>
                        <strong>Closing the Day with #JOY!</strong>
                        <br />
                        (10 mins)
                      </p>
                    </Slot>
                  </Column>
                </td>
                <td style={{ width: '20px' }} />
                <td>
                  <Column>
                    <Slot style={{ backgroundColor: 'rgba(137, 26, 252, 0.4)', minHeight: '86px' }}>
                      <p>
                        <strong>Session Opening - the POA Captains</strong>
                        <br />
                        Harry Ngai and Lakshmi Parasuraman (5 mins)
                      </p>
                    </Slot>
                    <Slot style={{ backgroundColor: 'rgba(137, 26, 252, 0.2)', minHeight: '64px' }}>
                      <p>
                        <strong>Conversations with Gabriela and Ehab</strong>
                        <br />
                        Gabriela Bodea and Ehab Mahgoub (10 mins)
                      </p>
                    </Slot>
                    <Slot style={{ backgroundColor: 'rgba(137, 26, 252, 0.2)', minHeight: '80px' }}>
                      <p>
                        <strong>Sound Bites - MERA RTAL and RML</strong>
                        <br />
                        Mohamed Okasha and Hatem El Kadi (10 mins)
                      </p>
                    </Slot>
                    <Slot style={{ backgroundColor: 'rgba(137, 26, 252, 0.2)', minHeight: '96px' }}>
                      <p>
                        <strong>Biosimilars</strong>
                        <br />
                        Daniel Doria and Waleed El Morsi
                        <br />
                        (25 mins)
                      </p>
                    </Slot>
                    <Slot>
                      <p>
                        <strong>Break</strong> (10 Mins)
                      </p>
                    </Slot>
                    <Slot style={{ backgroundColor: 'rgba(137, 26, 252, 0.2)', minHeight: '84px' }}>
                      <p>
                        <strong>Sound Bites - EM Asia RTAL and RML</strong>
                        <br />
                        Laybet Castillo and Manmohan Singh
                        <br />
                        (10 mins)
                      </p>
                    </Slot>
                    <Slot
                      style={{ backgroundColor: 'rgba(137, 26, 252, 0.2)', minHeight: '132px' }}>
                      <p>
                        <strong>Lung Cancer</strong>
                        <br />
                        Mohamed El Sayed, Chew Hooi Wong
                        <br />
                        (35 mins)
                      </p>
                    </Slot>
                    <Slot style={{ backgroundColor: 'rgba(137, 26, 252, 0.2)', minHeight: '80px' }}>
                      <p>
                        <strong>Sound Bites - Precision Medicine Lead</strong>
                        <br />
                        Tarek Fahmi (5 mins)
                      </p>
                    </Slot>
                    <Slot style={{ backgroundColor: 'rgba(137, 26, 252, 0.2)', minHeight: '80px' }}>
                      <p>
                        <strong>Colorectal Cancer and Melanoma</strong>
                        <br />
                        Mohamed Adel and Julieta D&apos;Annunzio
                        <br />
                        (25 mins)
                      </p>
                    </Slot>
                    <Slot style={{ backgroundColor: 'rgba(137, 26, 252, 0.4)', minHeight: '80px' }}>
                      <p>
                        <strong>Closing the Day with #JOY!</strong>
                        <br />
                        (10 mins)
                      </p>
                    </Slot>
                  </Column>
                </td>
                <td style={{ width: '20px' }} />
                <td>
                  <Column>
                    <Slot style={{ backgroundColor: 'rgba(199, 214, 1, 0.4)', minHeight: '86px' }}>
                      <p>
                        <strong>Session Opening - the POA Captains</strong>
                        <br />
                        Harry Ngai and Lakshmi Parasuraman
                        <br />
                        (5 mins)
                      </p>
                    </Slot>
                    <Slot style={{ backgroundColor: 'rgba(199, 214, 1, 0.2)', minHeight: '64px' }}>
                      <p>
                        <strong>Sound Bites - Access</strong>
                        <br />
                        Silver Chen (10 mins)
                      </p>
                    </Slot>
                    <Slot style={{ backgroundColor: 'rgba(199, 214, 1, 0.2)', minHeight: '130px' }}>
                      <p>
                        <strong>Genitourinary Cancer</strong>
                        <br />
                        Mahmoud Ammar, Waleed El Morsi, Ahmed
                        <br />
                        Hashem (35 mins)
                      </p>
                    </Slot>
                    <Slot>
                      <p>
                        <strong>Break</strong> (10 Mins)
                      </p>
                    </Slot>
                    <Slot style={{ backgroundColor: 'rgba(199, 214, 1, 0.2)', minHeight: '80px' }}>
                      <p>
                        <strong>
                          Sound Bites
                          <br />- EM Commercial Development
                        </strong>
                        <br />
                        Shimin Qi (10 mins)
                      </p>
                    </Slot>
                    <Slot style={{ backgroundColor: 'rgba(199, 214, 1, 0.2)', minHeight: '108px' }}>
                      <p>
                        <strong>
                          What&apos;s Next in Oncology?
                          <br />
                          Global Pipeline Update
                        </strong>
                        <br />
                        Ritesh Shah and Subramanian Hariharan
                        <br />
                        (10 mins)
                      </p>
                    </Slot>
                    <Slot style={{ backgroundColor: 'rgba(199, 214, 1, 0.2)', minHeight: '123px' }}>
                      <p>
                        <strong>Hematology</strong>
                        <br />
                        Archie Huang, Vivian Blunk
                        <br />
                        (35 mins)
                      </p>
                    </Slot>
                    <Slot style={{ backgroundColor: 'rgba(199, 214, 1, 0.2)', minHeight: '94px' }}>
                      <p>
                        <strong>Leaping Into the Future with #JOY</strong>
                        <br />
                        (25 mins)
                      </p>
                    </Slot>
                    <Slot style={{ backgroundColor: 'rgba(199, 214, 1, 0.4)', minHeight: '108px' }}>
                      <p>
                        <strong>Awards Ceremony and Highlights</strong>
                        <br />
                        - POA Captains
                        <br />
                        Harry Ngai and Lakshmi Parasuraman
                        <br />
                        (10 mins)
                      </p>
                    </Slot>
                  </Column>
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
};

const TimeZoneSelectorWrapper = styled.div`
  margin: 0 auto;
  max-width: 27.5rem;

  @media screen and (min-width: 1150px) {
    margin: 0 auto 2.75rem;
    max-width: 72.75rem;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 343px;
`;

const Slot = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem 0.5rem;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

const Table = styled.table`
  margin: 0 auto 69px;
  max-width: 27.5rem;
  width: 100%;

  @media screen and (min-width: 1150px) {
    margin: 0 auto 4.063rem;
    max-width: 72.75rem;
  }

  &,
  th,
  td {
    border-collapse: collapse;
  }

  thead th {
    text-align: center;

    &:nth-of-type(1) {
      text-align: left;
      position: relative;
      top: 0.063rem;
    }
  }

  tbody td {
    vertical-align: center;

    @media screen and (min-width: 1150px) {
      vertical-align: baseline;

      :nth-of-type(5),
      :nth-of-type(7) {
        vertical-align: top;
      }
    }
  }

  tbody tr td:nth-of-type(1) {
    white-space: nowrap;
  }

  th {
    font-size: 1rem;
    text-transform: uppercase;

    sup {
      text-transform: lowercase;
    }
  }

  td {
    color: #3c3c3c;
    font-size: 0.75rem;
    line-height: 1.643em;

    &:not(:first-of-type) {
      text-align: center;
      font-size: 0.875rem;
    }

    @media screen and (min-width: 1150px) {
      &:first-of-type {
        font-size: 0.875rem;
      }
    }
  }

  th,
  td {
    padding: 0.5rem;

    &:first-of-type {
      padding: 0.5rem 0.75rem 0.5rem 0;
    }
  }

  /* td:last-of-type {
    width: 100%;
  } */

  @media screen and (min-width: 1150px) {
    padding: 0.625rem;

    td {
      padding: 0;
    }
  }
`;

const Agenda = styled.h2`
  font-size: 1.5rem;
  margin: 1.083em auto 0.5em;
  max-width: 27.5rem;
  width: 100%;

  @media screen and (min-width: 1150px) {
    margin: 2.2em auto 1.4em;
    max-width: 72.75rem;
    width: 100%;
  }
`;

const P = styled.p`
  font-size: 1rem;
  margin: 0 auto 1em;
  max-width: 27.5rem;
  width: 100%;

  @media screen and (min-width: 1150px) {
    margin: 0 auto 1.5em;
    max-width: 72.875rem;
  }
`;

export default AgendaAllDaysVisibleVersion;
