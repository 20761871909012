import * as React from 'react';

function SvgNikkiSwirl(props) {
  return (
    <svg viewBox="0 0 216 293" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity={0.2} strokeWidth={2} strokeMiterlimit={10}>
        <path
          style={{
            mixBlendMode: 'luminosity'
          }}
          d="M89.25 217.828c6.598 2.178 3.781 8.45-2.423 14.178-6.204 5.728-15.796 10.911-22.745 10.911-13.9 0-25.17-11.233-25.17-25.09 0-6.929 3.265-14.153 8.19-20.152 4.926-6 11.514-10.774 18.16-12.803 6.592-2.012 7.44 4.527 9.214 12.419 1.774 7.891 4.475 17.137 14.773 20.536l.002.001z"
          stroke="url(#nikki-swirl_svg__paint0_linear_284_1387)"
        />
        <path
          d="M78.748 154.532c8.785-2.533 12.058 3.737 15.882 13.196 2.846 7.037 5.422 15.158 7.77 23.194 2.313 7.976 3.867 16.22 7.751 21.923 3.964 5.821.966 13.581-5.621 21.178-8.091 9.331-19.701 16.922-30.1 16.922-13.117 0-23.32-3.251-30.207-9.78-6.778-6.417-10.327-16.029-10.327-28.937 0-7.422 2.636-15.615 6.921-23.468 2.113-3.872 4.629-7.658 7.43-11.227 3.078-3.92 6.5-7.58 10.112-10.806 6.507-5.839 13.618-10.245 20.389-12.196v.001z"
          stroke="url(#nikki-swirl_svg__paint1_linear_284_1387)"
        />
        <path
          d="M91.877 124.215c10.969-3.088 17.024 2.853 22.552 13.974 4.989 10.038 8.815 22.003 11.285 34.328 2.442 12.275 2.725 24.93 4.982 35.37 1.86 8.603-1.742 18.827-8.818 28.178-9.877 13.05-23.607 22.934-37.457 22.934-19.183 0-33.212-3.614-42.418-12.013-9.118-8.31-13.48-21.343-13.48-40.33 0-10.27 3.846-21.95 10.029-33.258 3-5.485 6.55-10.878 10.483-15.98 4.33-5.618 9.123-10.884 14.15-15.53 9.275-8.602 19.329-15.075 28.693-17.674v.001z"
          stroke="url(#nikki-swirl_svg__paint2_linear_284_1387)"
        />
        <path
          d="M104.602 93.914c13.157-3.626 22.071 1.914 29.219 14.752 7.23 12.983 12.209 28.848 14.802 45.461 2.571 16.577 2.079 33.71 2.213 48.82.101 11.48-4.398 24.11-12.017 35.177-11.582 16.824-27.514 28.945-44.812 28.945-25.249 0-43.105-3.974-54.63-14.245-11.457-10.205-16.634-26.656-16.634-51.724 0-13.116 5.057-28.284 13.139-43.047 3.886-7.098 8.472-14.099 13.535-20.733 5.583-7.317 11.746-14.189 18.188-20.253 12.044-11.366 25.044-19.892 36.997-23.151v-.002z"
          stroke="url(#nikki-swirl_svg__paint3_linear_284_1387)"
        />
        <path
          d="M116.012 63.628c15.347-4.165 27.148.946 35.888 15.529 9.522 15.887 15.603 35.693 18.317 56.594 2.701 20.876 1.586 42.627-.557 62.267-1.581 14.495-7.021 29.407-15.214 42.176-13.232 20.628-31.419 34.956-52.167 34.956-31.314 0-52.999-4.335-66.84-16.478-13.799-12.098-19.788-31.97-19.788-63.12 0-15.962 6.267-34.616 16.248-52.835 4.771-8.71 10.393-17.32 16.587-25.486 6.837-9.016 14.37-17.494 22.225-24.976 14.813-14.13 30.759-24.71 45.302-28.628h-.001z"
          stroke="url(#nikki-swirl_svg__paint4_linear_284_1387)"
        />
        <path
          d="M127.214 33.355c17.534-4.702 32.236-.04 42.555 16.308 11.846 18.764 18.996 42.538 21.832 67.727 2.829 25.177 1.129 51.627-3.326 75.715-3.251 17.587-9.628 34.709-18.411 49.176-14.842 24.449-35.326 40.966-59.523 40.966-37.38 0-62.893-4.696-79.053-18.71-16.138-13.992-22.94-37.284-22.94-74.514 0-18.808 7.477-40.95 19.355-62.624 5.658-10.324 12.316-20.541 19.64-30.24 8.09-10.714 16.993-20.798 26.262-29.7 17.582-16.893 36.474-29.525 53.607-34.105l.002.001z"
          stroke="url(#nikki-swirl_svg__paint5_linear_284_1387)"
        />
        <path
          d="M118.359 291.357c-43.446 0-72.785-5.058-91.264-20.944C8.617 254.528 1 227.816 1 184.505c0-21.655 8.689-47.283 22.465-72.412 13.777-25.129 32.641-49.758 52.992-69.416C96.81 23.02 118.646 8.335 138.37 3.094c19.723-5.24 37.331-1.037 49.224 17.085 14.191 21.624 22.39 49.383 25.348 78.861 2.959 29.478.676 60.671-6.095 89.162-13.542 56.983-45.041 103.155-88.488 103.155h.001z"
          stroke="url(#nikki-swirl_svg__paint6_linear_284_1387)"
        />
      </g>
      <defs>
        <linearGradient
          id="nikki-swirl_svg__paint0_linear_284_1387"
          x1={38.815}
          y1={213.712}
          x2={93.133}
          y2={213.712}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="nikki-swirl_svg__paint1_linear_284_1387"
          x1={36.639}
          y1={194.774}
          x2={113.992}
          y2={210.294}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="nikki-swirl_svg__paint2_linear_284_1387"
          x1={40.101}
          y1={173.405}
          x2={133.415}
          y2={220.219}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="nikki-swirl_svg__paint3_linear_284_1387"
          x1={45.837}
          y1={141.264}
          x2={143.508}
          y2={239.242}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="nikki-swirl_svg__paint4_linear_284_1387"
          x1={54.191}
          y1={97.658}
          x2={139.253}
          y2={268.28}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="nikki-swirl_svg__paint5_linear_284_1387"
          x1={76.104}
          y1={43.502}
          x2={123.64}
          y2={281.931}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="nikki-swirl_svg__paint6_linear_284_1387"
          x1={107.607}
          y1={0.902}
          x2={107.607}
          y2={291.455}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
      </defs>
    </svg>
  );
}
export default SvgNikkiSwirl;
