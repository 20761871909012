import React, { useContext } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { LocalContext } from 'context';

const Button = ({
  initial = "initial",
  animate="animate",
  transition,
  children,
  colors,
  disabled,
  onClick,
  style,
  type = 'button',
  whileFocus,
  whileHover,
  whileTap,
  width,
  variants
}) => {
  const { theme } = useContext(LocalContext);
  return (
    <StyledButton
      $colors={colors}
      $theme={theme}
      animate={animate}
      disabled={disabled}
      transition={transition}
      exit="exit"
      initial={initial}
      onClick={onClick}
      style={style}
      type={type}
      variants={variants}
      whileFocus={whileFocus}
      whileHover={whileHover}
      whileTap={whileTap}
      width={width}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled(motion.button)`
  align-items: center;
  background-color: ${({ $theme, $colors }) =>
    $theme.className === 'contrast' ? 'yellow' : $colors ? $colors.tertiary : $theme.fg};
  border-radius: 3rem;
  color: ${({ $theme }) => ($theme.className === 'contrast' ? '#000' : 'white')};
  cursor: pointer;
  display: flex;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  height: 3rem;
  justify-content: center;
  padding: 1rem;
  text-transform: uppercase;
  width: ${(props) => (props.width ? props.width : '100%')};
  ${({ style }) => style}

  :disabled {
    cursor: default;
  }
`;

export default Button;
