import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Button,
  CreateOrEditPollModal,
  QueuedAndClosedPolls,
  AnalyticsModal,
  ConfirmDeleteModal
} from 'components';
import { FirebaseContext, LocalContext } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import { hexToRGB } from 'utils';
import { Plus } from 'assets/svgs';
import { fadeInAndOutVariants } from 'styles';

const blockedUsers = [
  'fouad.akkad@pfizer.com',
  'maximilian.newton@iqvia.com',
  'kevin.fitzpatrick@pfizer.com'
];

const ControlPanel = ({ colors }) => {
  const { selectedEvent } = useContext(LocalContext);
  const { firebase, user } = useContext(FirebaseContext);
  const { eid, did } = selectedEvent;
  const [editPoll, setEditPoll] = useState(null);
  const [isCreateOrEditPollModalOpen, setIsCreateOrEditPollModalOpen] = useState(false);
  const [queuedPolls, setPendingPolls] = useState([]);
  const [deletePoll, setDeletePoll] = useState(null);
  const [closedPolls, setClosedPolls] = useState([]);
  const [allPolls, setAllPolls] = useState([]);
  const [selectedPoll, setSelectedPoll] = useState(null);
  const [currentlyOpenPoll, setCurrentlyOpenPoll] = useState(null);
  const [isAnalyticsModalOpen, setIsAnalyticsModalOpen] = useState(false);
  const [
    pollAnalyticsBeingCurrentlyViewedByModerator,
    setPollAnalyticsBeingCurrentlyViewedByModerator
  ] = useState(null);
  const [
    pollAnalyticsBeingCurrentlySharedWithUsers,
    setPollAnalyticsBeingCurrentlySharedWithUsers
  ] = useState(null);

  useEffect(() => {
    let unsubscribeFromPolls;

    if (firebase) {
      unsubscribeFromPolls = firebase.subscribeToPolls({
        did,
        eid,
        onSnapshot: (snapshot) => {
          if (snapshot.exists()) {
            const _queuedPolls = [];
            const _closedPolls = [];
            const _allPolls = [];

            snapshot.forEach((child) => {
              _allPolls.push({
                pid: child.key,
                ...child.val()
              });
            });

            const _currentlyOpenPoll = _allPolls.find((poll) => poll.isOpen);
            const _pollAnalyticsBeingCurrentlySharedWithUsers = _allPolls.find(
              (poll) => poll.shareAnalytics
            );

            if (_currentlyOpenPoll) {
              setCurrentlyOpenPoll(_currentlyOpenPoll);
            }

            if (_pollAnalyticsBeingCurrentlySharedWithUsers) {
              setPollAnalyticsBeingCurrentlySharedWithUsers(
                _pollAnalyticsBeingCurrentlySharedWithUsers
              );
            } else {
              setPollAnalyticsBeingCurrentlySharedWithUsers(null);
            }

            _allPolls.forEach((poll) => {
              if (poll.isQueued) {
                _queuedPolls.push(poll);
              } else {
                _closedPolls.push(poll);
              }
            });

            setPendingPolls(_queuedPolls);
            setClosedPolls(_closedPolls);
            setAllPolls(_allPolls);
          } else if (!snapshot.exists()) {
            setPendingPolls([]);
            setClosedPolls([]);
            setAllPolls([]);
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromPolls) {
        unsubscribeFromPolls.off();
      }
      setEditPoll(null);
      setIsCreateOrEditPollModalOpen(false);
      setPendingPolls([]);
      setClosedPolls([]);
      setAllPolls([]);
      setSelectedPoll(null);
      setCurrentlyOpenPoll(null);
      setIsAnalyticsModalOpen(false);
      setPollAnalyticsBeingCurrentlyViewedByModerator(null);
      setPollAnalyticsBeingCurrentlySharedWithUsers(null);
    };
  }, [firebase, did, eid]);

  useEffect(() => {
    if (allPolls.length) {
      allPolls.forEach((poll) => {
        if (selectedPoll?.pid === poll.pid) {
          // Whenever the 'subscribeToPolls' snapshot is fired we loop through all the polls and
          // rehydrate the currently selected poll (if there is one) with any new properties
          // e.g. if it's been changed from a queued to a closed poll.
          setSelectedPoll(poll);
        }
      });
    }
  }, [allPolls]);

  const handlePoll = (action) => {
    if (blockedUsers.includes(user?.email)) {
      return;
    }
    switch (action) {
      case 'openPoll':
        firebase.openPoll({ did, eid, selectedPoll, currentlyOpenPoll });
        firebase.forceActiveTab({ did, eid, tabName: 'Polls' });
        break;
      case 'closePoll':
        firebase.closePoll({ did, eid, pid: selectedPoll.pid });
        firebase.forceActiveTab({ did, eid, tabName: null });
        setCurrentlyOpenPoll(null);
        break;
      case 'sharePollAnalytics':
        firebase.sharePollAnalytics({ did, eid, pid: selectedPoll.pid });
        firebase.forceActiveTab({ did, eid, tabName: 'Polls' });
        break;
      case 'stopSharingPollAnalytics':
        firebase.stopSharingPollAnalytics({ did, eid, pid: selectedPoll.pid });
        firebase.forceActiveTab({ did, eid, tabName: null });
        break;
      default:
        break;
    }
  };

  const handleDeletePoll = (e) => {
    if (blockedUsers.includes(user?.email)) {
      return;
    }
    e.stopPropagation();
    if (firebase) {
      if (selectedPoll?.pid === deletePoll?.pid) {
        setSelectedPoll(null);
      }
      if (currentlyOpenPoll?.pid === deletePoll?.pid) {
        firebase.forceActiveTab({ eid: selectedEvent.eid, did: selectedEvent.did, tabName: null });
        setCurrentlyOpenPoll(null);
      }
      firebase
        .deletePoll({ eid: selectedEvent.eid, did: selectedEvent.did, pid: deletePoll.pid })
        .then(() => {
          setDeletePoll(null);
        });
    }
  };
  return (
    <>
      <p>Moderator&apos;s Control Panel</p>
      <CreatePollButton
        onClick={() => {
          if (blockedUsers.includes(user?.email)) {
            return;
          }
          setIsCreateOrEditPollModalOpen(true);
        }}
        colors={colors}
        whileHover={{
          backgroundColor: colors.tertiary
        }}
        whileTap={{
          scale: 0.95
        }}>
        <Plus width="10px" />
        &nbsp;&nbsp;Create Poll
      </CreatePollButton>
      <QueuedAndClosedPolls
        did={did}
        eid={eid}
        colors={colors}
        queuedPolls={queuedPolls}
        closedPolls={closedPolls}
        selectedPoll={selectedPoll}
        setSelectedPoll={setSelectedPoll}
        currentlyOpenPoll={currentlyOpenPoll}
        setCurrentlyOpenPoll={setCurrentlyOpenPoll}
        setIsCreateOrEditPollModalOpen={setIsCreateOrEditPollModalOpen}
        setEditPoll={setEditPoll}
        setDeletePoll={setDeletePoll}
        setPollAnalyticsBeingCurrentlyViewedByModerator={
          setPollAnalyticsBeingCurrentlyViewedByModerator
        }
        setIsAnalyticsModalOpen={setIsAnalyticsModalOpen}
      />
      <AnimatePresence>
        {(queuedPolls.length || closedPolls.length) && (
          <Buttons colors={colors}>
            <AnimatePresence>
              {queuedPolls.length && (!selectedPoll || (selectedPoll && selectedPoll.isQueued)) && (
                <Button
                  onClick={() =>
                    selectedPoll?.pid === currentlyOpenPoll?.pid
                      ? handlePoll('closePoll')
                      : handlePoll('openPoll')
                  }
                  style={{
                    color:
                      selectedPoll &&
                      (selectedPoll.pid === currentlyOpenPoll?.pid ||
                        selectedPoll.pid !== currentlyOpenPoll?.pid)
                        ? '#fff'
                        : '#c4c4c4',
                    backgroundColor:
                      selectedPoll && selectedPoll.pid === currentlyOpenPoll?.pid
                        ? colors.tertiary
                        : selectedPoll && selectedPoll.pid !== currentlyOpenPoll?.pid
                        ? colors.primary
                        : '#e5e5e5',
                    gridColumn: '1/2'
                  }}
                  whileHover={{
                    boxShadow:
                      selectedPoll &&
                      (!currentlyOpenPoll || selectedPoll.pid === currentlyOpenPoll.pid) &&
                      !pollAnalyticsBeingCurrentlySharedWithUsers &&
                      `0 0 0.5rem 0.025rem ${hexToRGB({
                        color:
                          selectedPoll.pid === currentlyOpenPoll?.pid
                            ? colors.tertiary
                            : colors.primary,
                        alpha: 0.25
                      })}`
                  }}
                  whileTap={{
                    scale:
                      selectedPoll &&
                      (!currentlyOpenPoll || selectedPoll.pid === currentlyOpenPoll.pid) &&
                      !pollAnalyticsBeingCurrentlySharedWithUsers &&
                      0.95
                  }}
                  disabled={
                    user.email !== 'conor.d@agencyx.ie' ||
                    !selectedPoll ||
                    (selectedPoll &&
                      ((currentlyOpenPoll && selectedPoll.pid !== currentlyOpenPoll.pid) ||
                        pollAnalyticsBeingCurrentlySharedWithUsers))
                  }
                  variants={fadeInAndOutVariants()}
                  initial="initial"
                  animate="animate"
                  exit="exit">
                  {selectedPoll && selectedPoll.pid === currentlyOpenPoll?.pid
                    ? 'Close Poll'
                    : 'Open Poll'}
                </Button>
              )}
            </AnimatePresence>
            <Button
              onClick={() =>
                selectedPoll?.pid === pollAnalyticsBeingCurrentlySharedWithUsers?.pid
                  ? handlePoll('stopSharingPollAnalytics')
                  : handlePoll('sharePollAnalytics')
              }
              style={{
                color: selectedPoll && !selectedPoll.isQueued ? '#fff' : '#c4c4c4',
                backgroundColor:
                  selectedPoll && !selectedPoll.isQueued ? colors.tertiary : '#e5e5e5',
                gridColumn: '2'
              }}
              whileHover={{
                boxShadow:
                  selectedPoll &&
                  !selectedPoll.isQueued &&
                  `0 0 0.5rem 0.025rem ${hexToRGB({
                    color: colors.tertiary,
                    alpha: 0.25
                  })}`
              }}
              whileTap={{
                scale: selectedPoll && !selectedPoll.isQueued && 0.95
              }}
              disabled={!selectedPoll || selectedPoll.isQueued}>
              {selectedPoll?.shareAnalytics ? 'Stop Sharing' : 'Share Analytics'}
            </Button>
          </Buttons>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isCreateOrEditPollModalOpen && (
          <CreateOrEditPollModal
            colors={colors}
            setIsCreateOrEditPollModalOpen={setIsCreateOrEditPollModalOpen}
            editPoll={editPoll}
            setEditPoll={setEditPoll}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isAnalyticsModalOpen && (
          <AnalyticsModal
            poll={pollAnalyticsBeingCurrentlyViewedByModerator}
            setIsAnalyticsModalOpen={setIsAnalyticsModalOpen}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {deletePoll && (
          <ConfirmDeleteModal
            handleDelete={handleDeletePoll}
            handleCancel={() => setDeletePoll(null)}
            type="poll"
          />
        )}
      </AnimatePresence>
    </>
  );
};

const CreatePollButton = styled(motion.div)`
  align-items: center;
  background-color: ${({ colors }) => colors.primary};
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  font-weight: 700;
  height: 3rem;
  justify-content: center;
  margin: 0 auto;
  text-transform: uppercase;
  width: 190px;
`;

const Buttons = styled.section`
  align-items: center;
  border-top: 0.063rem solid #bdbdbd;
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(2, minmax(auto, 190px));
  height: 4.05rem;
  justify-content: space-between;
  padding: 0.75rem 0;

  @media screen and (min-width: 600px) {
    justify-content: end;
  }

  @media screen and (min-width: 1150px) {
    justify-content: space-between;
  }

  button {
    border-radius: 0.125rem;
    height: 2.5rem;
    max-width: 11.875rem;
  }
`;

export default ControlPanel;
