import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Markup } from 'interweave';
import { useEventData } from 'hooks';

export default ({ colors }) => {
  const { edges: events } = useEventData();
  const futureEvents = events
    .filter(({ node }) => node.frontmatter.type === 'future' && !node.frontmatter.hidden)
    .sort((a, b) => b.node.frontmatter.eid - a.node.frontmatter.eid);

  return (
    <FutureEvents>
      {futureEvents.map((event) => {
        const eventImage = getImage(event.node.frontmatter.thumb);
        return (
          <Event key={event.node.frontmatter.eid}>
            <GatsbyImage image={eventImage} alt={event.node.frontmatter.name} />
            <Details colors={colors}>
              <h4>
                <Markup content={event.node.frontmatter.dates} />
              </h4>
              <h3>{event.node.frontmatter.name}</h3>
              <p>{event.node.frontmatter.description}</p>
              <Link to={event.node.frontmatter.slug}>View Event</Link>
            </Details>
          </Event>
        );
      })}
    </FutureEvents>
  );
};

const Details = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  h3 {
    font-size: 2.5rem;
    font-weight: 400;
    color: #3c3c3c;
    margin: 0;
    margin-bottom: 0.5em;
  }
  h4 {
    font-size: 1.25rem;
    font-weight: 600 !important;
    margin-bottom: 1em !important;
    color: ${({ colors }) => colors.secondary} !important;
    text-align: left !important;
    width: 100% !important;
  }
  p {
    font-size: 1rem;
    margin-bottom: 1em !important;
    color: #3c3c3c !important;
    text-align: left !important;
    width: 100% !important;
  }
  a {
    background-color: ${({ colors }) => colors.tertiary};
    border-radius: 3rem;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5em;
    padding: 0.75rem 1.5rem;
    text-transform: uppercase;
    transition: 0.3s;
    &:hover {
      background-color: ${({ colors }) => colors.secondary};
    }
  }
`;

const Event = styled.div`
  background: white;
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr;
  padding: 1.25rem;
  img {
    max-width: 100%;
    object-position: top left !important;
  }
  @media (min-width: 768px) {
    grid-template-columns: 20rem 1fr;
  }
`;

const FutureEvents = styled.div`
  background: white;
  height: auto;
  width: 100%;
`;
