import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button, EventButton as AddToCalendarButton } from 'components';
import { Markup } from 'interweave';
import { LocalContext } from 'context';
import { useWindowSize } from 'hooks';
import { scrollToAnchor } from 'utils';
import { Days, HomepageIntroGraphic } from '../../assets/animated-svgs/intro-graphics';

const getSVG = ({ dayAndEventId, color }) => {
  switch (dayAndEventId) {
    case '1_1_2023':
      return <Days color={color} />;
    case '2_1_2023':
      return <Days color={color} />;
    case '3_1_2023':
      return <Days color={color} />;
    default:
      return <HomepageIntroGraphic />;
  }
};

const Intro = ({ text, colors, did, eid, padding }) => {
  const { windowWidth } = useWindowSize();
  const { selectedEvent } = useContext(LocalContext);

  return (
    <Wrapper id="info" eid={eid} padding={padding}>
      <Text colors={colors} eid={eid}>
        <Markup content={text} noWrap />
        <ButtonWrapper>
          <Button
            onClick={() => {
              scrollToAnchor('agenda', {
                duration: 1200,
                offset: eid ? -180 : -90
              });
            }}
            whileHover={{ scale: 1.025 }}
            whileTap={{ scale: 0.975 }}>
            View Agenda
          </Button>
          {selectedEvent && (
            <AddToCalendarButton
              colors={selectedEvent.colors}
              eventTitle={selectedEvent.title}
              description={selectedEvent.description}
              startTime={selectedEvent.startTime}
              endTime={selectedEvent.endTime}
            />
          )}
        </ButtonWrapper>
      </Text>
      {windowWidth >= 1000 && (
        <IntroGraphic eid={eid}>
          {getSVG({ dayAndEventId: `${did}_${eid}`, color: colors.primary })}
        </IntroGraphic>
      )}
    </Wrapper>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 440px) {
    flex-direction: row;
  }
`;

const Wrapper = styled.section`
  align-items: center;
  display: grid;
  grid-column-gap: 0.875rem;
  grid-template-columns: repeat(12, 1fr);
  justify-items: center;
  margin: 0 auto;
  max-width: 1400px;
  padding: 4rem 0;

  @media screen and (min-width: 1000px) {
    padding: ${({ padding }) => padding || '5.875rem 0 7.813rem'};
  }
`;

const IntroGraphic = styled.div`
  /* eid will be undefined on the Homepage */
  grid-column: 7/13;
  width: 100%;

  @media screen and (min-width: 1440px) {
    grid-column: 7/13;
    justify-self: start;
    top: 1.85rem;
  }
`;

const Text = styled.div`
  grid-column: 2/12;
  max-width: 400px;
  width: 100%;

  li {
    margin-left: 1.25em;
  }

  h2 {
    color: #3c3c3c;
    font-weight: 400;
    font-size: 2rem;
    line-height: 1.286em;
    margin-bottom: 0.25em;
  }

  p,
  ul {
    font-size: 1rem;
    line-height: 1.6em;
    margin-bottom: 2em;
  }

  button {
    background-color: ${({ eid, colors }) => (eid ? colors.primary : '#0095FF')};
    height: 3rem;
    width: 10.875rem;
  }

  @media screen and (min-width: 550px) {
    max-width: 582px;

    h2 {
      font-size: 2.3rem;
    }
  }

  @media screen and (min-width: 1000px) {
    grid-column: 2/7;

    h2 {
      font-size: 2.625rem;
      margin-bottom: 0.143em;
    }

    p {
      margin-right: 1em;
    }
  }
`;

export default Intro;
