import * as React from 'react';

function SvgStar(props) {
  return (
    <svg viewBox="0 0 99 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 78.263C40.71 63.632 49.321 65.976 77 99.22c-4.237-7.55-7.623-13.58-10.147-18.076L77 99.22C62.908 58.055 65.29 49.303 98.302 20.958c-24.58 14.33-24.58 14.33 0 0C57.592 35.59 48.981 33.245 21.302 0l10.147 18.076L21.302 0C35.394 41.166 33.012 49.918 0 78.263c24.58-14.33 24.58-14.33 0 0z"
        fill="url(#Star_svg__paint0_linear_25_7700)"
      />
      <defs>
        <linearGradient
          id="Star_svg__paint0_linear_25_7700"
          x1={98.304}
          y1={20.954}
          x2={-0.908}
          y2={76.647}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#891AFC" />
          <stop offset={0.36} stopColor="#2B46D1" />
          <stop offset={0.64} stopColor="#29ABE2" />
          <stop offset={0.97} stopColor="#FCEE21" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgStar;
