import React from 'react';
import styled, { keyframes } from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { breakpoints, gridContainer } from 'styles';
import { motion } from 'framer-motion';
import { useWindowSize } from 'hooks';
import { useInView } from 'react-intersection-observer';
import Star from 'assets/animated-svgs/intro-graphics/SharedGraphics/Star';
import {
  BgMoon1,
  BgMoon2,
  FbIcon,
  EmailIcon,
  LinkedinIcon,
  TwitterIcon,
  IgIcon,
  WebsiteIcon,
  WholeStar,
  BlurryBlob,
  NikkiSwirl
} from 'assets/svgs';
import { Markup } from 'interweave';

export default ({ motivationalSpeaker, data, colors }) => {
  const speakerImage = getImage(data.image);
  const { windowWidth } = useWindowSize();
  const [ref, inView] = useInView({ triggerOnce: true });

  const speakerHasSocialLinks =
    data.websiteLink ||
    data.fbLink ||
    data.linkedinLink ||
    data.twLink ||
    data.igLink ||
    data.emailLink;

  return (
    <Slide colors={colors} ref={ref}>
      {motivationalSpeaker && windowWidth < 1150 && <GuestSpeaker>Guest Speaker</GuestSpeaker>}
      {inView && (
        <SpeakerImgContainer>
          <LinearGradientBall />
          {/* <StyledWholeStar /> */}
          <BlueBall>
            <StyledBlurryBlob />
            <StyledStar />
          </BlueBall>
          <StyledNikkiSwirl />
          <SpeakerImg image={speakerImage} cropped />
        </SpeakerImgContainer>
      )}
      <SpeakerDetails>
        {motivationalSpeaker && windowWidth >= 1150 && <GuestSpeaker>Guest Speaker</GuestSpeaker>}
        <SpeakerName>{data.name}</SpeakerName>
        {data.position && (
          <SpeakerTitle>
            <Markup content={data.position} />
          </SpeakerTitle>
        )}
        {speakerHasSocialLinks && (
          <SpeakerSocials>
            {data.websiteLink && (
              <SpeakerSocial href={data.websiteLink} target="_blank" colors={colors}>
                <WebsiteIcon />
              </SpeakerSocial>
            )}
            {data.linkedinLink && (
              <SpeakerSocial href={data.linkedinLink} target="_blank" colors={colors}>
                <LinkedinIcon />
              </SpeakerSocial>
            )}
            {data.twLink && (
              <SpeakerSocial href={data.twLink} target="_blank" colors={colors}>
                <TwitterIcon />
              </SpeakerSocial>
            )}
            {data.fbLink && (
              <SpeakerSocial href={data.fbLink} target="_blank" colors={colors}>
                <FbIcon />
              </SpeakerSocial>
            )}
            {data.igLink && (
              <SpeakerSocial href={data.igLink} target="_blank" colors={colors}>
                <IgIcon />
              </SpeakerSocial>
            )}
            {data.emailLink && (
              <SpeakerSocial href={data.emailLink} target="_blank" colors={colors}>
                <EmailIcon />
              </SpeakerSocial>
            )}
          </SpeakerSocials>
        )}
        <SpeakerSummary colors={colors}>
          <Markup content={data.summary} />
        </SpeakerSummary>
      </SpeakerDetails>
    </Slide>
  );
};

const StyledNikkiSwirl = styled(NikkiSwirl)`
  animation: fadeIn 1s 1.5s ease-in-out forwards;
  opacity: 0;
  position: absolute;
  right: -10%;
  top: -33%;
  width: 85.2%;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const StyledStar = styled(Star)`
  animation: rotate 2s ease-in-out alternate-reverse infinite;
  left: -11.6%;
  position: absolute;
  top: -19.6%;
  width: 48%;
  z-index: 1;

  @keyframes rotate {
    0% {
      transform: rotate3d(0, 0, 1, 0deg);
    }
    100% {
      transform: rotate3d(0, 0, 1, 90deg);
    }
  }
`;

const StyledBlurryBlob = styled(BlurryBlob)`
  animation: glow 1.5s ease-in-out alternate-reverse infinite;
  bottom: -12%;
  filter: brightness(100%);
  opacity: 0;
  position: absolute;
  right: -4.8%;
  width: 48%;

  @keyframes glow {
    0% {
      filter: brightness(100%);
      opacity: 0.8;
    }

    100% {
      filter: brightness(130%);
      opacity: 1;
    }
  }
`;

const wholeStarAnimation = keyframes`
  0% {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 180deg);
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: rotate3d(0, 0, 1, 360deg);
  }
`;

// const StyledWholeStar = styled(WholeStar)`
//   animation: ${wholeStarAnimation} 4s 1.5s ease-in-out forwards;
//   opacity: 0;
//   position: absolute;
//   transform: rotate3d(0, 0, 1, 180deg);
//   width: 160%;
// `;

const BlueBall = styled(motion.div).attrs({
  initial: {
    opacity: 0,
    scale: 0
  },
  animate: {
    opacity: 1,
    scale: 1
  },
  transition: {
    duration: 1
  }
})`
  align-self: center;
  background-color: #1000c9;
  border-radius: 50%;
  height: 105%;
  justify-self: center;
  position: absolute;
  width: 105%;
  opacity: 0;
`;

const LinearGradientBall = styled(motion.div).attrs({
  initial: {
    opacity: 0,
    rotate: 360
  },
  animate: {
    opacity: 1,
    rotate: 0
  },
  transition: {
    rotate: {
      duration: 4,
      delay: 1
    },
    opacity: {
      duration: 1,
      delay: 1
    }
  }
})`
  align-self: center;
  background: linear-gradient(#0095ff 0%, #3c09db 17.19%, #891afc 47.4%, #c7d601 97.4%);
  border-radius: 50%;
  height: 116%;
  justify-self: center;
  position: absolute;
  width: 116%;
  opacity: 0;
  z-index: -1;
`;

const GuestSpeaker = styled.h2`
  text-align: center !important;

  @media (min-width: ${breakpoints.lg}) {
    text-align: left !important;
  }
`;

const SpeakerSocials = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0.625rem 0 0.25rem;
`;

const SpeakerSocial = styled(motion.a).attrs({
  whileHover: {
    scale: 1.075
  },
  whileTap: {
    scale: 0.925
  }
})`
  margin-right: 0.625rem;
  svg {
    width: 1.5rem;
    circle {
      fill: ${({ colors }) => colors.primary};
    }
  }
`;

const SpeakerSummary = styled.p`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  margin-top: 1.15rem !important;
  max-width: 400px !important;
  text-align: center !important;

  @media (min-width: 1150px) {
    text-align: justify !important;
    max-width: 100% !important;
  }
`;

const SpeakerName = styled.h3`
  font-size: 2rem !important;
  font-style: normal;
  font-weight: 600;
  margin: 0 auto;
  text-align: center !important;

  @media (min-width: 1150px) {
    text-align: left !important;
    margin-left: 0;
  }
`;

const SpeakerTitle = styled.p`
  color: #3c3c3c !important;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4rem !important;
  margin: 1rem 0 0.375rem !important;
  text-align: center !important;

  @media (min-width: 1150px) {
    margin: 0.25rem 0 0.375rem !important;
    text-align: left !important;
  }
`;

const SpeakerDetails = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${breakpoints.lg}) {
    align-items: flex-start;
    max-width: 710px;
    width: 100%;
  }
`;

const SpeakerImgContainer = styled(motion.div)`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 4.5rem auto 5.5rem;
  position: relative !important;

  @media (min-width: ${breakpoints.lg}) {
    margin-right: 11rem;
  }
`;

const SpeakerImg = styled(GatsbyImage)`
  animation: fadeInSpeakerImg 0.5s 0.8s ease-in-out forwards;
  border-radius: 50%;
  opacity: 0;
  width: 250px;

  @keyframes fadeInSpeakerImg {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Slide = styled.div`
  /* padding: 1.25rem; */
  align-items: center;
  color: #3c3c3c;
  display: flex;
  flex-direction: column;
  max-width: 1164px;
  position: relative;
  width: 100%;

  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
  }
`;
