import React from 'react';
import styled from 'styled-components';

const HeroVideo = () => (
  <Wrapper
    loop
    muted
    autoPlay
    playsInline
    controls={false}
    id="bgvid"
    style={{ pointerEvents: 'none' }}>
    <source src="/videos/homepage-hero-video.mp4" type="video/mp4" />
  </Wrapper>
);

const Wrapper = styled.video`
  /* filter: brightness(0.6); */
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;

  video::-webkit-media-controls-panel {
    display: none !important;
  }
`;

export default HeroVideo;
