import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { hexToRGB } from 'utils';
import { useWindowSize } from 'hooks';

function TimeZoneSelector({ selectedTimeZone, setSelectedTimeZone, cityNames, colors, style }) {
  const [showTimeZonesOnMobile, setShowTimeZonesOnMobile] = useState(false);
  const { windowWidth } = useWindowSize();

  const convertCityNameToTimeZone = (cityName) => {
    const date = format(new Date(), 'yyyy-MM-dd HH:mm z');
    switch (cityName) {
      case 'New York':
        return 'EST';
      case 'São Paulo':
        return 'ART';
      case 'Dubai':
        return 'GST';
      case 'Hong Kong':
        return 'HKT';
      default:
        return date?.slice(date?.lastIndexOf(' ') + 1);
    }
  };

  const handleCityNameClick = (e) =>
    setSelectedTimeZone(convertCityNameToTimeZone(e.target.innerText));

  useEffect(() => {
    const date = format(new Date(), 'yyyy-MM-dd HH:mm z');
    const timeZoneLetters = date?.slice(date.lastIndexOf(' ') + 1);
    switch (timeZoneLetters) {
      case 'GMT':
        setSelectedTimeZone('GMT');
        break;
      case 'GMT+0':
        setSelectedTimeZone('GMT');
        break;
      case 'EST':
        setSelectedTimeZone('EST');
        break;
      case 'EST+0':
        setSelectedTimeZone('EST');
        break;
      case 'ART':
        setSelectedTimeZone('ART');
        break;
      case 'ART+0':
        setSelectedTimeZone('ART');
        break;
      case 'GST':
        setSelectedTimeZone('GST');
        break;
      case 'GST+0':
        setSelectedTimeZone('GST');
        break;
      case 'HKT':
        setSelectedTimeZone('HKT');
        break;
      case 'HKT+0':
        setSelectedTimeZone('HKT');
        break;
      default:
        setSelectedTimeZone('EST');
    }
  }, []);

  const toggleShowTimeZonesOnMobile = (value) => {
    setShowTimeZonesOnMobile(value);
  };

  return (
    <Container colors={colors} style={style}>
      <AnimatePresence>
        {(showTimeZonesOnMobile || windowWidth >= 767) && (
          <CityNames onMouseLeave={() => windowWidth < 767 && toggleShowTimeZonesOnMobile(false)}>
            {cityNames.map((cityName) => (
              <CityName
                onClick={handleCityNameClick}
                selected={convertCityNameToTimeZone(cityName) === selectedTimeZone}
                key={cityName}
                custom={colors}>
                {cityName}
              </CityName>
            ))}
          </CityNames>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {(!showTimeZonesOnMobile || windowWidth >= 767) && (
          <ChooseTimeZone
            onMouseEnter={() => windowWidth < 767 && toggleShowTimeZonesOnMobile(true)}
            colors={colors}>
            Choose{windowWidth < 767 ? <br /> : ''} Time Zone
          </ChooseTimeZone>
        )}
      </AnimatePresence>
    </Container>
  );
}

const ChooseTimeZone = styled(motion.div).attrs({
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.1
    }
  }
})`
  align-items: center;
  background-color: #fff;
  border: 1px solid ${({ colors }) => colors?.primary};
  border-radius: 30px;
  color: ${({ colors }) => colors?.primary};
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  height: 100%;
  justify-content: center;
  left: 0;
  line-height: 1.25em;
  position: absolute;
  text-align: center;
  top: 0;
  transition: border 300ms ease-in-out, background-color 300ms ease-in-out;
  width: 120px;

  @media screen and (min-width: 767px) {
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    width: 220px;
  }
`;

const Container = styled(motion.div)`
  background-color: ${({ colors }) => hexToRGB({ color: colors.primary, alpha: 0.2 })};
  border-radius: 30px;
  grid-column: 1 / 2;
  grid-row: 2;
  height: 48px;
  margin: 0.375rem 0 0.875rem;
  overflow: hidden;
  position: relative;
  transition: width 300ms ease-in-out;
  width: 120px;
  ${({ style }) => style}

  @media screen and (min-width: 767px) {
    padding-right: 29px;
    width: 220px;
  }

  &:hover {
    width: 326px;

    @media (min-width: 767px) {
      width: 624px;
      grid-column: 1 / 3;
    }

    ${ChooseTimeZone} {
      background-color: ${({ colors }) => colors?.primary};
      color: #fff;
    }
  }
`;

const CityNames = styled(motion.ul).attrs({
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1,
    transition: {
      delay: 0.24
    }
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.1
    }
  }
})`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  list-style-type: none;

  @media screen and (min-width: 767px) {
    justify-content: flex-end;
  }
`;

const CityName = styled(motion.li).attrs({
  whileHover: (colors) => ({
    backgroundColor: colors.primary,
    color: '#fff'
    // mixBlendMode: 'multiply'
  })
})`
  align-items: center;
  color: #3c3c3c;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 0.8rem;
  text-align: center;
  text-decoration: ${({ selected }) => (selected ? 'underline' : 'none')};
  height: 100%;
  padding: 0 0.75em;
  white-space: nowrap;
  position: relative;

  @media screen and (min-width: 767px) {
    font-size: 1rem;
    justify-content: flex-end;
    left: 0.75em;
  }
`;

export default TimeZoneSelector;
