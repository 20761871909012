import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { FirebaseContext } from 'context';
import { motion } from 'framer-motion';
import { Participant } from 'components';
import throttle from 'lodash.throttle';

const Participants = ({ colors, did, eid, dbEventTitle, handleParticipantAvatarOrNameClick }) => {
  const { firebase } = useContext(FirebaseContext);
  const [lastFetchedParticipantDoc, setLastFetchedParticipantDoc] = useState();
  const [eventParticipants, setEventParticpants] = useState([]);

  const particpantsContainerRef = useRef();

  const populateEventParticipantsList = (result) => {
    if (!result.empty) {
      setEventParticpants((currrentValue) => [
        ...currrentValue,
        ...result.docs
          .filter(
            (doc) =>
              !doc.data().email.includes('agencyx') &&
              !doc.data().email.includes('getbroadcasting') &&
              !doc.data().email.includes('dynamicevents')
          )
          .map((doc) => {
            const {
              name,
              avatarUrl,
              region,
              function: _function,
              eventRole,
              uid,
              profession,
              socials
            } = doc.data();
            return {
              name,
              avatarUrl,
              region,
              function: _function,
              eventRole,
              uid,
              profession,
              socials
            };
          })
      ]);
      setLastFetchedParticipantDoc(result.docs[result.docs.length - 1]);
    }
  };

  useEffect(() => {
    firebase.fetchAllParticipants().then(populateEventParticipantsList);
    return () => {
      setEventParticpants([]);
      setLastFetchedParticipantDoc(null);
    };
  }, [did, eid]);

  const fetchPaginatedParticipants = () =>
    firebase.fetchAllParticipants(lastFetchedParticipantDoc).then(populateEventParticipantsList);

  return (
    <Wrapper ref={particpantsContainerRef} colors={colors}>
      {eventParticipants.length > 0 &&
        eventParticipants.map((participant, i, arr) => (
          <Participant
            participant={participant}
            colors={colors}
            handleParticipantAvatarOrNameClick={handleParticipantAvatarOrNameClick}
            key={`${participant.uid}-${did}-${eid}`}
            fetchPaginatedParticipants={fetchPaginatedParticipants}
            isLastFetchedParticipant={arr.length === i + 1}
            particpantsContainerRef={particpantsContainerRef}
          />
        ))}
    </Wrapper>
  );
};

const Wrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  background-color: #fff;
  padding: 1.25rem;
  min-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.primary};
    border-radius: 0.625rem;
  }
`;

export default Participants;
