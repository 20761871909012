import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { noPadding } from 'styles';
import { useWindowSize } from 'hooks';
import { LocalContext } from 'context';
import { Markup } from 'interweave';

export default ({ eventAgendas, colors }) => {
  const { agendaRef } = useContext(LocalContext);
  const { windowWidth } = useWindowSize();
  const [selected, setSelected] = useState(eventAgendas[0]);
  const { slots } = selected;
  return (
    <Agendas>
      <div ref={agendaRef} style={{ position: 'absolute', top: '-10rem' }} />
      <Container>
        <H3 colors={colors}>Agendas</H3>
        <Days>
          {eventAgendas.map((eventAgenda) => (
            <Selector
              key={eventAgenda.day}
              colors={colors}
              active={selected.day === eventAgenda.day}
              onClick={() => {
                setSelected(eventAgenda);
              }}>
              Day {eventAgenda.day}
            </Selector>
          ))}
        </Days>

        <Agenda>
          <Title colors={colors}>{selected.title}</Title>
          {windowWidth >= 768 && (
            <Grid colors={colors} className="inactive">
              <Time>Time:</Time>
              <Name style={{ fontSize: '1rem', fontWeight: 'normal' }}>Livestream</Name>

              {/* <Speaker style={{ fontSize: '1rem', fontWeight: 'normal' }}>Keynote Speaker</Speaker> */}
            </Grid>
          )}

          {slots.map((slot) => (
            <Grid colors={colors} key={slot.orderNumber}>
              <Time>
                {windowWidth < 768 && <span>Time:</span>}
                {slot.timeSlot}
              </Time>
              <Name>
                <Markup content={slot.title} />
              </Name>
              {slot.description && (
                <Description>
                  <Markup content={slot.description} />
                </Description>
              )}

              {/* <Speaker>{slot.speaker}</Speaker> */}
            </Grid>
          ))}
        </Agenda>
      </Container>
    </Agendas>
  );
};

const H3 = styled.h3`
  color: #00004e;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  grid-column: 1/7;
  line-height: 1.75rem;
  margin-bottom: 1.5rem;

  @media (min-width: 768px) {
    grid-column: 2/6;
  }

  @media (min-width: 1150px) {
    grid-column: 2/12;
  }

  @media (min-width: 1600px) {
    grid-column: 3/11;
  }
`;

const Description = styled.p`
  font-size: 1.25rem;
  font-weight: 400;
  grid-column: 1/7;
  line-height: 2rem;
  strong,
  ul {
    font-weight: 600;
  }
  ul {
    margin-left: 2rem;
  }
  @media (min-width: 1150px) {
    grid-column: 5/11;
  }
`;

const Name = styled.p`
  font-size: 1.25rem;
  font-weight: 400;
  grid-column: 1/7;
  line-height: 2rem;

  strong,
  ul {
    font-weight: 600;
  }

  ul {
    margin-left: 2rem;
  }

  @media (min-width: 1150px) {
    grid-column: 2/5;
  }
`;
// const Speaker = styled.p`
//   grid-column: 1/7;
//   font-weight: 400;
//   font-size: 1.25rem;
//   line-height: 2rem;
//   @media (min-width: 1150px) {
//     grid-column: 9/11;
//   }
// `;

const Time = styled.p`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  grid-column: 1/2;
  line-height: 2rem;

  span {
    margin-right: 0.375rem;
  }

  @media (min-width: 1150px) {
    grid-column: 1/2;
  }
`;

const Grid = styled.div`
  ${noPadding};
  color: #00004e;
  display: grid;
  grid-column: 1/7;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(6, 1fr);
  margin-bottom: 1.75rem;
  padding: 0.5rem;
  transition: 0.25s;
  &:hover {
    background: ${({ className }) =>
      className === 'inactive' ? 'initial' : 'rgba(62, 146, 203, 0.1)'};
    color: ${({ className }) => (className === 'inactive' ? 'initial' : '#8f2b7d')};
  }
  @media (min-width: 1150px) {
    margin-bottom: 0.75rem;
    grid-template-columns: repeat(10, 1fr);
    grid-column: 2/12;
    grid-gap: 2rem;
  }
  @media (min-width: 1600px) {
    grid-column: 3/11;
  }
`;

const Title = styled.h3`
  color: #00004e;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.3em;
  margin-bottom: 2rem;
`;

const Agenda = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
`;

const Selector = styled.button`
  background: ${({ active, colors }) => (active ? colors.primary : 'transparent')};
  border: 1px solid;
  border-color: ${({ active, colors }) => (active ? colors.primary : colors.tertiary)};
  border-radius: 2rem;
  color: ${({ active, colors }) => (active ? 'white' : colors.tertiary)};
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  height: 3rem;
  text-align: center;
  transition: 0.25s;
`;

const Days = styled.div`
  display: grid;
  grid-gap: 1.25rem;
  grid-template-columns: 7.5rem 7.5rem;
  margin: 2rem 0;
  width: auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  height: auto;
  justify-content: flex-start;
  width: 100%;

  @media (min-width: 1150px) {
    grid-column: 2/12;
  }
`;

const Agendas = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  margin: 3rem auto;
  max-width: 1440px;
  padding: 1rem;
  position: relative;
  width: 100%;
  @media (min-width: 1150px) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 1.25rem;
    margin: 5rem auto;
  }
`;
