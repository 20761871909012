import React from 'react';
import styled from 'styled-components';
import { Button } from 'components';

const BreakoutSessions = () => (
  <Container>
    <h2>Regional Breakout Sessions</h2>
    <BreakoutSession>
      <p>
        Regional Breakout Session
        <br />
        for EM Asia
      </p>
      <a
        href="https://us06web.zoom.us/j/84688986709?pwd=ZUt5RnZic3B4dXNrMkZPT3BMNVF5Zz09"
        target="_blank"
        rel="noreferrer">
        <Button
          whileTap={{ scale: 0.9625 }}
          whileHover={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)' }}>
          View Breakout Session
        </Button>
      </a>
    </BreakoutSession>
    <BreakoutSession>
      <p>
        Regional Breakout Session
        <br />
        for AfME
      </p>
      <a
        href="https://zoom.us/j/97944943947?pwd=MDdDTEVwV0k5UWdOejcyYWFMQmJZdz09"
        target="_blank"
        rel="noreferrer">
        <Button
          whileTap={{ scale: 0.9625 }}
          whileHover={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)' }}>
          View Breakout Session
        </Button>
      </a>
    </BreakoutSession>
    <BreakoutSession>
      <p>
        Regional Breakout Session
        <br />
        for LatAm
      </p>
      <a
        href="https://us02web.zoom.us/j/83427764731?pwd=ajJGMkxXRHdxblpXUnVraUtvV0padz09"
        target="_blank"
        rel="noreferrer">
        <Button
          whileTap={{ scale: 0.9625 }}
          whileHover={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)' }}>
          View Breakout Session
        </Button>
      </a>
    </BreakoutSession>
  </Container>
);

const Container = styled.section`
  display: grid;
  grid-gap: 1rem 0;
  justify-items: center;
  margin: 4.75rem auto 2rem;
  max-width: 23.125rem;
  padding: 0 1rem;

  h2 {
    color: #3c3c3c;
    font-size: 1.5rem;
    margin-bottom: 1.625rem;
    text-align: center;
  }

  @media screen and (min-width: 60rem) {
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(3, 1fr);
    max-width: 65.063rem;
    margin: 4.75rem auto 2rem;
    grid-gap: 1rem 1.313rem;
    padding: unset;

    h2 {
      grid-area: 1 / 1 / 2 / 4;
    }
  }
`;

const BreakoutSession = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1.875rem 0;
  width: 100%;

  p {
    color: #3c3c3c;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1.125rem;
    text-transform: capitalize;
  }

  button {
    color: #fff;
    font-size: 0.75rem;
    white-space: nowrap;
    width: 210px;
  }

  &:nth-of-type(1) {
    background-color: rgba(189, 0, 206, 0.4);

    button {
      background-color: #bd00ce;
    }
  }

  &:nth-of-type(2) {
    background-color: rgba(255, 73, 55, 0.4);

    button {
      background-color: #ff4937;
    }
  }

  &:nth-of-type(3) {
    background-color: rgba(248, 152, 29, 0.4);

    button {
      background-color: #c7d601;
    }
  }

  @media screen and (min-width: 60rem) {
    grid-row: 2 / 3;
  }
`;

export default BreakoutSessions;
