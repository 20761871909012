import * as React from 'react';

function SvgEmojiLike(props) {
  return (
    <svg viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M69.417 37.132c0 20.745-13.998 32.404-34.705 32.404C14.004 69.536.006 57.877.006 37.132.006 15.6 13.24 0 34.712 0c21.47 0 34.705 15.6 34.705 37.132z"
        fill="#0B91FC"
      />
      <path
        d="M48.848 46.52c1.018-.487 2.568-1.206 2.985-2.388.416-1.159-.301-2.34-.486-3.476 1.11-.302 2.383-1.067 2.591-2.736.232-1.808-1.087-2.619-2.452-3.407.3-.255.509-.463.74-.626 1.11-.834 1.69-1.924 1.18-3.291-.486-1.298-1.573-1.9-2.984-1.854-3.888.092-7.798 0-11.685 0 .694-4.474 2.013-9.18-1.689-12.818-1.11-1.066-3.146-1.785-3.47.116-.394 2.48 1.11 5.447-.024 7.904-1.18 2.596-2.59 4.496-4.025 6.953-.718 1.229-1.69 2.318-2.476 3.5a2.4 2.4 0 00-.416 1.298c-.024 4.219-.024 8.414 0 12.633 0 .394.115.927.393 1.136 1.342 1.02 2.753 1.854 4.604 1.83 4.512-.069 9 .024 13.512 0 2.777.024 5.715-1.599 3.702-4.774zM20.042 52.36a4.556 4.556 0 01-4.558-4.566V37.319a4.556 4.556 0 014.558-4.566 4.556 4.556 0 014.558 4.566v10.477a4.556 4.556 0 01-4.558 4.566z"
        fill="#fff"
      />
    </svg>
  );
}
export default SvgEmojiLike;
