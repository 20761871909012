import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { LoadingSpinner, Timer } from 'components';
import { FirebaseContext, LocalContext } from 'context';
import { hexToRGB } from 'utils';
import { Star } from 'assets/animated-svgs';
import { fadeInAndOutVariants } from 'styles';

const capitaliseFirstLetterOfText = (text) => text.slice(0, 1).toUpperCase() + text.slice(1);

const Stars = ({
  selectedEvent,
  userHasAlreadyAnsweredThisPoll,
  answerId,
  setRatings,
  usersSubmittedAnswersFromAlreadyAnsweredPoll
}) => {
  const arr = [];
  const [rating, setRating] = useState(0);

  const handleRating = (i) => {
    if (!userHasAlreadyAnsweredThisPoll) {
      setRating(rating === i ? 0 : i);
      setRatings((currentRatings) => ({
        ...currentRatings,
        [answerId]: rating === i ? 0 : i
      }));
    }
  };

  const userSelectedThisStar = (i) =>
    rating >= i || usersSubmittedAnswersFromAlreadyAnsweredPoll[answerId] >= i;

  for (let i = 1; i <= 5; i++) {
    arr.push(
      <AnimatedStar
        key={i}
        height="24px"
        width="24px"
        fill={userSelectedThisStar(i) ? selectedEvent?.colors.primary : 'none'}
        stroke={userSelectedThisStar(i) ? selectedEvent?.colors.primary : '#C4C4C4'}
        onClick={() => handleRating(i)}
      />
    );
  }

  return arr;
};

const AnswerPollModal = ({ poll, serverTimeOffset }) => {
  const { selectedEvent } = useContext(LocalContext);
  const { did, eid } = selectedEvent;
  const { pid } = poll;
  const { firebase, user } = useContext(FirebaseContext);
  const [pollStatus, setPollStatus] = useState('');
  // const [selectedAnswerIds, setSelectedAnswerIds] = useState([]);
  const [pollTimerHasExpired, setPollTimerHasExpired] = useState(false);
  const [userHasAlreadyAnsweredThisPoll, setUserHasAlreadyAnsweredThisPoll] = useState(false);
  const [
    usersSubmittedAnswersFromAlreadyAnsweredPoll,
    setUsersSubmittedAnswersFromAlreadyAnsweredPoll
  ] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [ratings, setRatings] = useState({});
  const [submitButtonIsDisabled, setSubmitButtonIsDisabled] = useState(false);
  const [answerButtonsAreDisabled, setAnswerButtonsAreDisabled] = useState(false);

  // const pollHasCorrectAnswers = poll.answers.some((answer) => answer.isCorrect);

  useEffect(() => {
    if (
      userHasAlreadyAnsweredThisPoll ||
      (poll.timer.enabled && pollTimerHasExpired) ||
      pollStatus === 'submitted'
    ) {
      setAnswerButtonsAreDisabled(true);
    } else {
      setAnswerButtonsAreDisabled(false);
    }
  }, [userHasAlreadyAnsweredThisPoll, poll?.timer?.enabled, pollTimerHasExpired, pollStatus]);

  useEffect(() => {
    if (answerButtonsAreDisabled || Object.values(ratings)?.every((value) => value === 0)) {
      setSubmitButtonIsDisabled(true);
    } else {
      setSubmitButtonIsDisabled(false);
    }
  }, [answerButtonsAreDisabled, ratings]);

  useEffect(() => {
    if (firebase && user) {
      firebase
        .checkIfUserHasAlreadyAnsweredThisPoll({
          uid: user.uid,
          did,
          eid,
          pid
        })
        .then((doc) => {
          if (doc.exists) {
            setUserHasAlreadyAnsweredThisPoll(true);
            const { answers } = doc.data();
            const entries = Object.entries(answers);
            const _usersSubmittedAnswersFromAlreadyAnsweredPoll = entries.reduce(
              (acc, [key, value]) => {
                acc[key] = value;
                return acc;
              },
              {}
            );
            setUsersSubmittedAnswersFromAlreadyAnsweredPoll(
              _usersSubmittedAnswersFromAlreadyAnsweredPoll
            );
          }
        });
    }
  }, [firebase, user, did, eid, pid, pollStatus]);

  // const handleAnswerSelection = (e) => {
  //   const answerId = e.target.id;

  //   /* TODO: can I remove the optional chaining here? */
  //   if (poll?.multipleAnswers.allowed) {
  //     if (selectedAnswerIds.includes(answerId)) {
  //       setSelectedAnswerIds((currentlySelectedAnswerIds) =>
  //         currentlySelectedAnswerIds.filter(
  //           (currentlySelectedAnswerId) => currentlySelectedAnswerId !== answerId
  //         )
  //       );
  //     } else if (selectedAnswerIds.length < poll.multipleAnswers.max) {
  //       setSelectedAnswerIds((currentlySelectedAnswerIds) => [
  //         ...currentlySelectedAnswerIds,
  //         answerId
  //       ]);
  //     }
  //   } else if (selectedAnswerIds.includes(answerId)) {
  //     setSelectedAnswerIds([]);
  //   } else {
  //     setSelectedAnswerIds([e.target.id]);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (pollStatus !== 'submitted') {
      try {
        setPollStatus('submitting');

        await firebase.submitPollAnswer({
          did,
          eid,
          poll,
          uid: user.uid,
          ratings,
          region: user.region,
          function: user.function
        });

        setPollStatus('submitted');
      } catch (error) {
        console.error(error);
        setPollStatus('error');
      }
    }
  };

  return (
    <Underlay colors={selectedEvent.colors} data-underlay>
      <Modal colors={selectedEvent.colors} pollTimerEnabled={poll.timer.enabled}>
        {poll.timer.enabled && serverTimeOffset && (
          <Timer
            colors={selectedEvent.colors}
            serverTimeOffset={serverTimeOffset}
            setPollTimerHasExpired={setPollTimerHasExpired}
            timerEndsAt={poll.timer.startedAt + poll.timer.seconds * 1000}
          />
        )}
        <Question colors={selectedEvent.colors}>
          <p>{capitaliseFirstLetterOfText(poll.question.text)}</p>
          {poll.multipleAnswers?.allowed && (
            <p>
              <em>(multiple choice)</em>
            </p>
          )}
        </Question>
        <form onSubmit={(e) => handleSubmit(e)}>
          {poll.answers.map((answer, i) => (
            <Answer
              colors={selectedEvent.colors}
              disabled={answerButtonsAreDisabled}
              // isCorrect={answer.isCorrect}
              // isSelected={
              //   selectedAnswerIds.includes(answer.id) ||
              //   (!pollHasCorrectAnswers &&
              //     usersSubmittedAnswersFromAlreadyAnsweredPoll.includes(answer.id))
              // }
              key={answer.id}
              // maxAnswersSelected={selectedAnswerIds.length === poll.multipleAnswers.max}
              // onClick={handleAnswerSelection}
              // pollHasCorrectAnswers={pollHasCorrectAnswers}
              pollStatus={pollStatus}
              // showBorder={
              //   pollHasCorrectAnswers &&
              //   usersSubmittedAnswersFromAlreadyAnsweredPoll.includes(answer.id)
              // }
              type="button"
              // whileTap={
              //   !answerButtonIsDisabled &&
              //   (selectedAnswerIds.includes(answer.id) ||
              //     selectedAnswerIds.length + 1 <= poll.multipleAnswers.max) && {
              //     scale: 0.95
              //   }
              // }
              userHasAlreadyAnsweredThisPoll={userHasAlreadyAnsweredThisPoll}>
              {/* <Checkbox
                colors={selectedEvent.colors}
                isCorrect={answer.isCorrect}
                isSelected={
                  selectedAnswerIds.includes(answer.id) ||
                  (!pollHasCorrectAnswers &&
                    usersSubmittedAnswersFromAlreadyAnsweredPoll.includes(answer.id))
                }
                pollHasCorrectAnswers={pollHasCorrectAnswers}
                pollStatus={pollStatus}
                userHasAlreadyAnsweredThisPoll={userHasAlreadyAnsweredThisPoll}>
                <div />
              </Checkbox> */}
              <p>{capitaliseFirstLetterOfText(answer.text)}</p>
              <Icons
                errorMessage={errorMessage}
                userHasAlreadyAnsweredThisPoll={userHasAlreadyAnsweredThisPoll}>
                <Stars
                  selectedEvent={selectedEvent}
                  setRatings={setRatings}
                  answerId={answer.id}
                  userHasAlreadyAnsweredThisPoll={userHasAlreadyAnsweredThisPoll}
                  usersSubmittedAnswersFromAlreadyAnsweredPoll={
                    usersSubmittedAnswersFromAlreadyAnsweredPoll
                  }
                />
              </Icons>
            </Answer>
          ))}
          <SubmitButton
            colors={selectedEvent.colors}
            custom={submitButtonIsDisabled}
            disabled={submitButtonIsDisabled}
            pollStatus={pollStatus}
            submitButtonIsDisabled={submitButtonIsDisabled}
            type="submit">
            {pollStatus === 'submitting' ? (
              <LoadingSpinner style={{ width: '1.625rem', color: '#fff' }} />
            ) : pollStatus === 'submitted' || userHasAlreadyAnsweredThisPoll ? (
              'Submitted'
            ) : (
              'Submit'
            )}
          </SubmitButton>
        </form>
      </Modal>
    </Underlay>
  );
};

const Icons = styled.div`
  border: ${({ errorMessage }) => (errorMessage === 'missing-rating' ? '1px solid red' : 'none')};
  display: flex;
  justify-content: space-between;

  > * {
    cursor: pointer;
    pointer-events: ${({ userHasAlreadyAnsweredThisPoll }) =>
      userHasAlreadyAnsweredThisPoll ? 'none' : 'auto'};
  }

  > svg {
    box-sizing: content-box;
    flex-shrink: 0;
    padding: 0.25rem;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`;

const Underlay = styled(motion.div).attrs(fadeInAndOutVariants())`
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 1.625rem 1.625rem;
  position: absolute;
  top: 0;
  width: 100%;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.primary};
    border-radius: 0.625rem;
  }
`;

const Modal = styled.div`
  background-color: #fff;
  margin: ${({ pollTimerEnabled }) => (pollTimerEnabled ? '6rem auto 0' : '1rem auto 0')};
  max-width: 23.75rem;
  position: relative;

  form {
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.875rem;
  }
`;

const Question = styled.div`
  background-color: ${({ colors }) => hexToRGB({ color: colors.primary, alpha: 0.2 })};
  color: ${({ colors }) => colors.primary};
  padding: 0.75rem 1.5rem;
  width: 100%;

  p:nth-of-type(1) {
    font-weight: 700;
  }

  p:nth-of-type(2) {
    font-weight: 300;
  }
`;

const Answer = styled(motion.button)`
  align-items: flex-start;
  background-color: ${({
    isSelected,
    colors,
    pollStatus,
    pollHasCorrectAnswers,
    isCorrect,
    userHasAlreadyAnsweredThisPoll
  }) =>
    pollHasCorrectAnswers &&
    ((pollStatus === 'submitted' && isSelected && isCorrect) ||
      (userHasAlreadyAnsweredThisPoll && isCorrect))
      ? 'rgba(51, 204, 102, 0.3)'
      : pollHasCorrectAnswers &&
        ((pollStatus === 'submitted' && isSelected && !isCorrect) ||
          (userHasAlreadyAnsweredThisPoll && !isCorrect))
      ? 'rgba(245, 81, 81, 0.4)'
      : isSelected
      ? hexToRGB({ color: colors.primary, alpha: 0.2 })
      : 'rgba(196, 196, 196, 0.2)'};
  border: ${({ colors, showBorder }) => (showBorder ? `2px solid ${colors.primary}` : 'none')};
  border-radius: 0.125em;
  color: ${({
    isSelected,
    colors,
    pollStatus,
    pollHasCorrectAnswers,
    isCorrect,
    userHasAlreadyAnsweredThisPoll
  }) =>
    pollHasCorrectAnswers &&
    ((pollStatus === 'submitted' && isSelected && isCorrect) ||
      (userHasAlreadyAnsweredThisPoll && isCorrect))
      ? '#33cc66'
      : pollHasCorrectAnswers &&
        ((pollStatus === 'submitted' && isSelected && !isCorrect) ||
          (userHasAlreadyAnsweredThisPoll && !isCorrect))
      ? '#f55151'
      : isSelected
      ? colors.primary
      : '#000'};
  /* cursor: ${({ disabled, maxAnswersSelected, isSelected }) =>
    disabled || (maxAnswersSelected && !isSelected) ? 'default' : 'pointer'}; */

  display: flex;
  flex-direction: column;
  font-size: 1rem;
  margin-bottom: 0.375em;
  padding: 0.5em;
  transition: background-color 150ms ease-in-out, color 150ms ease-in-out;

  div,
  p {
    pointer-events: none;
  }

  p {
    position: relative;
    text-align: left;
    top: 0.063em;
  }
`;

const AnimatedStar = styled(Star)`
  flex-grow: 1;
  transition: stroke 3s ease-in-out;
`;

// const Checkbox = styled.div`
//   align-items: center;
//   border-color: ${({
//     isSelected,
//     colors,
//     pollStatus,
//     pollHasCorrectAnswers,
//     isCorrect,
//     userHasAlreadyAnsweredThisPoll
//   }) =>
//     pollHasCorrectAnswers &&
//     ((pollStatus === 'submitted' && isSelected && isCorrect) ||
//       (userHasAlreadyAnsweredThisPoll && isCorrect))
//       ? '#33cc66'
//       : pollHasCorrectAnswers &&
//         ((pollStatus === 'submitted' && isSelected && !isCorrect) ||
//           (userHasAlreadyAnsweredThisPoll && !isCorrect))
//       ? '#f55151'
//       : isSelected
//       ? colors.primary
//       : '#c4c4c4'};
//   border-radius: 0.125rem;
//   border-style: solid;
//   border-width: 0.063rem;
//   display: flex;
//   height: 1rem;
//   justify-content: center;
//   margin-right: 0.625rem;
//   padding: 0.25rem;
//   transition: border-color 150ms ease-in-out;
//   width: 1rem;

//   > div {
//     background-color: ${({
//       isSelected,
//       colors,
//       pollStatus,
//       pollHasCorrectAnswers,
//       isCorrect,
//       userHasAlreadyAnsweredThisPoll
//     }) =>
//       pollHasCorrectAnswers &&
//       ((pollStatus === 'submitted' && isSelected && isCorrect) ||
//         (userHasAlreadyAnsweredThisPoll && isCorrect))
//         ? '#33cc66'
//         : pollHasCorrectAnswers &&
//           ((pollStatus === 'submitted' && isSelected && !isCorrect) ||
//             (userHasAlreadyAnsweredThisPoll && !isCorrect))
//         ? '#f55151'
//         : isSelected
//         ? colors.primary
//         : 'transparent'};
//     border-radius: 0.125rem;
//     flex-shrink: 0;
//     height: 0.5rem;
//     transition: background-color 150ms ease-in-out;
//     width: 0.5rem;
//   }
// `;

const SubmitButton = styled(motion.button).attrs({
  whileTap: (submitButtonIsDisabled) =>
    !submitButtonIsDisabled && {
      scale: 0.95
    }
})`
  align-items: center;
  align-self: flex-end;
  background-color: ${({ submitButtonIsDisabled, pollStatus, colors }) =>
    submitButtonIsDisabled && pollStatus === 'submitted'
      ? colors.tertiary
      : submitButtonIsDisabled && pollStatus !== 'submitted'
      ? 'rgba(196, 196, 196, 0.3)'
      : colors.primary};
  border-radius: 0.125em;
  color: ${({ submitButtonIsDisabled, pollStatus }) =>
    submitButtonIsDisabled && pollStatus !== 'submitted' ? '#c4c4c4' : '#fff'};
  cursor: ${({ submitButtonIsDisabled, pollStatus }) =>
    pollStatus === 'submitting'
      ? 'none'
      : submitButtonIsDisabled && pollStatus === 'submitted'
      ? 'default'
      : submitButtonIsDisabled && pollStatus !== 'submitted'
      ? 'not-allowed'
      : 'pointer'};
  display: flex;
  font-weight: 700;
  justify-content: center;
  text-transform: uppercase;
  font-size: 1rem;
  height: 2.5em;
  margin-top: 1.25em;
  width: 11.875em;
`;

export default AnswerPollModal;
