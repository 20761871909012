import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
// import { breakpoints, gridContainer } from 'styles';
import { motion, AnimatePresence } from 'framer-motion';
import { Button, GameScreenshot } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import { Plus, AppleStoreLogo, GooglePlayLogo, QrCode1, QrCode2 } from 'assets/svgs';
// import { LeftArrow, RightArrow, FbIcon, TwitterIcon, LinkedinIcon, EmailIcon } from 'assets/svgs';
import Interweave from 'interweave';

export default () => {
  const { gamePopupBanner, gameScreenshot } = useStaticQuery(graphql`
    query {
      gamePopupBanner: file(relativePath: { eq: "assets/images/game-popup-banner.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1164, height: 420)
        }
      }
      gameScreenshot: file(relativePath: { eq: "assets/images/game-screenshot.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 687, height: 388)
        }
      }
    }
  `);
  return (
    <Backdrop>
      <Container>
        <BannerImg image={getImage(gamePopupBanner)} alt="banner image" />
        <Content>
          <h2>Feel the #JOY!</h2>
          <h2 style={{ color: '#0095FF' }}>How do I get started with the game?</h2>
          <br />
          <p>
            DOWNLOAD THE APP Mobile Adventures directly from App Store and Google Play or scan the
            QR Code!
          </p>
          <br />
          <CodeAndLogosWrapper>
            <StyledQrCode1 />
            <Logos>
              <a
                href="https://apps.apple.com/us/app/mobile-adventures/id824257097"
                target="_blank"
                rel="noreferrer">
                <AppleStoreLogo />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=uk.co.huntthegoose.app&hl=en_GB&gl=US"
                target="_blank"
                rel="noreferrer">
                <GooglePlayLogo />
              </a>
            </Logos>
          </CodeAndLogosWrapper>
          <br />
          <ol>
            After downloading the app:
            <li>
              &nbsp;Open the app, allow all permissions and Scan the Game QR Code or enter game code
              manually.
              <br />
              <br />
              <div style={{ marginLeft: '15.25%' }}>
                <StyledQrCode2 />
                <h3
                  style={{
                    fontWeight: 400,
                    fontSize: '2rem',
                    position: 'relative',
                    left: '-2rem'
                  }}>
                  Game Code: <strong>148332700976</strong>
                </h3>
              </div>
              <br />
              <br />
            </li>
            <li>&nbsp;Choose your REGION from the list.</li>
            <li>
              &nbsp;Take a selfie (if you don&apos;t like selfies, take a selfie of a mascot!)
            </li>
            <li>&nbsp;Type your own name</li>
            <li>&nbsp;Join the game</li>
          </ol>
          <br />
          <br />
          <p>
            <strong>FAQ&apos;s</strong>
            <br />
            <br />
            <strong>
              I&apos;ve logged out of the game, how do I rejoin from where I left off?
            </strong>
            <br />
            If you find yourself logged out of the game you can rejoin to the point where you left
            off. To do this follows these steps:
            <ol>
              <li>Open App and re-enter your game code: 148332700976</li>
              <li>
                Do Not Choose your team, wait 30 seconds until a cog appears. Tap cog and then
                &quot;enter rejoin pin&quot;.
              </li>
              <li>
                Each player has a unique rejoin pin. If you do not know your rejoin pin please ask
                us via live chat support.
              </li>
            </ol>
            <br />
            <strong>How does the Scoring System work?</strong>
            <br />
            Photo & Video challenges are then reviewed by our judge and the score is awarded.
            Maximum points are awarded for extraordinary effort with JOY, creativity and humour.
            <br />
            <br />
            Check out the photo wall in app for inspiration from your colleagues.
            <br />
            <br />
            <strong>Types of Tasks</strong>
            <br />
            There will be photo and video challenges to complete once the Joy Session begins
            <br />
            <br />
            <strong>Support </strong>
            <br />
            If you have any questions, feel free to use{' '}
            <a
              style={{ color: '#3c3c3c', textDecoration: 'underline' }}
              href="https://go.crisp.chat/chat/embed/?website_id=00b69e53-4c51-42e2-a625-f876a3efec48"
              target="_blank"
              rel="noreferrer">
              live chat support
            </a>{' '}
            or email&nbsp;
            <a
              style={{ color: '#3c3c3c', textDecoration: 'underline' }}
              href="mailto:support@dynamicevents.ie"
              target="_blank"
              rel="noreferrer">
              support@dynamicevents.ie
            </a>{' '}
            our customer support team.
            <br />
            <br />
            <ul>
              <strong>The Submissions:</strong>
              <li>
                You will enter your submission by taking a photo or recording a video via the Mobile
                App we have provided you with.
              </li>
              <li>Please note, all submissions must be live in the moment photos.</li>
              <li>Each submission equals points for your REGION.</li>
            </ul>
            <br />
            <br />
            <small>
              <strong>Disclaimer: </strong>Your photo entries will be visible to other Pfizer
              employees within the Mobile App, so make them fun! Selected photos may also be shared
              (internally only) via internal channels or email with other Pfizer employees to drive
              engagement and competitiveness.
            </small>
          </p>
        </Content>
      </Container>
    </Backdrop>
  );
};

const CodeAndLogosWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 500px) {
    flex-direction: row;
    align-items: center;
  }
`;

const StyledQrCode1 = styled(QrCode1)`
  max-width: 410px;
  width: 100%;
`;

const StyledQrCode2 = styled(QrCode2)`
  max-width: 408px;
  width: 100%;
`;

const Logos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 500px) {
    margin-left: 20px;
    position: relative;
    top: 15px;
  }

  svg {
    height: 3rem;
    width: 10.125rem;

    &:first-of-type {
      margin-bottom: 10px;
    }
  }
`;

const Content = styled.div`
  > * {
    max-width: 926px;
  }
`;

const BannerImg = styled(GatsbyImage)`
  height: 15.625rem;
  width: 100%;
  @media (min-width: 48rem) {
    height: 26.25rem;
  }
`;

const Container = styled(motion.div)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
  padding-top: 1rem;
  width: 1200px;

  p,
  ol,
  ul,
  li,
  a {
    color: #3c3c3c;
    font-size: 1.25rem;
    line-height: 32px;
  }

  ol,
  ul {
    list-style-position: inside;
    line-height: 32px;
  }

  h2 {
    font-size: 2rem;
  }

  > div {
    background: #ededed;
    padding: 1rem 1.5rem;
    width: 100%;
  }

  @media (min-width: 48rem) {
    > div {
      padding: 3rem 5rem 1.5rem;
    }
  }

  @media (min-width: 71.875rem) {
    max-width: 100%;
    padding-top: 0;
  }
`;

const Backdrop = styled.div`
  height: auto;
  width: auto;
  @media (max-width: 71.875rem) {
    overflow-x: hidden;
  }
`;
