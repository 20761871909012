import * as React from 'react';

function SvgSmallStar(props) {
  return (
    <svg viewBox="0 0 233 234" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 234c103.471-71.573 129.529-71.573 233 0l-42.449-42.631L233 234c-71.267-103.915-71.267-130.084 0-234-58.261 58.511-58.261 58.511 0 0C129.529 71.573 103.471 71.573 0 0c17.728 17.804 31.892 32.03 42.449 42.63C31.892 32.03 17.772 17.85 0 0c71.267 103.916 71.267 130.085 0 234 58.261-58.511 58.261-58.511 0 0z"
        fill="url(#small-star_svg__paint0_linear_2_1229)"
      />
      <defs>
        <linearGradient
          id="small-star_svg__paint0_linear_2_1229"
          x1={233.001}
          y1={-0.013}
          x2={-1.005}
          y2={232.993}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#891AFC" />
          <stop offset={0.36} stopColor="#2B46D1" />
          <stop offset={0.64} stopColor="#29ABE2" />
          <stop offset={0.97} stopColor="#FCEE21" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgSmallStar;
