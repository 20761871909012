import * as React from 'react';

function SvgWebsiteIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={12} cy={12} r={12} fill="#0E41D5" />
      <path d="M12 18a6 6 0 100-12 6 6 0 000 12z" stroke="#fff" strokeMiterlimit={10} />
      <path
        d="M12 18c1.82 0 3.294-2.686 3.294-6S13.819 6 12 6s-3.293 2.686-3.293 6S10.18 18 12 18zM12 6v12M18 12H6"
        stroke="#fff"
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgWebsiteIcon;
