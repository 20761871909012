import React from 'react';
import styled, { keyframes } from 'styled-components';
import { SmallStar, BigStar } from 'assets/animated-svgs';

const HomepageBannerLoggedInGraphic = () => (
  <Wrapper>
    <StyledSmallStar />
    <StyledBigStar />
  </Wrapper>
);

const wrapperAnimation = keyframes`
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 360deg);
  }
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 467px;
  justify-content: center;
  left: 2.5rem;
  margin-top: 2.05rem;
  position: relative;
  width: 467px;

  svg {
    opacity: 0;
    position: absolute;
  }
`;

const smallStarAnimation = keyframes`
  0% {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 0deg);
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: rotate3d(0, 0, 1, 360deg);
  }
`;

const bigStarAnimation = keyframes`
  0% {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 180deg);
  }

  10% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: rotate3d(0, 0, 1, 360deg);
  }
`;

const StyledSmallStar = styled(SmallStar)`
  animation: ${smallStarAnimation} 4s ease-in-out forwards,
    ${wrapperAnimation} 18s 4s ease-in-out infinite;
  width: 50%;
`;
const StyledBigStar = styled(BigStar)`
  animation: ${bigStarAnimation} 4s ease-in-out forwards,
    ${wrapperAnimation} 18s 4s ease-in-out infinite;
  transform: rotate3d(0, 0, 1, 180deg);
`;

export default HomepageBannerLoggedInGraphic;
