import React, { useContext, useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Interaction, LivestreamOverlays, HeroVideo } from 'components';
import { BackgroundOverlay } from 'components/Events/EventHeader';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FirebaseContext, LocalContext } from 'context';
import { useWindowSize } from 'hooks';
import { AnimatePresence, motion } from 'framer-motion';
import { Markup } from 'interweave';
import throttle from 'lodash.throttle';
import { hexToRGB } from 'utils';
import { fullGridContainer, breakpoints } from 'styles';
import Vimeo from '@vimeo/player';
import { useInView } from 'react-intersection-observer';
import { wrap } from 'popmotion';

function generateRandomThrottleInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export default (props) => {
  const {
    colors,
    did,
    eid,
    dbEventTitle,
    dateAndTimeText,
    description,
    endTime,
    eventName,
    startTime,
    eventTitle,
    eventSubtitle,
    footNote,
    gameLink,
    zoomLink,
    events,
    setSelectedEvent,
    endScreenImg,
    isQAndAEnabled,
    isPollsEnabled,
    isParticipantsEnabled,
    gameScreenImg,
    setShowJoySessionBanner
  } = props;

  const { windowWidth } = useWindowSize();
  const { headerRef } = useContext(LocalContext);
  const { user, firebase, loading } = useContext(FirebaseContext);
  const [isPipModeEnabled, setIsPipModeEnabled] = useState(false);
  const [vimeoPlayer, setVimeoPlayer] = useState(null);
  const [livestreamIsPlaying, setLivestreamIsPlaying] = useState(false);
  const [questionCurrentlyBeingAnsweredLive, setQuestionCurrentlyBeingAnsweredLive] =
    useState(null);
  const [pipRef, vimeoPlayerIsInView] = useInView({
    initialInView: true,
    threshold: 0.75
  });
  const [forcedActiveTab, setForcedActiveTab] = useState(null);
  const [showPleaseChooseFranchiseScreen, setShowPleaseChooseFranchiseScreen] = useState(false);
  const [showGameScreen, setShowGameScreen] = useState(false);
  const [showEndScreen, setShowEndScreen] = useState(false);
  const [streamUrl, setStreamUrl] = useState(null);

  const livestreamRef = useRef(null);

  useEffect(() => {
    let unsubscribeFromEventUpdates;

    if (firebase) {
      unsubscribeFromEventUpdates = firebase.subscribeToEventUpdates({
        did,
        eid,
        onSnapshot: (snapshot) => {
          if (snapshot.exists) {
            const {
              forcedActiveTab: _forcedActiveTab,
              questionCurrentlyBeingAnsweredLive: _questionCurrentlyBeingAnsweredLive,
              showEndScreen: _showEndScreen,
              showGameScreen: _showGameScreen,
              streamUrl: _streamUrl,
              showJoySessionBanner: _showJoySessionBanner
            } = snapshot.data();

            setStreamUrl(_streamUrl);

            setShowEndScreen(_showEndScreen);

            setShowGameScreen(_showGameScreen);

            setShowJoySessionBanner(_showJoySessionBanner);

            if (_forcedActiveTab || _forcedActiveTab === null) {
              setForcedActiveTab(_forcedActiveTab);
            }

            if (_questionCurrentlyBeingAnsweredLive) {
              setQuestionCurrentlyBeingAnsweredLive(_questionCurrentlyBeingAnsweredLive);
            } else {
              setQuestionCurrentlyBeingAnsweredLive(null);
            }
          } else if (!snapshot.exists) {
            /* CONOR TODO: Is there a better way of doing this? Can we automatically add the event doc somehow? Hmmmm */
            console.error(
              "You need to add a doc for this event in the 'Events' collection in Firebase."
            );
          }
        }
      });
      return () => {
        if (unsubscribeFromEventUpdates) {
          unsubscribeFromEventUpdates();
        }
        setIsPipModeEnabled(false);
        setVimeoPlayer(null);
        setLivestreamIsPlaying(false);
        setQuestionCurrentlyBeingAnsweredLive(null);
        setForcedActiveTab(null);
        setShowPleaseChooseFranchiseScreen(false);
        setShowEndScreen(false);
        setShowJoySessionBanner(false);
        // setStreamUrl(null);
      };
    }
  }, [firebase, did, eid]);

  useEffect(() => {
    const livestreamIframe = document.getElementById('livestream-iframe');
    let updateVideoSessionDataInterval;

    if (livestreamIframe && user && did && eid && !vimeoPlayer) {
      const _vimeoPlayer = new Vimeo(livestreamIframe);

      setVimeoPlayer(_vimeoPlayer);

      // I'm defining a video "session" as a discrete period of time someone is on the page watching
      // a video. If someone watches an hour one day, then navigates away and comes back to the same
      // page the next day, then those are two seperate sessions. Likewise, if they watch 20 mins
      // then navigate to another part of the site and then come back to the same page again and
      // watches another 20 mins, then those are two seperate sessions. But if someone watches 20
      // mins and pauses the video, then unpauses and continues watching, then that's a single
      // session. Likewise, if someone watches 20 mins and then fast-forwards an hour and continues
      // watching, then that's also a single session.
      const updateVideoSessionData = async () => {
        const data = await _vimeoPlayer.getPlayed();

        const timeRange = data.map((arr) => ({
          start: parseFloat(arr[0].toFixed(2)),
          end: parseFloat(arr[1].toFixed(2))
        }));

        return firebase.updateVideoSessionData({
          did,
          eid,
          timeRange,
          uid: user.uid
        });
      };

      updateVideoSessionDataInterval = () => throttle(updateVideoSessionData, 60000);

      _vimeoPlayer.on('timeupdate', updateVideoSessionDataInterval());
      _vimeoPlayer.on('play', (data) => {
        // Using an 'if' statement here because when the 'play' event is fired
        // after any 'seek' event 'data' is undefined.
        if (data) {
          setLivestreamIsPlaying(true);
        }
      });
      _vimeoPlayer.on('pause', (data) => {
        setLivestreamIsPlaying(false);

        // The 'pause' event is also fired when the video ends, along with the 'ended' event.
        // We want to ignore it when the video has ended, so we'll check the percent value.
        if (data.percent !== 1) {
          updateVideoSessionData();
        }
      });
      _vimeoPlayer.on('seeked', updateVideoSessionData);
      _vimeoPlayer.on('ended', updateVideoSessionData);

      _vimeoPlayer.on('enterpictureinpicture', () => {
        setIsPipModeEnabled(true);
      });
      _vimeoPlayer.on('leavepictureinpicture', () => {
        setIsPipModeEnabled(false);
      });
    }

    return () => {
      // document.removeEventListener('keydown', keyDownListener);
      clearInterval(updateVideoSessionDataInterval);
      vimeoPlayer?.off('timeupdate');
      vimeoPlayer?.off('play');
      vimeoPlayer?.off('pause');
      vimeoPlayer?.off('seeked');
      vimeoPlayer?.off('ended');
      vimeoPlayer?.off('enterpictureinpicture');
      vimeoPlayer?.off('leavepictureinpicture');
    };
  }, [streamUrl, user, did, eid]);

  useEffect(() => {
    if (
      document.pictureInPictureEnabled &&
      vimeoPlayer &&
      !vimeoPlayer.disablePictureInPicture &&
      livestreamIsPlaying &&
      !vimeoPlayerIsInView
    ) {
      try {
        vimeoPlayer.requestPictureInPicture();
      } catch (err) {
        console.error(err);
      }
    }
  }, [vimeoPlayer, vimeoPlayerIsInView, did, eid]);

  const handlePipOverlayClick = () => vimeoPlayer.exitPictureInPicture();

  const headerTextvariants = {
    enter: {
      x: 1000,
      opacity: 0
    },
    center: {
      x: 0,
      opacity: 1
    },
    exit: {
      x: -1000,
      opacity: 0,
      transition: {
        x: { duration: 0.2 }
      }
    }
  };

  return (
    <Header ref={headerRef} initial={{ opacity: 0 }} animate={{ opacity: 1 }} id="livestream">
      <Background>
        <HeroVideo />
        {/* <BackgroundOverlay colors={colors} /> */}
      </Background>
      <AnimatePresence initial={false} exitBeforeEnter>
        {did && (
          <HeaderText
            key={did}
            variants={headerTextvariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: 'spring', stiffness: 300, damping: 30 },
              opacity: { duration: 0.1 }
            }}>
            <DateAndTime>{dateAndTimeText}</DateAndTime>
            <EventTitle>{`Welcome to EM Transform 2023 - ${eventTitle.toUpperCase()}`}</EventTitle>
            {eventSubtitle && (
              <EventSubtitle did={did}>
                <Markup content={eventSubtitle} noWrap />
              </EventSubtitle>
            )}
          </HeaderText>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {streamUrl && (
          <Container
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            colors={colors}>
            <LiveStream ref={livestreamRef}>
              {showGameScreen && gameScreenImg ? (
                <div
                  style={{
                    border: `0.188rem solid`,
                    borderColor: colors.primary,
                    transition: 'border-color',
                    transitionDuration: '0.5s',
                    padding: '56.25% 0 0 0',
                    height: '100%',
                    position: 'relative',
                    overflow: 'hidden'
                  }}>
                  <GatsbyImage
                    image={getImage(gameScreenImg)}
                    alt={`${eventName} Stream Placeholder`}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%'
                    }}
                  />
                </div>
              ) : showEndScreen && endScreenImg ? (
                <div
                  style={{
                    border: `0.188rem solid`,
                    borderColor: colors.primary,
                    transition: 'border-color',
                    transitionDuration: '0.5s',
                    padding: '56.25% 0 0 0',
                    height: '100%',
                    position: 'relative',
                    overflow: 'hidden'
                  }}>
                  <GatsbyImage
                    image={getImage(endScreenImg)}
                    alt={`${eventName} Stream Placeholder`}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%'
                    }}
                  />
                </div>
              ) : (
                <div
                  ref={pipRef}
                  style={{
                    border: `0.188rem solid`,
                    borderColor: colors.primary,
                    transition: 'border-color 0.5s ease-in-out',
                    padding: '56.25% 0 0 0',
                    height: '100%',
                    position: 'relative',
                    overflow: 'hidden'
                  }}>
                  <iframe
                    title={eventName}
                    id="livestream-iframe"
                    src={streamUrl}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%'
                    }}
                  />
                  <LivestreamOverlays
                    colors={colors}
                    did={did}
                    eid={eid}
                    handlePipOverlayClick={handlePipOverlayClick}
                    isPipModeEnabled={isPipModeEnabled}
                    livestreamRef={livestreamRef}
                    questionCurrentlyBeingAnsweredLive={questionCurrentlyBeingAnsweredLive}
                  />
                </div>
              )}
            </LiveStream>
            <Interaction
              colors={colors}
              did={did}
              eid={eid}
              dbEventTitle={dbEventTitle}
              forcedActiveTab={forcedActiveTab}
              questionCurrentlyBeingAnsweredLive={questionCurrentlyBeingAnsweredLive}
              isQAndAEnabled={isQAndAEnabled}
              isPollsEnabled={isPollsEnabled}
              isParticipantsEnabled={isParticipantsEnabled}
            />
          </Container>
        )}
      </AnimatePresence>
    </Header>
  );
};

const HeaderText = styled(motion.div)`
  color: #3c3c3c;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  left: -1rem;
  position: relative;
  > * {
    grid-column: 2/13;
  }
`;

const DateAndTime = styled.h3`
  font-size: 1rem;

  @media screen and (min-width: 650px) {
    font-size: 1.25rem;
  }

  @media screen and (min-width: 1150px) {
    margin-top: 2.125em;
  }
`;

const EventTitle = styled.h1`
  font-size: 2.5rem;
  margin-top: 0.425em;

  @media screen and (min-width: 650px) {
    font-size: 3rem;
  }
`;

const EventSubtitle = styled.p`
  font-size: 0.875rem;
  margin: 1.25em 0 2.125em 0.188em;

  @media screen and (min-width: 650px) {
    font-size: 1rem;
  }

  @media screen and (min-width: 1150px) {
    margin-bottom: -1.2em;
  }
`;

const LiveStream = styled.div`
  height: auto;
  margin: 1.25rem -1.25rem;
  position: relative;
  @media (min-width: 1150px) {
    margin: 0;
    height: 100%;
  }
`;

const Container = styled(motion.div)`
  color: #00004e;
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  grid-template-columns: 1fr;
  margin: 0 auto;
  /* max-width: 1863px; */
  padding-bottom: 2.5rem;
  position: relative;
  width: 100%;

  @media (min-width: ${breakpoints.lg}) {
    padding: 4rem 0;
    grid-template-columns: 2fr 1fr;
  }
`;

const BackgroundImage = styled(GatsbyImage)`
  bottom: 0;
  height: 100%;
  position: absolute;
  right: 0;
  width: 100%;
`;

const BackgroundVideo = styled.video`
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
`;

const Background = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const Header = styled.header`
  background: white;
  height: auto;
  overflow-x: hidden;
  padding: 6rem 0.5rem 0;
  position: relative;
  width: 100%;

  @media screen and (min-width: 450px) {
    padding: 6rem 1.25rem 0;
  }

  @media (min-width: ${breakpoints.lg}) {
    min-height: 100vh;
    /* padding: 0 2.5rem; */
    padding-top: 8rem;
  }
`;

const AnimatedDateButton = styled(motion.div)`
  height: auto;
  margin-top: 1.95rem;
  width: auto;
`;

const Button = styled(motion.a)`
  align-items: center;
  background-color: ${({ colors }) => colors.tertiary};
  border-radius: 0.5rem;
  color: white;
  cursor: pointer;
  display: flex;
  font-family: noto-sans, tahoma, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  height: 5rem;
  justify-content: center;
  opacity: 1;
  padding: 1rem;
  text-transform: uppercase;
  transition: 0.5s;
  width: 100%;

  &:last-child {
    background-color: ${({ colors }) => colors.primary};
  }

  @media (min-width: ${breakpoints.lg}) {
    opacity: 0.8;
  }

  &:hover {
    opacity: 1;
  }
`;

const Links = styled.div`
  display: grid;
  grid-column: 1/7;
  grid-gap: 1.5rem;
  grid-template-columns: 250px;
  height: auto;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
  @media (min-width: 768px) {
    align-items: center;
    grid-gap: 2.5rem;
    margin-top: 3rem;
  }
  @media (min-width: ${breakpoints.lg}) {
    grid-column: 4/10;
  }
`;
