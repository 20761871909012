import React from 'react';
import { Button, GameScreenshot, Modal, GamePopup } from 'components';
import styled from 'styled-components';
import { useWindowSize } from 'hooks';
import { useStaticQuery, graphql } from 'gatsby';
import { motion } from 'framer-motion';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { AppleStoreLogo, GooglePlayLogo, QrCode } from 'assets/svgs';
// import playIcon from './play-icon.png';

const FeelTheJoy = ({ colors, style }) => {
  const { backgroundImg, gameScreenshot } = useStaticQuery(graphql`
    query {
      backgroundImg: file(relativePath: { eq: "assets/images/feel-the-joy-bg-img-1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 517, height: 328)
        }
      }
      gameScreenshot: file(relativePath: { eq: "assets/images/game-screenshot.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 419, height: 236)
        }
      }
    }
  `);

  const { windowWidth } = useWindowSize();
  console.log('colors', colors);
  return (
    <Wrapper colors={colors} style={style}>
      <div>
        {windowWidth >= 1150 && (
          <BackgroundImage image={getImage(backgroundImg)} alt="Person holding earth in hands" />
        )}
        <section>
          <h2>Feel The #JOY</h2>
          <h3>How do I get started with the game?</h3>
          {/* <p>
            <a href="/joy-challenge.mp4" target="_blank">
              <strong>Watch this video</strong>
            </a>{' '}
            before you get started or click the button to find out more about the game session and
            how to participate.
          </p> */}
          {/* {windowWidth < 1150 && (
            <GameScreenshot
              style={{ maxHeight: '235px', maxWidth: '407px' }}
              gameScreenshot={gameScreenshot}
            />
          )} */}
          <Modal
            trigger={
              <Button
                whileHover={{ scale: 1.035 }}
                whileTap={{ scale: 0.975 }}
                style={{ width: '332px', backgroundColor: '#0E41D5', color: '#fff' }}>
                Click For More Information
              </Button>
            }
            modalContent={<GamePopup />}
          />
        </section>
        {/* {windowWidth >= 1150 && (
          <GameScreenshot
            style={{ maxHeight: '235px', maxWidth: '407px' }}
            gameScreenshot={gameScreenshot}
          />
        )} */}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 5.188rem 1rem 0;
  width: 100%;
  ${({ style }) => style}

  > div {
    align-items: center;
    background-blend-mode: unset;
    background-color: ${({ colors }) => (colors ? colors.primary : '#0095ff')};
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 34.25rem;
    padding: 2rem 1.5rem;
    position: relative;

    @media screen and (min-width: 1150px) {
      align-items: center;
      flex-direction: row;
      height: auto;
      overflow: hidden;
      max-width: 72.75rem;
      padding: 2.5rem 42px 1.875rem 53px;

      section:nth-of-type(1) {
        margin-right: 2rem;
      }
    }

    h2,
    p,
    button {
      position: relative;
      z-index: 1;
    }

    h2,
    h3,
    p {
      color: #fff;
    }

    a {
      color: #fff;
      text-decoration: underline;
    }

    h2 {
      text-align: center;
      font-size: 2.5rem;
      margin-bottom: 0.125em;

      @media screen and (min-width: 420px) {
        font-size: 3rem;
      }

      @media screen and (min-width: 1150px) {
        text-align: left;
      }
    }

    h3 {
      text-align: center;
      font-size: 1.5rem;
      margin-bottom: 1em;
      padding: 0 1rem;

      @media screen and (min-width: 420px) {
        font-size: 2rem;
        padding: unset;
      }

      @media screen and (min-width: 1150px) {
        text-align: left;
      }
    }

    p {
      font-size: 1rem;
      margin-bottom: 2em;
      max-width: 40.75rem;
      padding: 0 1rem;
      text-align: center;

      @media screen and (min-width: 420px) {
        padding: unset;
        font-size: 1rem;
      }

      @media screen and (min-width: 1150px) {
        text-align: left;
      }
    }

    @media screen and (max-width: 1149px) {
      button {
        margin: 2rem auto 0;
      }
    }
  }
`;

const BackgroundImage = styled(GatsbyImage)`
  bottom: 0;
  position: absolute;
  right: 0;
`;

export default FeelTheJoy;
