import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { LoadingSpinner, Modal, LoginModal } from 'components';
import { FirebaseContext } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import { pfizer } from 'styles';

const ProtectedRoute = ({ children, slug, eventIsHidden }) => {
  const { firebase, loading, user } = useContext(FirebaseContext);
  const firebaseHasLoaded = !loading && firebase;
  const userIsInFirebaseAuth = firebase?.auth?.currentUser;

  // We allow an event livestream page to be accessed when 'eventIsHidden' so that Pfizer can
  // preview/approve the livestream page of a hidden event.
  const openGatedContent = eventIsHidden || user;

  return (
    <>
      <AnimatePresence>
        {(loading || (firebase && userIsInFirebaseAuth && !user)) && (
          <LoadingSpinnerFullScreenWrapper animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <LoadingSpinner style={{ color: '#0095FF', width: '8rem' }} />
          </LoadingSpinnerFullScreenWrapper>
        )}
      </AnimatePresence>
      {(eventIsHidden || (firebaseHasLoaded && userIsInFirebaseAuth && user)) &&
        (openGatedContent ? children : navigate('/'))}
      {!eventIsHidden && firebaseHasLoaded && !userIsInFirebaseAuth && (
        <Modal
          hideCloseButton
          openWithoutTrigger
          redirectIfNotAuthorised
          // disableCloseOnClickOutside
          modalContent={<LoginModal slug={slug} colors={pfizer} />}
        />
      )}
    </>
  );
};

const LoadingSpinnerFullScreenWrapper = styled(motion.div)`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100vw;
`;

export default ProtectedRoute;
