import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { Stopwatch } from 'assets/animated-svgs';
import { fadeInAndOutVariants } from 'styles';

const formatTimeLeft = (n) => (n < 10 ? `0${n}` : n);

const getInitialState = ({ timerEndsAt, serverTimeOffset, setPollTimerHasExpired }) => {
  const timeLeft = Math.round((timerEndsAt - (Date.now() + serverTimeOffset)) / 1000) * 1000;

  if (timeLeft > 0) {
    return {
      minutes: formatTimeLeft(Math.floor(timeLeft / 60000)),
      seconds: formatTimeLeft(Math.floor(timeLeft / 1000) % 60),
      timeLeft
    };
  }

  if (timeLeft <= 0) {
    setPollTimerHasExpired(true);
    return {
      minutes: '00',
      seconds: '00',
      timeLeft
    };
  }
};

const Timer = ({ colors, timerEndsAt, serverTimeOffset, setPollTimerHasExpired }) => {
  const [timer, setTimer] = useState(
    getInitialState({ timerEndsAt, serverTimeOffset, setPollTimerHasExpired })
  );
  let countdownTimerInterval;

  useEffect(() => {
    const calculateTimeLeft = () => {
      const timeLeft = Math.round((timerEndsAt - (Date.now() + serverTimeOffset)) / 1000) * 1000;

      if (timeLeft > 0) {
        setTimer({
          minutes: formatTimeLeft(Math.floor(timeLeft / 60000)),
          seconds: formatTimeLeft(Math.floor(timeLeft / 1000) % 60),
          timeLeft
        });
      }

      if (timeLeft <= 0) {
        setTimer({
          minutes: '00',
          seconds: '00',
          timeLeft
        });
        setPollTimerHasExpired(true);
        clearInterval(countdownTimerInterval);
      }
    };

    calculateTimeLeft();

    countdownTimerInterval = setInterval(calculateTimeLeft, 1000);

    return () => {
      if (countdownTimerInterval) {
        clearInterval(countdownTimerInterval);
      }
    };
  }, []);

  return (
    <AnimatePresence>
      <Wrapper variants={fadeInAndOutVariants()} initial="initial" animate="animate" exit="exit">
        <Stopwatch color={timer.timeLeft <= 0 ? '#f55151' : colors.primary} width="1.563rem" />
        <RemainingTime
          timeLeft={timer.timeLeft}>{`${timer?.minutes}:${timer?.seconds}`}</RemainingTime>
      </Wrapper>
    </AnimatePresence>
  );
};

const Wrapper = styled(motion.div)`
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: -3.85rem;
  width: 100%;
`;

const RemainingTime = styled.span`
  animation: ${({ timeLeft }) =>
    timeLeft > 0 && timeLeft <= 5000
      ? 'flashAndScale 1s infinite'
      : timeLeft <= 0
      ? 'scale 1s'
      : 'unset'};
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.031em;
  margin: 0.12em 0 0 0.406em;
  opacity: 1;

  @keyframes flashAndScale {
    50% {
      opacity: 1;
      transform: scale3d(1.1, 1.1, 1.1);
    }

    100% {
      opacity: 0;
      transform: scale3d(1, 1, 1);
    }
  }

  @keyframes scale {
    50% {
      transform: scale3d(1.2, 1.2, 1.2);
    }

    100% {
      transform: scale3d(1, 1, 1);
    }
  }
`;

export default Timer;
