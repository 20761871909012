import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Button, ErrorMessage } from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { Markup } from 'interweave';
import { clearQueryParams } from 'utils';
import { FormContainer, FormLabel, FormInput } from '../FormComponents';

const ActionCodeError = ({ actionCodeError, setActionCodeError }) => {
  const [formValues, setFormValues] = useState({
    email: ''
  });
  const [passwordResetEmailSent, setPasswordResetEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { theme } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);

  const handleInputChage = (e) => {
    if (errorMessage) {
      setErrorMessage('');
    }
    const { name, value } = e.target;
    setFormValues((currentValues) => ({
      ...currentValues,
      [name]: value
    }));
  };

  const resendPasswordResetEmail = (e) => {
    e.preventDefault();
    setErrorMessage('');

    firebase
      .sendPasswordResetEmail(formValues.email)
      .then(() => {
        setPasswordResetEmailSent(true);
        setFormValues({
          email: ''
        });
      })
      .catch((error) => {
        console.error(error);
        switch (error.code) {
          case 'auth/user-not-found':
            setErrorMessage('There is no user account associated with this email address.');
            break;
          default:
            setErrorMessage(error.message);
            break;
        }
      });
  };

  return (
    <CustomContainer onSubmit={resendPasswordResetEmail} theme={theme}>
      <ModalTitle>
        {passwordResetEmailSent ? (
          <Markup content="We've sent you another email.<br />Please check your inbox." />
        ) : (
          <Markup content={actionCodeError.message} />
        )}
      </ModalTitle>
      {actionCodeError.mode === 'verifyEmail' || actionCodeError.mode === 'recoverEmail' ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => {
              clearQueryParams();
              setActionCodeError({ mode: null, message: null });
            }}
            type="button"
            width="8.125rem"
            style={{ alignSelf: 'center' }}>
            Close
          </Button>
        </div>
      ) : actionCodeError.mode === 'resetPassword' && !passwordResetEmailSent ? (
        <>
          <FormLabel hide htmlFor="email">
            Email
          </FormLabel>
          <FormInput
            id="email"
            name="email"
            onChange={handleInputChage}
            placeholder="Email"
            type="email"
            value={formValues.email}
            required
          />
          <Button style={{ alignSelf: 'center' }} type="submit" width="330px">
            Send New Email
          </Button>
        </>
      ) : null}
      <ErrorMessage
        errorMessage={errorMessage}
        style={{
          color: '#C7D601'
        }}
      />
    </CustomContainer>
  );
};

const ModalTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  text-align: center;
`;

const CustomContainer = styled(FormContainer)`
  margin-top: 20px;
  max-width: 450px;

  @media screen and (min-width: 1150px) {
    padding: 40px 20px;
  }
`;

export default ActionCodeError;
