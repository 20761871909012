import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { ModalCloseIcon } from 'assets/svgs';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const ModalContent = (props) =>
  ReactDOM.createPortal(
    <ModalCover
      tag="aside"
      role="dialog"
      tabIndex="-1"
      aria-modal="true"
      className="modal-cover"
      onKeyDown={props.onKeyDown}>
      <ModalArea
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={props.onClickOutside}>
        <ModalBody ref={props.modalRef} onClick={(e) => e.stopPropagation()}>
          {!props.hideCloseButton && (
            <ModalClose
              ref={props.buttonRef}
              aria-label="Close Modal"
              aria-labelledby="close-modal"
              transparentCloseButtonBackground={props.transparentCloseButtonBackground}
              onClick={props.closeModal}
              colors={props.colors}
              style={props.style}>
              <HideVisual id="close-modal">Close</HideVisual>
              <ModalCloseIcon width="20" height="20" />
            </ModalClose>
          )}
          {props.content}
        </ModalBody>
      </ModalArea>
    </ModalCover>,
    document.body
  );

const ModalCover = styled.div`
  background-color: rgba(255, 255, 255, 0.75);
  box-sizing: border-box;
  height: 100%;
  left: 0;
  max-width: 100vw;
  padding: 1rem 0.25rem;
  position: fixed;
  top: 0;
  transform: translateZ(0);
  width: 100%;
  z-index: 10;
`;

const ModalArea = styled(motion.aside)`
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: none;
  padding: 1rem;
  width: 100%;
`;

const ModalClose = styled.button`
  align-items: center;
  background: ${({ colors, transparentCloseButtonBackground }) =>
    transparentCloseButtonBackground ? 'transparent' : colors ? colors.secondary : '#0095FF'};
  border: 0;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  display: flex;
  height: 2rem;
  justify-content: center;
  line-height: 1;
  padding: 0.75em;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  transition: 0.2s;
  width: 2rem;
  z-index: 20;
  ${({ style }) => style}
  &:hover {
    transform: scale3d(1.075, 1.075, 1.075);
    transition: transform 200ms ease-in-out;
  }
`;

const ModalBody = styled.div`
  height: auto;
  max-height: 100%;
  max-width: 100%;
  position: relative;
  width: auto;
  form {
    margin-bottom: 20px;
  }
`;
const HideVisual = styled.span`
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
`;

export default ModalContent;
