import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const GameScreenshot = ({ style, gameScreenshot }) => {
  const { playButton } = useStaticQuery(graphql`
    query {
      playButton: file(relativePath: { eq: "assets/images/play-button.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100, height: 100, quality: 100)
        }
      }
    }
  `);
  return (
    <Wrapper style={style}>
      <GatsbyImage image={getImage(gameScreenshot)} alt="Game screenhot" />
      <div>
        <a href="/joy-challenge.mp4" target="_blank">
          <BigPlayButton image={getImage(playButton)} alt="play button" />
        </a>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  border: 7px solid #0e41d5;
  border-radius: 2px;
  box-shadow: 5px 5px 12px -6px rgba(0, 0, 0, 0.75);
  position: relative;
  width: 100%;
  z-index: 2;
  ${({ style }) => style}

  @media screen and (max-width: 1149px) {
    margin: 0 auto;
  }

  > div:nth-of-type(2) {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

const BigPlayButton = styled(GatsbyImage)`
  animation: pulse 1.2s ease-in-out infinite;
  border: 1px solid transparent;
  border-radius: 50%;
  box-shadow: 0 4px 13px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  &:active {
    transform: scale(0.9);
  }

  @keyframes pulse {
    0% {
      filter: brightness(100%);
      transform: scale(100%);
    }

    50% {
      filter: brightness(120%);
      transform: scale(107%);
    }

    100% {
      filter: brightness(100%);
      transform: scale(100%);
    }
  }
`;

export default GameScreenshot;
