import React, { useContext } from 'react';
import { Button } from 'components';
import { navigate } from '@reach/router';
import { LocalContext, FirebaseContext } from 'context';
import styled, { css } from 'styled-components';
import { clearQueryParams, scrollToAnchor } from 'utils';
import { Markup } from 'interweave';
import { FormContainer } from '../FormComponents';

const NewUserEmailVerification = ({
  setShowNewUserEmailVerificationModal,
  newUserEmailVerified
}) => {
  const { user } = useContext(FirebaseContext);
  const { theme } = useContext(LocalContext);

  const handleButtonClick = () => {
    if (user) {
      clearQueryParams();
      // scrollToAnchor('agenda', {
      //   duration: 1200,
      //   offset: -90
      // });
      setShowNewUserEmailVerificationModal(false);
      navigate('/day-3/');
    }
  };

  return (
    <FormContainer
      theme={theme}
      style={{
        padding: newUserEmailVerified ? '1.625rem 2.25rem 2.5rem' : '2.5rem 1.25rem',
        maxWidth: '412px'
      }}>
      <Status newUserEmailVerified={newUserEmailVerified}>
        <Markup
          content={
            newUserEmailVerified && user?.name
              ? `<span style="position: relative; top: 0.45rem;">Thanks, ${
                  user.name.split(' ')[0]
                }!</span><br /><br />Click below to proceed to the event!<br><br>`
              : 'Verifying Email'
          }
        />
      </Status>
      {newUserEmailVerified && user?.name && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => handleButtonClick()}
            whileTap={{ scale: 0.95 }}
            type="button"
            width="11rem"
            style={{ alignSelf: 'center', marginTop: '0.25rem' }}>
            Continue
          </Button>
        </div>
      )}
    </FormContainer>
  );
};

const Status = styled.h3`
  align-items: center;
  display: flex;
  font-size: ${({ newUserEmailVerified }) => (newUserEmailVerified ? '1.5rem' : '1.75rem')};
  font-style: normal;
  font-weight: bold;
  justify-content: center;
  line-height: 1.275em;
  text-align: center;

  ${({ newUserEmailVerified }) =>
    !newUserEmailVerified &&
    css`
      &:after {
        animation: dots 1s steps(5, end) infinite;
        content: ' .';
        margin-left: 0.25rem;
      }

      @keyframes dots {
        0%,
        20% {
          color: rgba(0, 0, 0, 0);
          text-shadow: 0.5rem 0 0 rgba(0, 0, 0, 0), 1rem 0 0 rgba(0, 0, 0, 0);
        }
        40% {
          color: #fff;
          text-shadow: 0.5rem 0 0 rgba(0, 0, 0, 0), 1rem 0 0 rgba(0, 0, 0, 0);
        }
        60% {
          text-shadow: 0.5rem 0 0 #fff, 1rem 0 0 rgba(0, 0, 0, 0);
        }
        80%,
        100% {
          text-shadow: 0.5rem 0 0 #fff, 1rem 0 0 #fff;
        }
      }
    `}
`;

export default NewUserEmailVerification;
