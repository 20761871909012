import * as React from 'react';

function SvgBlurryBlob(props) {
  return (
    <svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={60} cy={60} r={60} fill="url(#blurry-blob_svg__paint0_radial_284_1386)" />
      <defs>
        <radialGradient
          id="blurry-blob_svg__paint0_radial_284_1386"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 60 -60 0 60 60)">
          <stop offset={0.376} stopColor="#0095FF" />
          <stop offset={1} stopColor="#0095FF" stopOpacity={0} />
        </radialGradient>
      </defs>
    </svg>
  );
}
export default SvgBlurryBlob;
