import React from 'react';
import styled from 'styled-components';
import Star from '../SharedGraphics/Star';
import Mesh from '../SharedGraphics/Mesh';
import Background from './Background';

function DaysIntroGraphic({ color }) {
  return (
    <Container>
      <StyledBackground color={color} />
      <StyledMesh color={color} />
      <StyledStar1 />
      <StyledStar2 />
    </Container>
  );
}

const Container = styled.div`
  max-width: 562px;
  position: relative;
  top: -2.5rem;
  width: 100%;
`;

const StyledBackground = styled(Background)`
  width: 100%;
`;

const StyledMesh = styled(Mesh)`
  animation: moveMesh 3s ease-in-out alternate-reverse infinite;
  left: 11%;
  position: absolute;
  top: -7.5%;
  transform: rotate(-5deg);
  width: 79.35%;

  @keyframes moveMesh {
    0% {
      transform: rotate(-5deg);
    }

    100% {
      transform: rotate(5deg);
    }
  }
`;

const StyledStar1 = styled(Star)`
  animation: rotate 2s ease-in-out alternate-reverse infinite;
  left: 5%;
  position: absolute;
  top: 5%;
  width: 160px;

  @keyframes rotate {
    0% {
      transform: rotate3d(0, 0, 1, 0deg);
    }
    100% {
      transform: rotate3d(0, 0, 1, 90deg);
    }
  }
`;

const StyledStar2 = styled(Star)`
  animation: rotate 2s ease-in-out alternate-reverse infinite;
  position: absolute;
  right: 5%;
  top: 53%;
  transform: rotate3d(0, 0, 1, -45deg);
  width: 91px;

  @keyframes rotate {
    0% {
      transform: rotate3d(0, 0, 1, -45deg);
    }
    100% {
      transform: rotate3d(0, 0, 1, 45deg);
    }
  }
`;

export default DaysIntroGraphic;
