import * as React from 'react';

function SvgBigStar(props) {
  return (
    <svg viewBox="0 0 467 468" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M467 234C291.886 201.703 265.728 175.489 233.5 0 201.271 175.489 175.114 201.703 0 234c116.743 0 116.743 0 0 0 175.114 32.297 201.271 58.511 233.5 234 32.228-175.489 58.386-201.703 233.5-234-116.742 0-116.742 0 0 0z"
        fill="url(#big-star_svg__paint0_linear_2_1230)"
      />
      <defs>
        <linearGradient
          id="big-star_svg__paint0_linear_2_1230"
          x1={112.309}
          y1={91.211}
          x2={341.511}
          y2={360.067}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#891AFC" />
          <stop offset={0.37} stopColor="#2B46D1" />
          <stop offset={0.7} stopColor="#29ABE2" />
          <stop offset={0.97} stopColor="#FCEE21" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgBigStar;
