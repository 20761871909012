import * as React from 'react';

function SvgDaysIntroGraphicsBackground(props) {
  return (
    <svg viewBox="0 0 564 426" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle
        cx={243}
        cy={269.118}
        r={156}
        fill="url(#days-intro-graphics-background_svg__paint0_linear_182_3)"
      />
      <circle cx={383} cy={318.118} r={107} fill={props.color} />
      <circle
        cx={164}
        cy={174.118}
        r={108}
        fill="url(#days-intro-graphics-background_svg__paint1_linear_182_3)"
      />
      <circle cx={243} cy={269.118} r={142} fill="#1000C9" />
      <circle cx={107} cy={313.118} r={107} fill="#1000C9" />
      <circle cx={373} cy={195.118} r={142} fill="#1000C9" />
      <g opacity={0.2} strokeWidth={2} strokeMiterlimit={10}>
        <path
          style={{
            mixBlendMode: 'luminosity'
          }}
          d="M391.968 296.238c8.984 2.966 5.149 11.507-3.3 19.306-8.447 7.798-21.508 14.856-30.97 14.856-18.927 0-34.271-15.296-34.271-34.163 0-9.435 4.445-19.272 11.152-27.44 6.707-8.17 15.676-14.67 24.726-17.432 8.976-2.739 10.131 6.163 12.546 16.91 2.415 10.745 6.094 23.333 20.116 27.962l.001.001z"
          stroke="url(#days-intro-graphics-background_svg__paint2_linear_182_3)"
        />
        <path
          d="M377.667 210.053c11.963-3.449 16.419 5.089 21.627 17.967 3.874 9.583 7.381 20.64 10.579 31.583 3.149 10.86 5.265 22.085 10.554 29.85 5.397 7.927 1.314 18.493-7.654 28.837-11.017 12.705-26.826 23.042-40.985 23.042-17.86 0-31.752-4.427-41.13-13.318-9.23-8.737-14.062-21.824-14.062-39.4 0-10.107 3.589-21.263 9.424-31.956 2.877-5.272 6.303-10.427 10.118-15.286 4.19-5.338 8.849-10.321 13.767-14.715 8.861-7.95 18.543-13.948 27.762-16.606v.002z"
          stroke="url(#days-intro-graphics-background_svg__paint3_linear_182_3)"
        />
        <path
          d="M395.545 168.772c14.935-4.204 23.179 3.885 30.706 19.028 6.794 13.668 12.003 29.96 15.367 46.741 3.324 16.715 3.709 33.946 6.783 48.162 2.533 11.713-2.372 25.635-12.007 38.367-13.448 17.77-32.144 31.227-51.002 31.227-26.12 0-45.222-4.92-57.757-16.356-12.415-11.316-18.356-29.061-18.356-54.915 0-13.984 5.237-29.888 13.656-45.285 4.084-7.468 8.919-14.812 14.275-21.758 5.895-7.65 12.422-14.821 19.266-21.146 12.629-11.714 26.319-20.528 39.07-24.066l-.001.001z"
          stroke="url(#days-intro-graphics-background_svg__paint4_linear_182_3)"
        />
        <path
          d="M412.871 127.514c17.916-4.938 30.052 2.606 39.786 20.087 9.844 17.677 16.623 39.28 20.154 61.901 3.501 22.571 2.831 45.899 3.013 66.473.137 15.632-5.988 32.829-16.362 47.899-15.771 22.908-37.463 39.412-61.017 39.412-34.38 0-58.694-5.411-74.385-19.396-15.601-13.895-22.65-36.296-22.65-70.43 0-17.859 6.886-38.511 17.89-58.612 5.291-9.666 11.536-19.198 18.43-28.231 7.602-9.964 15.994-19.32 24.764-27.577 16.4-15.477 34.101-27.086 50.377-31.523v-.003z"
          stroke="url(#days-intro-graphics-background_svg__paint5_linear_182_3)"
        />
        <path
          d="M428.408 86.275c20.896-5.67 36.964 1.29 48.865 21.145 12.965 21.633 21.245 48.601 24.942 77.06 3.677 28.426 2.159 58.043-.759 84.784-2.154 19.737-9.561 40.042-20.716 57.429-18.017 28.087-42.781 47.596-71.031 47.596-42.639 0-72.165-5.903-91.013-22.436-18.788-16.474-26.943-43.532-26.943-85.946 0-21.734 8.534-47.134 22.124-71.941 6.496-11.861 14.151-23.584 22.585-34.703 9.309-12.276 19.567-23.82 30.262-34.008 20.17-19.239 41.883-33.645 61.685-38.981l-.001.001z"
          stroke="url(#days-intro-graphics-background_svg__paint6_linear_182_3)"
        />
        <path
          d="M443.66 45.056c23.876-6.403 43.894-.054 57.944 22.204 16.129 25.55 25.866 57.921 29.727 92.219 3.853 34.281 1.537 70.296-4.528 103.095-4.428 23.948-13.111 47.261-25.069 66.96-20.21 33.291-48.101 55.781-81.049 55.781-50.898 0-85.636-6.394-107.639-25.477-21.974-19.051-31.238-50.767-31.238-101.46 0-25.609 10.182-55.758 26.356-85.27 7.704-14.057 16.769-27.969 26.741-41.175 11.016-14.589 23.139-28.32 35.76-40.44 23.941-23.003 49.663-40.203 72.992-46.439l.003.002z"
          stroke="url(#days-intro-graphics-background_svg__paint7_linear_182_3)"
        />
        <path
          d="M431.603 396.357c-59.157 0-99.106-6.887-124.267-28.517-25.16-21.63-35.532-58.002-35.532-116.975 0-29.486 11.831-64.381 30.589-98.598 18.759-34.216 44.445-67.752 72.156-94.518 27.711-26.766 57.445-46.761 84.3-53.898 26.855-7.136 50.831-1.411 67.024 23.264 19.323 29.443 30.487 67.241 34.515 107.379 4.029 40.137.921 82.611-8.299 121.405-18.439 77.59-61.329 140.458-120.487 140.458h.001z"
          stroke="url(#days-intro-graphics-background_svg__paint8_linear_182_3)"
        />
      </g>
      <defs>
        <linearGradient
          id="days-intro-graphics-background_svg__paint0_linear_182_3"
          x1={243}
          y1={113.118}
          x2={243}
          y2={425.118}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0095FF" />
          <stop offset={0.172} stopColor="#3C09DB" />
          <stop offset={0.474} stopColor="#891AFC" />
          <stop offset={0.974} stopColor="#C7D601" />
        </linearGradient>
        <linearGradient
          id="days-intro-graphics-background_svg__paint1_linear_182_3"
          x1={164}
          y1={66.118}
          x2={164}
          y2={282.118}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0095FF" />
          <stop offset={0.172} stopColor="#3C09DB" />
          <stop offset={0.474} stopColor="#891AFC" />
          <stop offset={0.974} stopColor="#C7D601" />
        </linearGradient>
        <linearGradient
          id="days-intro-graphics-background_svg__paint2_linear_182_3"
          x1={323.294}
          y1={290.633}
          x2={397.255}
          y2={290.633}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="days-intro-graphics-background_svg__paint3_linear_182_3"
          x1={320.331}
          y1={264.848}
          x2={425.657}
          y2={285.979}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="days-intro-graphics-background_svg__paint4_linear_182_3"
          x1={325.045}
          y1={235.75}
          x2={452.104}
          y2={299.493}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="days-intro-graphics-background_svg__paint5_linear_182_3"
          x1={332.856}
          y1={191.987}
          x2={465.847}
          y2={325.396}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="days-intro-graphics-background_svg__paint6_linear_182_3"
          x1={344.23}
          y1={132.612}
          x2={460.053}
          y2={364.935}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="days-intro-graphics-background_svg__paint7_linear_182_3"
          x1={374.067}
          y1={58.871}
          x2={438.794}
          y2={383.522}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
        <linearGradient
          id="days-intro-graphics-background_svg__paint8_linear_182_3"
          x1={416.962}
          y1={0.867}
          x2={416.962}
          y2={396.491}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB60A8" />
          <stop offset={0.19} stopColor="#891AFC" />
          <stop offset={0.58} stopColor="#2B46D1" />
          <stop offset={0.97} stopColor="#29ABE2" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgDaysIntroGraphicsBackground;
