import * as React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export default ({ answer }) => (
  <Container
    variants={{ collapsed: { scale: 0.8, opacity: 0 }, open: { scale: 1, opacity: 1 } }}
    transition={{ duration: 0.5 }}
    className="content-placeholder">
    <p>{answer}</p>
  </Container>
);

const Container = styled(motion.div)`
  font-size: 1rem;
  padding: 1.25rem 3rem;
  width: 100%;
`;
