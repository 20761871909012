import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

export default ({ children }) => (
  <LoadingSpinnerFullScreenWrapper animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
    {children}
  </LoadingSpinnerFullScreenWrapper>
);

const LoadingSpinnerFullScreenWrapper = styled(motion.div)`
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100vw;
`;
