import React, { useContext } from 'react';
import styled from 'styled-components';
import { AgencyxLogo } from 'assets/svgs';
import { LocalContext, FirebaseContext } from 'context';
import { Link } from 'gatsby';

export default () => {
  const { contactRef } = useContext(LocalContext);
  const { user } = useContext(FirebaseContext);
  return (
    <Footer ref={contactRef} id="contact">
      <Container>
        <div>
          <p>EM Transform 2023 is brought to you by Agency X in conjunction with Pfizer</p>
          <a href="https://www.agencyx.ie/" target="__blank">
            <AgencyxLogo
              style={{
                width: '200px'
              }}
            />
          </a>
        </div>
        <div>
          <h3>Support</h3>
          <p>
            <a href="mailto:support@emtransform.com" target="_blank" rel="noreferrer">
              support@emtransform.com
            </a>
          </p>
          {user && (
            <>
              <h3>Contact</h3>
              <p>
                <a
                  href="mailto:EMOncologyTransform2023@pfizer.com"
                  target="_blank"
                  rel="noreferrer">
                  EMOncologyTransform2023@pfizer.com
                </a>
              </p>
            </>
          )}
        </div>
      </Container>
      <Copyright>
        <div>
          {/* <p>Copyright © 2021 - Designed and Developed by Agency X<br /><span>PP-AIP-IRL-0224 | Date of preparation: April 2021</span></p> */}
          <p>
            Copyright © 2023 - Designed and Developed by{' '}
            <a
              href="https://www.agencyx.ie/"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'underline' }}>
              Agency X
            </a>
            <br />
            For Pfizer Internal Use Only
          </p>
          {/* <p>
            <a href="https://agencyx.ie/terms-and-conditions" target="blank">
              Terms & Conditions
            </a>
            <Link to="/privacy">Privacy Policy</Link>
          </p> */}
        </div>
      </Copyright>
    </Footer>
  );
};

const Container = styled.div`
  align-items: start;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin: 0 auto;
  max-width: 1166px;
  padding: 1.625rem 1rem 0;
  width: 100%;
  > div {
    display: flex;
    flex-direction: column;
    grid-column: 1/7;
    justify-content: flex-start;
    justify-content: start;
    svg {
      width: 12.5rem;
      margin-bottom: 1rem;
      :last-of-type {
        width: 8rem;
      }
    }
    h3 {
      color: #3c3c3c;
      margin-top: 1.2rem;
      margin-bottom: 0.625rem;

      @media (min-width: 1200px) {
        margin-top: 0;
      }
    }
    h5 {
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }
    p {
      color: #3c3c3c;
      margin: 0.2rem 0 1.75rem;
      max-width: 28.375rem;
    }

    a {
      color: #3c3c3c;
      text-decoration: underline;
    }

    @media (min-width: 1200px) {
      :first-child {
        grid-column: 1/7;
      }
      :last-child {
        grid-column: 10/13;
        position: relative;
        right: -1rem;
        justify-content: end;
      }
    }
  }

  @media (min-width: 1200px) {
    flex-direction: row;
    padding: 3rem 0;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Copyright = styled.div`
  background: #c4c4c4;
  height: auto;
  padding: 1rem;
  width: 100%;

  > div {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    margin: 0 auto;
    max-width: 1166px;
    p {
      color: #3c3c3c;
      font-size: 0.75rem;
      grid-column: 1/7;
      margin: 0.25rem 0;

      &:first-of-type > span {
        color: #3c3c3c;
      }

      a {
        color: #3c3c3c;

        &:first-of-type {
          margin-right: 1rem;

          @media (min-width: 1200px) {
            margin-right: 2rem;
          }
        }
      }
    }
    @media (min-width: 1200px) {
      grid-template-columns: repeat(12, 1fr);
      p {
        &:first-child {
          grid-column: 1/7;
        }
        /* &:last-child {
          grid-column: 9/13;
          text-align: right;
        } */
      }
    }
  }

  @media screen and (min-width: 1200px) {
    padding: 1rem 0;
  }
`;

const Footer = styled.footer`
  background: #ededed;
  overflow-x: hidden;
  width: 100%;
`;
