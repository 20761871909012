import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useIntersection } from 'hooks';
import { DefaultCommenterAvatar } from 'assets/svgs';
import { CommenterAvatar } from 'components/Events/Livestream/Interaction/Chat';

function Participant({
  participant,
  colors,
  handleParticipantAvatarOrNameClick,
  fetchPaginatedParticipants,
  isLastFetchedParticipant,
  particpantsContainerRef
}) {
  const [ref, observerEntry] = useIntersection({
    root: particpantsContainerRef.current,
    rootMargin: '0px 0px 100px 0px',
    threshold: 0.1
  });

  useEffect(() => {
    if (isLastFetchedParticipant && observerEntry.isIntersecting) {
      fetchPaginatedParticipants();
    }
  }, [observerEntry.isIntersecting]);

  return (
    <Container
      ref={ref}
      onClick={() => handleParticipantAvatarOrNameClick(participant)}
      eventRole={participant.eventRole}
      colors={colors}>
      {participant.avatarUrl ? (
        <CommenterAvatar
          size="1.625rem"
          src={participant.avatarUrl}
          alt={participant.name}
          colors={colors}
        />
      ) : (
        <DefaultCommenterAvatar />
      )}
      <p>
        {participant.name}
        <span> - {participant.eventRole ? participant.eventRole : 'Attendee'}</span>
      </p>
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  align-self: flex-start;
  color: #c4c4c4;
  display: flex;
  font-weight: 600;
  padding: 0.313rem 0 0.5em;

  img,
  svg {
    cursor: pointer;
    flex-shrink: 0;
    margin-right: 0.5rem;
    width: 1.625rem;
  }

  p {
    cursor: pointer;
  }

  span {
    color: ${({ eventRole, colors }) => (eventRole === 'Organiser' ? colors.primary : '#000')};
    font-weight: ${({ eventRole }) => (eventRole === 'Organiser' ? 500 : 400)};
  }
`;

export default Participant;
