import React, { useContext, useState, useEffect } from 'react';
import { Button, ErrorMessage, LoadingSpinner, Modal, LoginModal } from 'components';
import styled from 'styled-components';
import { LocalContext, FirebaseContext } from 'context';
import { includesSurname, hexToRGB } from 'utils';
import { motion, AnimatePresence } from 'framer-motion';
import { useWindowSize } from 'hooks';
import { isDesktop } from 'react-device-detect';
import { fadeInAndOutVariants, fadeInAndOutAndAnimateHeightVariants } from 'styles';
import BaseSelect from 'react-select';
import FixRequiredSelect from '../FixRequiredSelect';

import { FormContainer, FormInput } from '../FormComponents';

const resetForm = () => ({
  name: '',
  email: '',
  region: '',
  function: '',
  referringUrl:
    typeof window !== 'undefined' &&
    JSON.parse(window.localStorage.getItem('referringUrlEMTransform2023'))?.data
});

const FormSelect = (props) => (
  <FixRequiredSelect {...props} SelectComponent={BaseSelect} options={props.options} />
);

const regionOptions = [
  {
    value: 'Latin America',
    label: 'Latin America'
  },
  {
    value: 'Middle East, Russia, Africa',
    label: 'Middle East, Russia, Africa'
  },
  {
    value: 'EM Asia',
    label: 'EM Asia'
  },
  {
    value: 'Brazil',
    label: 'Brazil'
  },
  {
    value: 'India',
    label: 'India'
  },
  {
    value: 'Saudi Arabia',
    label: 'Saudi Arabia'
  },
  {
    value: 'Turkey',
    label: 'Turkey'
  },
  {
    value: 'EM Above Market',
    label: 'EM Above Market'
  },
  {
    value: 'Global',
    label: 'Global'
  },
  {
    value: 'Others',
    label: 'Others'
  }
];

const functionOptions = [
  {
    value: 'Marketing',
    label: 'Marketing'
  },
  {
    value: 'Medical',
    label: 'Medical'
  },
  {
    value: 'Category Leads',
    label: 'Category Leads'
  },
  {
    value: 'Sales',
    label: 'Sales'
  },
  {
    value: 'Access',
    label: 'Access'
  },
  {
    value: 'Commercial Development',
    label: 'Commercial Development'
  },
  {
    value: 'PHEX',
    label: 'PHEX'
  },
  {
    value: 'Corporate Affairs',
    label: 'Corporate Affairs'
  },
  {
    value: 'BAI',
    label: 'BAI'
  },
  {
    value: 'Digital',
    label: 'Digital'
  },
  {
    value: 'Regulatory',
    label: 'Regulatory'
  },
  {
    value: 'Legal',
    label: 'Legal'
  },
  {
    value: 'PGS',
    label: 'PGS'
  },
  {
    value: 'Leadership Team',
    label: 'Leadership Team'
  },
  {
    value: 'Others',
    label: 'Others'
  }
];

const customDropdownMenuStyles = (colors, windowHeight) => ({
  container: (provided, state) => ({
    ...provided,
    alignItems: 'center',
    background: 'transparent',
    border: state.isFocused ? '0.25em solid #fff' : '1px solid #fff',
    display: 'flex',
    fontFamily: "'Noto Sans', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 'bold',
    height: '2.5rem',
    letterSpacing: '0.036em',
    padding: '0'
  }),
  control: (provided, state) => ({
    ...provided,
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    width: '100%',
    padding: state.isFocused ? '0 0 0 0.85rem' : '0 0 0 1rem',
    transition: 'none',
    cursor: 'pointer',
    height: '100%'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#fff',
    '&:hover': {
      color: '#fff'
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  input: (provided) => ({
    ...provided,
    color: '#fff',
    margin: 0
  }),
  menu: (provided) => ({
    ...provided,
    background: colors.primary,
    border: '0.25em solid #fff',
    borderRadius: '0',
    fontFamily: "'Noto Sans', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 'bold',
    left: '-0.25em',
    letterSpacing: '0.036em',
    margin: '0',
    width: 'calc(100% + 0.5em)'
  }),
  menuList: (provided) => ({
    ...provided,
    '::-webkit-scrollbar': {
      width: '11px'
    },
    '::-webkit-scrollbar-track': {
      background: '#ededed'
    },
    '::-webkit-scrollbar-thumb': {
      background: colors.secondary,
      height: '1.25rem'
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: colors.tertiary
    }
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: '#fff'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? colors.tertiary : 'transparent',
    paddingLeft: '0.85rem',
    padding: isDesktop && windowHeight <= 796 ? 0 : 'inherit',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.secondary
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "'Noto Sans', sans-serif",
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    color: 'white',
    margin: 0
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#fff',
    margin: 0
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0
  })
});

const RegistrationForm = ({ colors, contrast }) => {
  const { firebase } = useContext(FirebaseContext);
  const { setANewUserHasRegisteredForAnEvent } = useContext(LocalContext);
  const [formValues, setFormValues] = useState(resetForm());
  const { windowHeight } = useWindowSize();
  const [errorMessage, setErrorMessage] = useState('');
  const [userAlreadyExists, setUserAlreadyExists] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState({
    submitting: false,
    submitted: false
  });
  const [disableSubmitButtonWhileCheckingEmailAddress, setDisableSubmitButtonWhileCheckingInputs] =
    useState(false);
  let isMounted = true;
  let debounce;
  const debounceTimeout = 750;

  useEffect(
    () => () => {
      isMounted = false;
    },
    []
  );

  useEffect(() => {
    const { name } = formValues;
    if (name) {
      setDisableSubmitButtonWhileCheckingInputs(true);
      debounce = setTimeout(() => {
        if (!includesSurname(name)) {
          setErrorMessage('Please include a surname');
        }
        setDisableSubmitButtonWhileCheckingInputs(false);
      }, debounceTimeout);
    }
    return () => clearTimeout(debounce);
  }, [formValues.name]);

  useEffect(() => {
    const { email } = formValues;
    if (email) {
      setDisableSubmitButtonWhileCheckingInputs(true);
      debounce = setTimeout(async () => {
        try {
          const { data: _userAlreadyExists } = await firebase.checkIfUserAlreadyExists({ email });
          if (_userAlreadyExists) {
            setUserAlreadyExists(true);
            throw new Error(
              "This email address already belongs to an EM Transform account. Click 'Login' above if it belongs to you."
            );
          } else if (userAlreadyExists) {
            setUserAlreadyExists(false);
            setErrorMessage('');
          }
        } catch (error) {
          setErrorMessage(error.message);
        } finally {
          setDisableSubmitButtonWhileCheckingInputs(false);
        }
      }, debounceTimeout);
    }
    return () => clearTimeout(debounce);
  }, [formValues.email]);

  const handleInputChange = (e) => {
    e.persist();
    const { name, type, checked, value } = e.target;
    if (errorMessage && !userAlreadyExists) {
      setErrorMessage('');
    }
    if (!submissionStatus.submitted) {
      setFormValues((currentValues) => ({
        ...currentValues,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();

    if (errorMessage || submissionStatus.submitting) {
      return;
    }

    /* Can't destructure 'function' below as it obviously clashes with the JavaScript 'function' object, so renaming it _function */
    const { name, email, region, function: _function } = formValues;

    if (!name) {
      setErrorMessage('Please include your name');
      return;
    }

    if (!includesSurname(name)) {
      setErrorMessage('Please include a surname');
      return;
    }

    if (!email) {
      setErrorMessage('Please include an email address');
      return;
    }

    if (userAlreadyExists) {
      setErrorMessage(
        "This email address already belongs to an EM Transform 2023 account. Click 'Login' above if it belongs to you."
      );
      return;
    }

    if (!region) {
      setErrorMessage('Please select your region');
      return;
    }

    // if (!country) {
    //   setErrorMessage('Please select your country');
    //   return;
    // }

    if (!_function) {
      setErrorMessage('Please select your function');
      return;
    }

    try {
      setSubmissionStatus({
        submitting: true,
        submitted: false
      });

      const actionCodeSettings = {
        url: window.location.origin,
        handleCodeInApp: true
      };

      const { data } = await firebase.sendSignInWithMagicLinkEmail({
        firstName: name.split(' ')[0],
        email,
        colors,
        actionCodeSettings
      });

      if (data.Message === 'OK') {
        setSubmissionStatus({
          submitting: false,
          submitted: true
        });
        window.localStorage.setItem(
          'newUserEMTransform2023',
          JSON.stringify({
            ...formValues,
            actionCodeSettings
          })
        );
        setANewUserHasRegisteredForAnEvent(true);
      } else {
        setSubmissionStatus({
          submitting: false,
          submitted: false
        });
        setErrorMessage('Error sending email. Please try again');
      }

      setFormValues(resetForm());
    } catch (error) {
      console.error(error);

      if (isMounted) {
        setErrorMessage(error.message);
      }

      setSubmissionStatus({
        submitting: false,
        submitted: false
      });
    }
  }

  return (
    <StyledFormContainer
      id="registration-form"
      onSubmit={(e) => handleSubmit(e)}
      colors={colors}
      contrast={contrast}>
      <div
        style={{
          fontSize: '1.75rem',
          fontWeight: 600,
          lineHeight: '1.75rem',
          marginBottom: '0.55rem',
          textAlign: 'center'
        }}>
        Register Here
      </div>
      <div
        style={{
          color: `#fff`,
          display: 'flex',
          fontSize: '0.875rem',
          justifyContent: 'center',
          marginBottom: '2.6em'
        }}>
        Already have an account?&nbsp;
        <Modal
          trigger={
            <span
              style={{
                fontSize: '0.875rem',
                textAlign: 'center',
                marginBottom: '1.5em',
                textDecoration: 'underline'
              }}>
              Click here!
            </span>
          }
          hideCloseButton
          modalContent={<LoginModal colors={colors} />}
        />
      </div>
      <div
        style={{
          position: 'relative',
          display: 'grid',
          gridTemplateRows: '1fr',
          gridTemplateColumns: '1fr'
        }}>
        <div
          style={{
            gridRow: 1,
            gridColumn: 1
          }}
          variants={fadeInAndOutVariants()}
          initial="initial"
          animate="animate"
          exit="exit">
          <Fields>
            <FormInput
              id="name"
              name="name"
              onChange={handleInputChange}
              placeholder="Full Name*"
              type="text"
              value={formValues.name}
              style={{
                fontSize: '0.875rem'
              }}
              required
            />
            <FormInput
              id="email"
              name="email"
              onChange={handleInputChange}
              placeholder="Pfizer Email Address*"
              type="email"
              value={formValues.email}
              style={{
                fontSize: '0.875rem'
              }}
              required
            />
            <FormSelect
              id="region"
              name="region"
              required
              defaultValue={formValues.region}
              value={regionOptions.filter(({ value }) => value === formValues.region)}
              controlShouldRenderValue
              onChange={({ value }) => {
                setFormValues((currentStates) => ({
                  ...currentStates,
                  region: value
                }));
              }}
              placeholder="Region*"
              styles={customDropdownMenuStyles(colors)}
              options={regionOptions}
            />
            <FormSelect
              id="function"
              name="function"
              required
              defaultValue={formValues.function}
              value={functionOptions.filter(({ value }) => value === formValues.function)}
              controlShouldRenderValue
              onChange={({ value }) => {
                setFormValues((currentStates) => ({
                  ...currentStates,
                  function: value
                }));
              }}
              placeholder="Function*"
              styles={customDropdownMenuStyles(colors, windowHeight)}
              options={functionOptions}
            />
            {/* <FormSelect
              id="country"
              name="country"
              required
              defaultValue={formValues.country}
              value={countryOptions.filter(({ value }) => value === formValues.country)}
              controlShouldRenderValue
              onChange={({ value }) => {
                setFormValues((currentStates) => ({
                  ...currentStates,
                  country: value
                }));
              }}
              placeholder="Country*"
              styles={customDropdownMenuStyles(colors)}
              options={countryOptions}
            /> */}
          </Fields>
          <ErrorMessage
            errorMessage={errorMessage}
            style={{ color: '#C7D601', position: 'relative', top: '0.5rem' }}
            variants={fadeInAndOutAndAnimateHeightVariants()}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '2.5rem'
            }}>
            {submissionStatus.submitted ? (
              <div
                style={{
                  textAlign: 'center',
                  marginBottom: '0.5rem',
                  color: '#C7D601',
                  width: '90%'
                }}>
                Check your inbox to verify your email address and complete your registration.
                Can&apos;t find our email? Be sure to check your junk!
              </div>
            ) : (
              <Button
                type="submit"
                width="7.5rem"
                disabled={
                  userAlreadyExists || disableSubmitButtonWhileCheckingEmailAddress || errorMessage
                }
                whileTap={{
                  scale: 0.95
                }}
                whileHover={{
                  scale: 1.05
                }}
                style={{
                  alignItems: 'center',
                  alignSelf: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative'
                }}>
                {
                  <AnimatePresence>
                    {submissionStatus.submitting && (
                      <LoadingSpinner
                        style={{ width: '2rem', color: '#fff', position: 'absolute' }}
                      />
                    )}
                  </AnimatePresence>
                }
                {
                  <AnimatePresence>
                    {!submissionStatus.submitting && (
                      <motion.span
                        variants={fadeInAndOutVariants()}
                        initial="initial"
                        animate="animate"
                        exit="exit">
                        Submit
                      </motion.span>
                    )}
                  </AnimatePresence>
                }
              </Button>
            )}
          </div>
        </div>
      </div>
    </StyledFormContainer>
  );
};

const StyledFormContainer = styled(FormContainer)`
  background: ${({ colors }) => colors.primary};

  @media screen and (min-width: 1150px) {
    background: ${({ colors }) => hexToRGB({ color: colors.primary, alpha: 0.85 })};
    padding: 2.2rem 1.25rem 2.5rem;
    max-width: 28.25rem;
    margin-left: 1.4rem;
  }

  input {
    margin-bottom: 0;
  }

  button {
    background: ${({ colors }) => colors.secondary};
  }
`;

const Fields = styled.div`
  display: grid;
  grid-row-gap: 1.25rem;
  grid-template-columns: 1fr;
  margin-bottom: 1.25rem;
`;

export default RegistrationForm;
