import React, { useState, useContext, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import {
  Navbar,
  EmailNotVerifiedNotification,
  Modal,
  AccountDeleted,
  Footer,
  EmailHandler
} from 'components';
import { LocalContext, SmootherContext } from 'context';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { useWindowSize, useIsomorphicLayoutEffect } from 'hooks';
import { FirebaseContext, useAuth } from 'firebase';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollSmoother from 'gsap/ScrollSmoother';
import { pfizer } from 'styles';
import '../assets/fonts/fonts.css';

const GlobalStyles = createGlobalStyle`
  * {
      margin:0;
      padding:0;
      box-sizing:border-box;
      font-family: noto-sans, tahoma, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html{
    font-size: ${({ font }) => font};
  }

  body {
    background: ${({ theme }) => (theme.className === 'contrast' ? 'black' : 'white')};
    ${'' /* overflow-x: hidden; */}
  }

  a{
    text-decoration: none;
  }

  button{
    border:none;
    outline:none;
    &:focus{
        outline:none;
        border:none;
    }
  }
  .scroll-lock {
    overflow: hidden;
  }

  /* Address autocomplete dropdown style overrides */
  .pac-container {
    background: ${({ selectedEvent }) =>
      selectedEvent ? selectedEvent.colors.primary : pfizer.primary};
    border: 0.063rem solid #fff;
    border-top-width: 0;
    border-radius: 0;
    box-shadow: none;

    @media screen and (min-width: 1439px) {
      max-width: 427px !important;
      width: 100% !important;
    }
  }

  .pac-target-input {
    outline: none;
    width: 100%;
  }

  .pac-target-input:focus {
    border: 0.188rem solid #fff !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .pac-item {
    color: #fff;
    background-color: transparent;
    font-family: 'Noto Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: bold;
    letter-spacing: 0.031rem;
    padding: 0.188rem 1.25rem 0;
    border: none;
    height: 2.3rem;
  }

  .pac-item:hover {
    background-color: ${({ selectedEvent }) =>
      selectedEvent ? selectedEvent.colors.secondary : pfizer.secondary};
    cursor: pointer;
  }

  .pac-logo {
    box-sizing: content-box;
    border: 0.188rem solid #fff;
    border-top: none;

    @media screen and (min-width: 1440px) {
      box-sizing: border-box;
    }
  }

  .pac-logo:after {
    padding: 0;
    height: 0;
  }

  .hdpi.pac-logo:after {
    padding: 0;
    height: 0;
  }

  .pac-icon,
  .hdpi .pac-icon {
    display: none;
  }

  .pac-item-query {
    color: #fff;
    font-size: 1rem;
  }

  .pac-item-query:after {
    content: ','
  }

  .pac-selected {
    background-color: ${({ selectedEvent }) =>
      selectedEvent ? selectedEvent.colors.tertiary : pfizer.secondary};
  }

  ::-webkit-scrollbar {
    width: 0.75rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ selectedEvent }) =>
      selectedEvent ? selectedEvent.colors.primary : pfizer.secondary};
    transition: background-color 0.5s ease-in-out;
    border-radius: 0.625rem;
  }
`;

const Layout = ({ children }) => {
  const {
    theme,
    fontSizing,
    selectedEvent,
    aNewUserHasRegisteredForAnEvent,
    showUserAccountDeletedModal,
    setShowUserAccountDeletedModal
  } = useContext(LocalContext);
  const [
    returningUserHasNotConfirmedTheirEmailAddressYet,
    setReturningUserHasNotConfirmedTheirEmailAddressYet
  ] = useState(false);
  const [newUserLocalStorageData, setNewUserLocalStorageData] = useState(null);
  const { user, firebase, loading } = useAuth();
  const { windowWidth } = useWindowSize();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { mode } = queryParams;
  const colors = selectedEvent ? selectedEvent.colors : pfizer;
  // const [smoother, setSmoother] = useState();

  // useIsomorphicLayoutEffect(() => {
  //   gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

  //   const _smoother = ScrollSmoother.create({
  //     normalizeScroll: true, // prevents address bar from showing/hiding on most devices, solves various other browser inconsistencies
  //     ignoreMobileResize: true, // skips ScrollTrigger.refresh() on mobile resizes from address bar showing/hiding
  //     effects: true
  //   });

  //   setSmoother(_smoother);
  // }, []);

  // newUserLocalStorageData is cleared from the browser's local storage as soon as their 'user' doc
  // is first created in the database, so if we can still get 'newUserEMTransform2023' from local
  // storage below, then we know they haven't clicked the link yet. We then pass that set
  // newUserLocalStorageData as state, which triggers the second useEffect, which in turn mounts
  // the EmailNotVerifiedNotification component again, which re-sends the email and notifies the user.
  useEffect(() => {
    if (!loading && firebase) {
      const data = JSON.parse(window.localStorage.getItem('newUserEMTransform2023'));
      if (!firebase?.auth?.currentUser && data && !mode) {
        setNewUserLocalStorageData(data);
      }
    }
    return () => {
      // This is just for safety, though hopefully not necessary.
      if (firebase?.auth?.currentUser) {
        window.localStorage.removeItem('newUserEMTransform2023');
        window.localStorage.removeItem('existingUserEMTransform2023');
        window.localStorage.removeItem('referringUrlEMTransform2023');
      }
    };
  }, [loading, firebase]);

  useEffect(() => {
    if (newUserLocalStorageData) {
      setReturningUserHasNotConfirmedTheirEmailAddressYet(true);
    }
  }, [newUserLocalStorageData]);

  return (
    <FirebaseContext.Provider value={{ user, firebase, loading }}>
      <Wrapper theme={theme} id="smooth-content">
        <GlobalStyles theme={theme} font={fontSizing} selectedEvent={selectedEvent} />
        <EmailHandler />
        <Navbar colors={colors} />
        {children}
        {!mode &&
          (aNewUserHasRegisteredForAnEvent || returningUserHasNotConfirmedTheirEmailAddressYet) && (
            <EmailNotVerifiedNotification
              colors={colors}
              windowWidth={windowWidth}
              newUserLocalStorageData={newUserLocalStorageData}
              returningUserHasNotConfirmedTheirEmailAddressYet={
                returningUserHasNotConfirmedTheirEmailAddressYet
              }
            />
          )}
        <Modal
          hideCloseButton
          openWithoutTrigger={showUserAccountDeletedModal}
          modalContent={
            <AccountDeleted
              setShowUserAccountDeletedModal={setShowUserAccountDeletedModal}
              onClickOutside={() => setShowUserAccountDeletedModal(false)}
            />
          }
        />
      </Wrapper>
    </FirebaseContext.Provider>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  overflow-x: hidden;
  /* background: ${({ theme }) => (theme.className === 'contrast' ? 'black' : 'white')};
  filter: ${({ theme }) => (theme.className === 'grayscale' ? 'grayscale(100%)' : 'none')}; */
`;

export default Layout;
