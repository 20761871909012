import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Markup } from 'interweave';
import { useEventData } from 'hooks';

export default ({ colors }) => {
  const { edges: events } = useEventData();
  const pastEvents = events
    .filter(({ node }) => node.frontmatter.type === 'past' && !node.frontmatter.hidden)
    .sort((a, b) => b.node.frontmatter.eid - a.node.frontmatter.eid);

  return (
    <PastEvents>
      {pastEvents
        .map((event) => {
          const eventImage = getImage(event.node.frontmatter.thumb);
          return (
            <Event key={event.node.frontmatter.eid}>
              <GatsbyImage image={eventImage} alt={event.node.frontmatter.name} />
              <Details colors={colors}>
                <h3>{event.node.frontmatter.name}</h3>
                <h4>
                  <Markup content={event.node.frontmatter.dates} />
                </h4>
                <Link to={event.node.frontmatter.slug}>Visit Event</Link>
              </Details>
            </Event>
          );
        })
        .slice(0, 3)}
    </PastEvents>
  );
};

const Details = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: ${({ colors }) => colors.secondary} !important;
    margin-bottom: 0.5em;
  }
  h4 {
    font-size: 1rem;
    font-weight: 600;
    color: #3c3c3c !important;
    margin-bottom: 0.5em;
  }
  a {
    background-color: ${({ colors }) => colors.tertiary};
    border-radius: 3rem;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1em;
    padding: 0.75rem 1.5rem;
    text-transform: uppercase;
    transition: 0.3s;
    &:hover {
      background-color: ${({ colors }) => colors.secondary};
    }
  }
`;

const Event = styled.div`
  background: transparent;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 400px;
  padding: 0;
  width: 100%;
`;

const PastEvents = styled.div`
  background: white;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  height: auto;
  margin: 0 auto;
  max-width: 1300px;
  padding: 1.25rem;
  width: 100%;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
