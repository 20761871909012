import * as React from 'react';

function SvgTwitterIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={12} cy={12} r={12} fill="#BDBDBD" />
      <path
        d="M17.682 8.533c-.37.158-.763.247-1.157.315.185-.023.463-.36.579-.494.162-.203.3-.427.393-.674 0-.023.023-.045 0-.068h-.07c-.44.225-.879.382-1.365.517-.046 0-.07 0-.092-.022-.047-.045-.07-.09-.116-.113a3.292 3.292 0 00-.671-.404 2.73 2.73 0 00-1.042-.157 2.411 2.411 0 00-.972.27c-.3.157-.578.359-.787.628-.231.27-.393.584-.463.899-.069.314-.069.628-.023.965 0 .045 0 .068-.046.045-1.875-.27-3.425-.92-4.698-2.336-.046-.067-.093-.067-.14 0-.555.809-.277 2.112.418 2.74.092.09.185.18.3.248-.046 0-.509-.045-.902-.247-.047-.045-.093-.023-.093.044 0 .09 0 .158.023.27.116.809.695 1.572 1.482 1.864.092.045.208.067.3.09-.185.045-.37.067-.902.022-.07-.022-.093.023-.07.09.394 1.033 1.25 1.348 1.875 1.527.093.023.162.023.255.045l-.024.023c-.208.314-.948.539-1.272.651-.625.202-1.296.315-1.945.247-.115-.022-.115-.022-.162 0-.023.023 0 .045.024.068.138.09.277.157.393.224.417.202.856.382 1.296.494 2.338.63 4.976.158 6.712-1.527 1.388-1.325 1.851-3.167 1.851-4.986 0-.067.093-.112.14-.157.346-.27.647-.584.902-.921.07-.067.046-.157.046-.18.07-.045.07-.022.023 0z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.068 16.552c0-.023.208 0 0 0 0-.023.023 0 0 0z"
        fill="#fff"
      />
    </svg>
  );
}
export default SvgTwitterIcon;
