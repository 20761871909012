import React, { useContext } from 'react';
import { ModeratorControlPanel, UserPanel } from 'components';
import { FirebaseContext } from 'context';
import styled from 'styled-components';

const Polls = ({ did, eid, colors }) => {
  const { user } = useContext(FirebaseContext);
  return (
    user && (
      <Wrapper colors={colors}>
        {user.isModerator?.includes(`${did}_${eid}`) ? (
          <ModeratorControlPanel colors={colors} />
        ) : (
          <UserPanel colors={colors} />
        )}
      </Wrapper>
    )
  );
};

const Wrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding: 0.9rem 0.75rem 0;
  position: relative;

  @media screen and (min-width: 410px) {
    padding: 1.125rem 1.0125rem 0;
  }

  @media screen and (min-width: 1150px) and (max-width: 1365px) {
    padding: 0.9rem 0.75rem 0;
  }

  @media screen and (min-width: 1366px) {
    padding: 1.125rem 1.0125rem 0;
  }

  > p:first-child {
    color: ${({ colors }) => colors.primary};
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0.1em 0 1.2em;
    position: relative;
    right: 0.023em; /* Have to do this so that it aligns with the "Moderator's Control Panel" in Q&A. TODO: Ideally, I'd stop using scaled3d in the Q&A component, so I don't have to do this here. Will refactor the Q&A component when I get a chance. */
    text-align: center;
  }
`;

export const TextInput = styled.input`
  border: none;
  border-bottom-color: ${({ error }) => (error ? '#F55151' : '#bdbdbd')};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  color: #4f4f4f;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 0.875rem;
  line-height: 20px;
  outline: none;
  transition: border-bottom-color 100ms ease-in-out;
  width: 100%;

  &::placeholder {
    color: #c4c4c4;
    font-style: italic;
  }

  &:focus {
    border-bottom-color: ${({ selectedEvent, error }) =>
      error ? '#F55151' : selectedEvent?.colors.primary};
  }
`;

export default Polls;
