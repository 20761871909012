import React, { useContext, useRef, forwardRef, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Button, FeelTheJoy } from 'components';
import { Markup } from 'interweave';
import { FirebaseContext, SmootherContext } from 'context';
import { useWindowSize, useIsomorphicLayoutEffect } from 'hooks';
import { BgSwirl, BgSwirl2 } from 'assets/svgs';

const boxData = [
  {
    title: 'Day 1',
    body: "On Day 1, join our leaders as they share their vision on EM Oncology's Leap Into the Future in 2023. Hear inspiring messages from our guest speaker, Nikki Bradley, followed by a deep-dive into our Breast Cancer strategies. Each day will end with a dose of #JOY!",
    action: 'View DAY 1 Livestream',
    primary: '#0095FF',
    secondary: 'rgba(0, 149, 255, 0.2)',
    link: '/day-1/'
  },
  {
    title: 'Day 2',
    body: 'Join us for a conversation with our EM leadership, followed by in-depth discussions in our Biosimilars, Lung Cancer, and Colorectal Cancer and Melanoma sections. Learn about regional perspectives from our MERA and EM Asia RTALs and RMLs, and about our initiatives in precision medicine.',
    action: 'View DAY 2 Livestream',
    primary: '#891AFC',
    secondary: 'rgba(137, 26, 252, 0.2)',
    link: '/day-2/'
  },
  {
    title: 'Day 3',
    body: "On Day 3, we will cover important updates from our Genitourinary Cancer and Hematology portfolios. Also hear about exciting global pipeline updates and learn about what's next in Pfizer Oncology. We'll wrap up the POA with a session of #JOY - come prepared to have fun!",
    action: 'View DAY 3 Livestream',
    primary: '#C7D601',
    secondary: 'rgba(199, 214, 1, 0.2)',
    link: '/day-3/'
  }
];

const EmbracingJoy = () => {
  const { user } = useContext(FirebaseContext);
  // const smoother = useContext(SmootherContext);
  const { windowWidth } = useWindowSize();
  // const BgSwirl1Ref = useRef();
  // const BgSwirl2Ref = useRef();

  // useIsomorphicLayoutEffect(() => {
  //   if (smoother && BgSwirl1Ref.current) {
  //     smoother.effects(BgSwirl1Ref.current, {
  //       speed: 0.8
  //     });
  //   }
  // }, [smoother, BgSwirl1Ref.current]);

  // useIsomorphicLayoutEffect(() => {
  //   if (smoother && BgSwirl2Ref.current) {
  //     smoother.effects(BgSwirl2Ref.current, {
  //       speed: 0.8
  //     });
  //   }
  // }, [smoother, BgSwirl2Ref.current]);

  // Solves an issue with GSAP Parallax
  // useEffect(() => {
  //   window.dispatchEvent(new Event('resize'));
  // }, []);

  return (
    <Wrapper>
      <div
        style={{
          background: 'rgba(196, 196, 196, 0.2)',
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}>
        <div style={{ position: 'relative', width: '100%', overflow: 'visible' }}>
          <Segment>
            {windowWidth >= 767 && (
              <div
                // ref={BgSwirl2Ref}
                style={{
                  opacity: 0.55,
                  position: 'absolute',
                  right: 0,
                  top: '-265px',
                  width: '495px',
                  zIndex: -1
                }}>
                <StyledBgSwirl2 />
              </div>
            )}
            <SegmentHeading>How to get the most from your experience</SegmentHeading>
            <InstructionsBody>
              <div>
                You will see we have a simple interactive webchat facility running alongside each
                day&apos;s streaming event. Please try out the chat function, comment on the
                presentations, ask questions of our presenters, give emoji feedback as you go and
                have lots of fun.
                <br />
                <br />
                Some of the sessions will include polling, others will include game play and others
                still will be just for you to relax, reflect and enjoy the presentations of your
                team.
                <br />
                <br />
                The chat functions are visible to all, the Q&A function is usable by all, and your
                questions are visible only by the event moderator. Our promise is to respond to all
                questions submitted either in-show or post-event, so please submit some!
              </div>
              <div>
                And just in case you miss any parts, all presentations will be available after the
                live stream ends.
                <br />
                <br />
                <strong>Begin right away with the game.</strong> Simply follow the instructions on
                the banner below and start playing, your participation is greatly valued and will
                bring a greater sense of togetherness and joy to your colleagues.
                <br />
                <br />
                The Chat and Q&A are already open so go right ahead ask any questions you have
                within the portal, get involved right now!
                <br />
                <br />
                We would love to hear from you.
                <br />
                <br />
                <strong>Most of all EnJoy the EM Transform 2023 conference</strong>
                <br />
                <br />
                <p>The EM Transform Conference Team</p>
              </div>
            </InstructionsBody>
          </Segment>
        </div>
      </div>
      {user && <FeelTheJoy style={{ padding: '5rem 1rem 2.1rem' }} />}
      <div style={{ position: 'relative', width: '100%', overflow: 'visible' }}>
        <Segment>
          {windowWidth >= 767 && (
            <div
              // ref={BgSwirl1Ref}
              style={{
                left: 0,
                opacity: 0.55,
                position: 'absolute',
                top: '-490px',
                width: '385px',
                zIndex: -1
              }}>
              <StyledBgSwirl1 />
            </div>
          )}
          <SegmentHeading>Embracing JOY everyday!</SegmentHeading>
          <SegmentSubHeading>
            We have imbued our POA with JOY, and each day has a fun session.
          </SegmentSubHeading>
          <Grid style={{ paddingBottom: 0 }}>
            {boxData.map((obj, i) => (
              <GridElement key={i} style={{ background: `${obj.secondary}` }} to={obj.link}>
                <div style={{ background: `${obj.primary}` }}>{obj.title}</div>
                <p>
                  <Markup content={obj.body} noWrap />
                </p>
                <span style={{ color: `${obj.primary}` }}>{obj.action}</span>
              </GridElement>
            ))}
          </Grid>
          {/* <SegmentSubHeading>For more info visit</SegmentSubHeading>
          <Button
            type="button"
            width="296px"
            whileTap={{
              scale: 0.975
            }}
            whileHover={{
              scale: 1.025
            }}
            style={{
              alignItems: 'center',
              alignSelf: 'center',
              display: 'flex',
              justifyContent: 'center',
              position: 'relative'
            }}>
            <a
              href="http://awards.pfizer.com/EnterpriseRecognitionPortal/English/_layouts/15/Pfizer.ECF.Awards.Website/ERPHome.aspx"
              target="_blank"
              rel="noreferrer"
              style={{ color: '#FFF' }}>
              Moments of JOY Portal
            </a>
          </Button> */}
        </Segment>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  align-items: center;
  color: #3c3c3c;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Segment = styled.div`
  align-items: center;
  color: #3c3c3c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem;
  width: 100%;
  @media screen and (min-width: 768px) {
    max-width: 72.75rem;
    margin: 3rem auto;
  }
`;

const StyledBgSwirl1 = styled(BgSwirl)``;

const StyledBgSwirl2 = styled(BgSwirl2)``;

const SegmentHeading = styled.h3`
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 3.375rem;
  margin: 1rem;
  text-align: center;
  @media screen and (min-width: 768px) {
    font-size: 2.625rem;
  }
`;

const SegmentSubHeading = styled.h4`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin: 13px;
`;

const Grid = styled.div`
  display: grid;
  gap: 4rem 2.6rem;
  grid-template-columns: 1fr;
  margin: 2rem auto;
  padding: 2.625rem 0;

  @media screen and (min-width: 1150px) {
    margin: 2rem auto 0;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const InstructionsBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1rem;
  div {
    width: 100%;
    p {
      padding: 1.5rem 0;
      text-align: right;
    }
    a {
      color: #0095ff;
      text-decoration: underline;
      &:hover {
        color: #1200c9;
      }
    }
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
    margin: 0.625rem 0;
    padding: 3.125rem 0 0;
    div {
      margin: 0.625rem;
    }
  }

  @media screen and (min-width: 1150px) {
    div {
      margin: 0.625rem 0;
    }
  }
`;

const GridElement = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 22.5rem;
  padding: 3.3rem 1.275rem 1.5rem;
  position: relative;
  text-align: center;
  div {
    align-items: center;
    border-radius: 1.875rem;
    color: #fff;
    display: flex;
    height: 3rem;
    justify-content: center;
    left: calc(50% - 3.75rem);
    position: absolute;
    width: 7.5rem;
    top: -1.5rem;
  }
  p {
    color: #3c3c3c;
    margin-bottom: 1rem;
  }
  span {
    font-weight: 700;
    justify-self: end;
    margin-top: auto;
    text-transform: uppercase;
  }
  @media screen and (min-width: 768px) {
    flex-direction: column;
    margin: 1.25rem 0;
  }
`;

export default EmbracingJoy;
