import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  RegistrationForm,
  Button,
  Modal,
  HeroVideo,
  RenderWhenReady,
  HomepageBannerLoggedInGraphic
} from 'components';
import { FormLabel } from 'components/Forms/FormComponents';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import { FirebaseContext, LocalContext } from 'context';
import { useWindowSize } from 'hooks';
import { motion } from 'framer-motion';
import { scrollToAnchor } from 'utils';
// import { EMTransformLogo, HomepageBannerLoggedInGraphic } from 'assets/svgs';
// import { AnimatedEmTransformLogo } from 'assets/animated-svgs';
import { EmTransformLogo } from 'assets/svgs';
import { Markup } from 'interweave';

const Header = (props) => {
  const { loading, firebase, user } = useContext(FirebaseContext);
  const { headerRef } = useContext(LocalContext);
  const { colors } = props;
  const { windowWidth } = useWindowSize();

  return (
    <Wrapper
      ref={headerRef}
      user={user}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}>
      <HeroVideo />
      <Container>
        <Content>
          <Date
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}
            colors={colors}>
            February 21 - 23, 2023
          </Date>
          <EMTransformLogoWrapper
            initial={{ transform: 'rotateX(90deg)' }}
            animate={{ transform: 'rotateX(0deg)' }}
            transition={{ duration: 1, delay: 1 }}>
            {/* {user ? <AnimatedEmTransformLogo /> : <EmTransformLogo />} */}
            <EmTransformLogo />
          </EMTransformLogoWrapper>
          <Title
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}>
            Welcome to the EM Oncology
            <br />
            POA Meeting 2023
          </Title>
          <Description
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}>
            Welcome to the EM Oncology POA, EM Transform 2023. Over 3 days we&apos;re coming
            together to discuss strategic priorities for our portfolio, define clear action plans,
            celebrate successes and share innovative approaches across Emerging Markets
          </Description>
          {user && (
            <Button
              width="180px"
              type="button"
              style={{
                marginBottom: '0.5rem',
                marginLeft: '0.5rem',
                marginTop: '1rem',
                padding: '1.75rem 1rem',
                width: '200px'
              }}
              whileTap={{
                scale: 0.95
              }}
              whileHover={{
                scale: 1.05
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1 }}
              onClick={(e) => {
                e.preventDefault();
                scrollToAnchor('agenda', {
                  duration: 1200,
                  offset: -90
                });
              }}>
              View Agenda
            </Button>
          )}
          {windowWidth < 1150 && !loading && firebase && !user && (
            <RegisterButton
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1 }}>
              <Button width="200px" type="button">
                <Modal
                  trigger={<p>Register Here</p>}
                  modalContent={<RegistrationForm colors={colors} contrast />}
                />
              </Button>
            </RegisterButton>
          )}
        </Content>
        <RenderWhenReady>
          {windowWidth >= 1150 && (
            <FormContainer
              user={user}
              style={
                user && {
                  display: 'flex',
                  justifyContent: 'center'
                }
              }
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1 }}>
              {loading ? null : !loading && firebase && user ? (
                <>
                  <HomepageBannerLoggedInGraphic />
                  {/* <HomepageBannerLoggedInGraphic width={300} style={{ marginBottom: '4rem' }} /> */}
                </>
              ) : (
                !loading && firebase && !user && <RegistrationForm colors={colors} contrast />
              )}
            </FormContainer>
          )}
        </RenderWhenReady>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.header`
  background: #3c3c3c;
  height: auto;
  overflow: ${({ user }) => (user ? 'hidden' : 'auto')};
  position: relative;
  width: 100%;

  @media (min-width: 1150px) {
    min-height: 100vh;
  }
`;

const Container = styled.div`
  color: var(--black);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  margin: 0 auto 1rem;
  max-width: 1440px;
  padding: 5rem 0.625rem 2.5rem;
  position: relative;
  width: 100%;
  @media (min-width: 768px) and (min-height: 1000px) and (max-width: 1150px) {
    padding: 6rem 0 12.5rem;
  }
  @media (min-width: 1150px) {
    /* padding: 12.5rem 5rem 0; */
    padding: 0rem;
    height: auto;
    margin-bottom: 0;
    grid-template-columns: repeat(12, 1fr);
    /* grid-column-gap: 1.4rem; */
  }
`;

const Content = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  justify-content: flex-start;
  padding: 2rem 0.5rem;
  position: relative;
  width: 100%;
  z-index: 2;
  @media (min-width: 768px) {
    padding: 4rem;
  }
  @media (min-width: 1150px) {
    grid-column: 2/7;
    padding: 12.5rem 0 5.8rem;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }

  @media (min-width: 1440px) {
    grid-column: 2/8;
  }
`;

const Date = styled(motion.h4)`
  color: #891afc;
  font-size: 1.25rem;
  font-weight: 700;
  font-weight: bold;
  margin-bottom: 1.5rem;

  @media (min-width: 1150px) {
    font-size: 1.5rem;
    grid-row: 1/2;
    grid-column: 1/5;
  }
`;

const EMTransformLogoWrapper = styled(motion.div)`
  margin-left: -3.25%;
  transform: rotateX(-90deg);
  transform-style: preserve-3d;

  svg {
    /* height: 12.563rem; */
    /* max-width: 100%; */
  }

  @media (min-width: 1150px) {
    /* margin-bottom: 2rem; */
    margin-bottom: 0.325rem;
    grid-row: 1/3;
    grid-column: 1/7;
    max-width: 538px;

    position: relative;
    bottom: 3.8rem;
  }

  @media (min-width: 1440px) {
    max-width: 684px;
  }
`;

const Title = styled(motion.h1)`
  color: #3c3c3c;
  font-size: 3rem;
  font-weight: bold;
  height: auto;
  margin-bottom: 0.25em;
  width: auto;
  @media (min-width: 1150px) {
    font-size: 2.3rem;
    margin-bottom: 2rem;
    grid-row: 3/4;
    grid-column: 1/7;
    svg {
      width: 30rem;
    }
  }

  @media (min-width: 1200px) {
    font-size: 2.45rem;
  }

  @media (min-width: 1300px) {
    font-size: 2.625rem;
  }

  @media (min-width: 1440px) {
    font-size: 3rem;
  }
`;

const Description = styled(motion.div)`
  color: #3c3c3c;
  font-size: 1.25rem;
  line-height: 1.3em;
  margin-bottom: 1.5rem;
  margin-top: 0rem;
  max-width: 32.5rem;
  @media (min-width: 1150px) {
    margin-bottom: 1.5rem;
    grid-row: 4/5;
    grid-column: 1/7;
  }

  @media (min-width: 1440px) {
    max-width: 33.4em;
  }
`;

const FormContainer = styled(motion.div)`
  grid-column: 1/7;
  height: auto;
  position: relative;
  z-index: 2;

  @media (min-width: 1150px) {
    grid-column: 8 /12;
    padding-top: ${({ user }) => (user ? '9rem' : '12.75rem')};
    justify-self: end;
  }
`;

const RegisterButton = styled(motion.div)`
  margin-left: 0.5rem;
  margin-top: 1rem;
  button {
    align-items: center;
    /* background-color: #b12f7e; */
    display: flex;
    justify-content: center;
    padding: 1.75rem 1rem;
  }
`;

export default Header;
