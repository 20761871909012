/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import queryString from 'query-string';
import Layout from './src/layouts';
import { LocalContextProvider } from './src/context/LocalContext';

export const wrapPageElement = ({ props }) => {
  const { component: PageComponent, page } = props.pageResources;
  return page.path === '/ie-not-supported/' ? (
    <PageComponent {...props} />
  ) : (
    <Layout {...props}>
      <PageComponent {...props} />
    </Layout>
  );
};

export const wrapRootElement = ({ element }) => (
  <LocalContextProvider>{element}</LocalContextProvider>
);

export const onClientEntry = () => {
  const parseUrl = (params) => {
    const queryParams = queryString.parse(params);
    /* eslint-disable camelcase */
    let { utm_source } = queryParams;
    if (utm_source) {
      utm_source = utm_source
        .split('_')
        .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    }
    return utm_source || document.referrer;
  };

  const referringUrl = JSON.parse(window.localStorage.getItem('referringUrlEMTransform2023'))?.data;

  if (!referringUrl) {
    window.localStorage.setItem(
      'referringUrlEMTransform2023',
      JSON.stringify({
        data: document.referrer ? parseUrl(window.location.search) : 'Direct Navigation'
      })
    );
  }
};
